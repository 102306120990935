<div
  class="tw-flex tw-h-auto tw-w-full tw-justify-center tw-bg-neutral-50 tw-pt-6 md:tw-mb-[88px] md:tw-bg-white md:tw-pt-0"
>
  <div
    class="tw-flex tw-h-auto tw-w-[80%] tw-flex-col tw-rounded-lg tw-bg-white tw-p-6 md:tw-my-0 md:tw-h-full md:tw-w-[90%]"
  >
    <nav
      class="tw-relative tw-mb-6 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
      [routerLink]="backRoutePath"
    >
      <div class="sm:btn-icon">
        <icon [name]="'left-arrow'" [size]="7"></icon>
      </div>
      <div class="tw-flex tw-w-full tw-items-center md:tw-absolute md:tw-justify-center">
        <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{ title | i18n }}</span>
      </div>
    </nav>
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>
</div>
