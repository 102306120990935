import { Injectable, Injector } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { ScenarioResponse } from "@bitwarden/web-vault/app/models/data/response/scenario.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import {
  GroupScenarioBalance,
  ScenarioOptionWinner,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { CalculationServiceAbstraction } from "@bitwarden/web-vault/app/services/DataCalculationService/calculation.service.abstraction";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { ScenarioOptionsFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/scenario-options-factory";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";

import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class ScenarioService implements CalculationServiceAbstraction {
  scenarioWinners: GroupScenarioBalance[] = [];
  scenariosCompleted = 0;
  callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>;
  scenarios: Scenario[] = [];
  scenarioOptions: ScenarioOption[] = [];
  scenarioGroup: ScenarioGroup;
  webWorkerQueue: WebWorkerQueue;
  completedPermutations = 0;
  totalPermutations = 0;
  preferenceService: PreferenceService;
  private scenarioStepIndex: {
    name: string;
    permutationLength: number;
    stepIndex: number;
    scenarioIndex: number;
  }[] = [];

  constructor(
    private injector: Injector,
    private mainProgressBar: MainProgressBar,
    private i18nService: I18nService
  ) {
    this.webWorkerQueue = new WebWorkerQueue();
    this.preferenceService = this.injector.get(PreferenceService);
  }

  async runScenarioGroup(
    scenarioGroup: ScenarioGroup,
    webWorkerQueue: WebWorkerQueue,
    callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>
  ) {
    this.webWorkerQueue = webWorkerQueue;
    // TODO - @Michelle symbol is getting set to AUD when added to bloby we need to make sure it is being calculated based on baseCurrency
    /* scenarioGroup.symbol = <string>await this.preferenceService.get("baseCurrency");*/
    this.callback = callback;

    await this.resetScenarios();

    let scenarioIndex = 0;
    for (const s of scenarioGroup.scenarios) {
      const scenario = new Scenario(new ScenarioResponse(s));
      this.scenarios.push(scenario);
      if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
        await this.setupScenarios(scenario, scenarioGroup);
      } else {
        // if the endDate filter is before the anchor point, then we don't need to run the scenario
        await this.completeScenario(scenario, scenarioIndex, scenarioGroup, {
          createdRecords: {},
          fullTransactionList: [],
          finalBalanceAmount: null,
        });
      }
      scenarioIndex++;
    }

    if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
      scenarioIndex = 0;
      let stepIndex = 0;
      for (const scenario of this.scenarios) {
        if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
          const scenarioOption = this.scenarioOptions[scenarioIndex];

          /** This will add a mapping which Scenario is running on the THREAD */
          stepIndex += this.scenarioOptions[scenarioIndex].permutations.length;
          this.scenarioStepIndex.push({
            name: scenarioOption.constructor.name,
            permutationLength: scenarioOption.permutations.length,
            stepIndex: stepIndex,
            scenarioIndex: scenarioIndex,
          });

          await scenarioOption.run(
            this.completeScenario.bind(this, scenario, scenarioIndex, scenarioGroup),
            this.completePermutation.bind(this)
          );
        }
        scenarioIndex++;
      }
    }
  }

  async setupScenarios(scenario: Scenario, scenarioGroup: ScenarioGroup) {
    const scenarioOption = ScenarioOptionsFactory.createScenarioOption(
      scenario.scenarioType,
      scenarioGroup,
      this.injector,
      this.webWorkerQueue
    );
    this.scenarioOptions.push(scenarioOption);

    if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
      await scenarioOption.beforeRunningPermutationsHook();
    }
    if (scenarioOption.permutations.length > 0) {
      this.totalPermutations += scenarioOption.permutations.length;
    }
  }

  async resetScenarios() {
    this.scenarioWinners = [];
    this.scenarios = [];
    this.scenarioOptions = [];
    this.scenariosCompleted = 0;
    this.totalPermutations = 0;
    this.completedPermutations = 0;
    this.scenarioStepIndex = [];
  }

  async completeScenario(
    scenario: Scenario,
    scenarioIndex: number,
    scenarioGroup: ScenarioGroup,
    groupedBalanceByScenario: ScenarioOptionWinner
  ) {
    this.scenariosCompleted++;

    this.scenarioWinners[scenarioIndex] = {
      ScenarioGroupId: scenarioGroup.id,
      scenarioID: scenario.id,
      scenarioType: scenario.scenarioType,
      scenarioName: scenario.name,
      createdRecords: groupedBalanceByScenario.createdRecords,
      fullTransactionList: groupedBalanceByScenario.fullTransactionList,
      finalBalanceAmount: groupedBalanceByScenario.finalBalanceAmount,
      graphData: null,
      helpInfo: groupedBalanceByScenario.helpInfo,
    };
    if (this.scenariosCompleted === scenarioGroup.scenarios.length) {
      await this.callback(this.scenarioWinners);
    }
  }

  completePermutation() {
    this.completedPermutations++;
    const percentage = Math.round(
      this.totalPermutations === 0 ? 0 : (this.completedPermutations / this.totalPermutations) * 100
    );
    this.mainProgressBar.setLoadingPercentage(percentage);

    const translatedScenarioStepIndex = this.translateScenarioStep();
    const action = `${translatedScenarioStepIndex.map((item) => item.translatedName).join(", ")}`;

    this.mainProgressBar.setProgressLabel(action);
    this.mainProgressBar.setScenarioIndex(translatedScenarioStepIndex[0]?.scenarioIndex || 0);
  }

  getScenarioStepIndex() {
    return this.scenarioStepIndex;
  }

  translateScenarioStep() {
    const scenarioStepIndex = this.getScenarioStepIndex();
    let currentStepGroupFound = false;
    // todo: the comparison is called everytime the calculation runs, potential refactor the logic
    return scenarioStepIndex
      .map((item) => {
        const translationKey = `calculating${item.name}`;
        const translatedName = this.i18nService.t(translationKey);
        const isCurrentPermutationStep =
          !currentStepGroupFound && item.stepIndex >= this.completedPermutations;
        if (isCurrentPermutationStep) {
          currentStepGroupFound = true;
        }
        return {
          ...item,
          translatedName: translatedName,
          isCurrentStep: isCurrentPermutationStep,
        };
      })
      .filter((item) => item.isCurrentStep);
  }
}
