import { Injectable, signal, WritableSignal } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { BaseProgressBar } from "../../components/progress-bar/base-progress-bar";
import { IMainProgressBar } from "../../models/interfaces/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class BasiqProgressBar extends BaseProgressBar implements IMainProgressBar {
  static DATA_TYPE_LABEL_LOADING = "Loading...";
  static CONNECTING_TO_BASIQ_LABEL = "Connecting to Basiq";
  private loadingPercentage: WritableSignal<number> = signal(0);
  private isLoadingComplete: WritableSignal<boolean> = signal(false);
  private dataTypeLabel$ = new BehaviorSubject<string>(BasiqProgressBar.DATA_TYPE_LABEL_LOADING);
  private accountLabel$ = new BehaviorSubject<string>(BasiqProgressBar.CONNECTING_TO_BASIQ_LABEL);
  isAccountLabelSet = false;

  constructor() {
    super();
  }

  setLoadingPercentage(percentage: number) {
    this.loadingPercentage.set(percentage);
  }

  getLoadingPercentage() {
    return this.loadingPercentage();
  }

  setIsLoadingCompleted(isLoadingCompleted: boolean) {
    setTimeout(() => this.isLoadingComplete.set(isLoadingCompleted), 0);
  }

  setProgressLabel(label: string) {
    this.dataTypeLabel$.next(label);
  }

  getDataTypeLabel() {
    return this.dataTypeLabel$;
  }

  setAccountLabel(label: string) {
    if (!this.isAccountLabelSet) {
      this.isAccountLabelSet = true;
    }
    this.accountLabel$.next(label);
  }

  getAccountLabel() {
    return this.accountLabel$;
  }

  reset() {
    this.setIsLoadingCompleted(false);
    this.setProgressLabel(BasiqProgressBar.DATA_TYPE_LABEL_LOADING);
    this.setLoadingPercentage(0);
  }

  getProgressLabel(): string {
    return "";
  }

  isLoadingCompleted(): boolean {
    return false;
  }
}
