import { Component, Input } from "@angular/core";

@Component({
  selector: "app-earnings-content",
  templateUrl: "./earnings-content.component.html",
})
export class EarningsContentComponent {
  @Input() earningAmount!: number;
  @Input() currency!: string;
}
