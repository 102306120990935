<ng-container>
  <div class="tw-mb-3 tw-mt-6 tw-text-2xl tw-font-medium sm:tw-mb-1.5 sm:tw-text-lg">
    {{ "currentAccess" | i18n }}
  </div>
  <div
    *ngIf="!isPremiumUser"
    class="tw-w-[84px] tw-rounded-full tw-bg-neutral-50 tw-py-3 tw-text-center tw-text-lg tw-font-medium tw-text-primary sm:tw-mb-6 sm:tw-w-[67px] sm:tw-py-2 sm:tw-text-base"
  >
    {{ "accessStatus1" | i18n }}
  </div>

  <div
    *ngIf="isPremiumUser"
    class="tw-flex tw-h-fit tw-w-fit tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-px-4 tw-text-lg tw-font-medium tw-text-primary sm:tw-text-base"
  >
    <svg class="tw-mt-2 tw-h-10 tw-w-10">
      <use xlink:href="images/sprite.svg#diamond" />
    </svg>
    <span class="tw-whitespace-nowrap tw-text-neutral-800">{{ "accessStatus2" | i18n }}</span>
  </div>

  <section class="tw-my-8 sm:tw-my-4">
    <span class="tw-text-[18.2px] tw-font-medium sm:tw-text-base">{{
      "accessStatus1" | i18n
    }}</span>
    <p
      class="tw-mt-2 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-px-6 tw-py-4 sm:tw-px-3 sm:tw-py-2"
    >
      {{ "accessStatus1Content1" | i18n }} <br />{{ "accessStatus1Content2" | i18n }}
    </p>
  </section>
  <section class="tw-my-8 sm:tw-my-4">
    <span class="tw-text-[18.2px] tw-font-medium sm:tw-text-base">{{
      "accessStatus2" | i18n
    }}</span>
    <div
      class="tw-mt-2 tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-px-6 tw-py-4 sm:tw-px-3 sm:tw-py-2"
    >
      <div class="tw-flex tw-gap-1">
        <svg class="tw-h-10 tw-w-10">
          <use xlink:href="images/sprite.svg#diamond" />
        </svg>
        <span class="tw-mt-1 tw-font-medium">{{ "accessStatus2" | i18n }}</span>
      </div>
      <p class="tw-mb-0">
        {{ "accessStatus2Content1" | i18n }} <br />{{ "accessStatus2Content2" | i18n }} <br />{{
          "accessStatus2Content3" | i18n
        }}
      </p>
      <app-gloss-button
        *ngIf="!isPremiumUser"
        class="tw-flex tw-w-fit tw-min-w-[270px] tw-max-w-[400px]"
        [options]="requestAccessButtonOptions"
      ></app-gloss-button>
    </div>
  </section>
</ng-container>
