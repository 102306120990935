import { Component, Input } from "@angular/core";

import { WidgetMenuType } from "@bitwarden/web-vault/app/models/types/widget.types";

@Component({
  selector: "app-views",
  templateUrl: "views.component.html",
})
export class ViewsComponent {
  @Input() iconName: string;
  @Input() title: string;
  @Input() widgetIcons: string[];

  balanceWidgetMenu = {
    type: "texts" as WidgetMenuType,
    options: [
      {
        displayValue: "$AUD",
        value: "AUD",
        isSelected: true,
      },
      {
        displayValue: "$HKD",
        value: "HKD",
        isSelected: false,
      },
    ],
  };

  chartWidgetMenu = {
    type: "icons" as WidgetMenuType,
    options: [
      {
        displayValue: "bubble-chart",
        value: "bubbleChart",
        isSelected: true,
      },
      {
        displayValue: "pie-chart",
        value: "pieChart",
      },
      {
        displayValue: "bar-chart",
        value: "barChart",
      },
    ],
  };

  handleCurrencyWidget(value: string) {
    // console.log(value);
  }

  handleChartWidget(value: string) {
    // console.log(value);
  }
}
