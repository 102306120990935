<!--
<div>
  <div class="container">
    <div class="stepper-wrapper">
      <div
        class="stepper-item"
        [ngClass]="{
          'active-step': step === 'institution',
          completed: competedSteps.includes('institution')
        }"
      >
        <div class="step-counter">1</div>
        <div class="step-name">{{ "institution" | i18n }}</div>
      </div>
      <div
        class="stepper-item"
        [ngClass]="{
          'active-step': step === 'upload',
          completed: competedSteps.includes('upload')
        }"
      >
        <div class="step-counter">2</div>
        <div class="step-name">{{ "upload" | i18n }}</div>
      </div>
      <div
        class="stepper-item"
        [ngClass]="{
          'active-step': step === 'arrange',
          completed: competedSteps.includes('arrange')
        }"
      >
        <div class="step-counter">3</div>
        <div class="step-name">{{ "arrange" | i18n }}</div>
      </div>

      <div class="stepper-item" [ngClass]="{ 'active-step': step === 'import' }">
        <div class="step-counter">4</div>
        <div class="step-name">{{ "import" | i18n }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="step === 'institution'" class="container">
    <div class="select-container">
      <span class="section-info">{{ "selectInstitutionInfo" | i18n }}</span>
      <mat-form-field>
        <mat-select
          [(value)]="selectedInstitution"
          (selectionChange)="onInstitutionSelected($event)"
        >
          <mat-option *ngFor="let option of institutions" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button
      class="next-button"
      mat-raised-button
      color="primary"
      (click)="this.gotToUpload($event)"
    >
      <span>{{ "next" | i18n }}</span>
    </button>
    <button
      class="next-button"
      mat-raised-button
      color="primary"
      (click)="this.createNewInstitution()"
    >
      <span>{{ "createNewInstitution" | i18n }}</span>
    </button>
  </div>

  <div class="container" *ngIf="step === 'upload'">
    <form #form ngNativeValidate>
      <div class="row">
        <div>
          <span class="section-info">{{ "uploadSectionInfo" | i18n }}</span>
          <div class="form-group">
            <br />
            <div class="file-selector">
              <button type="button" mat-stroked-button color="basic" (click)="fileSelector.click()">
                {{ "chooseFile" | i18n }}
              </button>
              {{ this.fileSelected ? this.fileSelected.name : ("noFileChosen" | i18n) }}
            </div>
            <input
              #fileSelector
              type="file"
              id="file"
              class="form-control-file"
              name="file"
              (change)="setSelectedFile($event)"
              accept=".csv"
              hidden
              multiple
            />
          </div>
        </div>
      </div>
      <div class="upload-step-btn-container">
        <button mat-raised-button color="accent" (click)="goBAckToInstitution()">
          {{ "previous" | i18n }}
        </button>
        <button
          class="next-button"
          mat-raised-button
          color="primary"
          (click)="this.gotToArrangement($event)"
        >
          <span>{{ "next" | i18n }}</span>
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="step === 'arrange'">
    <app-arrange-columns-table
      [records]="records"
      [fileRows]="fileRows"
      [institution]="selectedInstitution"
      [userDateFormatIndex]="userDateFormatIndex"
      [possibleDateFormats]="possibleDateFormats"
      (previousStep)="goBackToUpload()"
      (goToPreview)="goToPreview($event)"
    ></app-arrange-columns-table>
  </div>
  <div *ngIf="isDataValidated && step === 'import'">
    <app-preview-transaction-table
      [records]="records"
      [userDateFormatIndex]="userDateFormatIndex"
      [selectedInstitution]="selectedInstitution"
      [institutionCsvMapper]="selectedInstitution.csvMapper"
      (closeTable)="handleCloseTable()"
      (backToArrange)="goBackToArrange()"
    ></app-preview-transaction-table>
  </div>
</div>
-->
