import { Component, OnInit, Renderer2 } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { TokenService } from "@bitwarden/common/abstractions/token.service";
import { ImportError } from "@bitwarden/common/importers/import-error";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { LinkingNewAccountsComponent } from "@bitwarden/web-vault/app/gloss/import/linking/linking-accounts/linking-accounts.component";
import { UserChoiceDateFormatComponent } from "@bitwarden/web-vault/app/gloss/import/user-choice-date-format/user-choice-date-format.component";
import { alignmentDefaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { ArrangeStore } from "@bitwarden/web-vault/app/importers/store/arrange.import.strore";
import { ImportStore } from "@bitwarden/web-vault/app/importers/store/import.store";
import { InstitutionStore } from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import { ImportTypeStore } from "@bitwarden/web-vault/app/importers/store/type.import.store";
import { UploadStore } from "@bitwarden/web-vault/app/importers/store/upload.import.store";
import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { TransactionPlaidImporter } from "@bitwarden/web-vault/app/importers/transaction-plaid-importer";
import {
  ImportStepsEnum,
  TransactionImportType,
} from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { BasiqIoConfigService } from "@bitwarden/web-vault/app/services/api/basiqio-config.service";
import { GlossApiProxyRequest } from "@bitwarden/web-vault/app/shared/utils/helper.glos-api-call/gloss-api-proxy-request";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";

import { PlaidLinkHandler } from "../../../app/models/enum/plaidLinkHandlerType";
import { PlaidConfigService } from "../../../app/services/api/plaid-config.service";
import { ScriptLoaderService } from "../../../app/services/script-loader-service";
import { PlaidValidator } from "../../../app/validators/plaid-validator";

import "./importer.scss";

interface PlaidConfig {
  clientName: string;
  token: string;
}

declare global {
  interface Window {
    Plaid: {
      create: (config: PlaidConfig) => PlaidLinkHandler;
      get: (endpoint: string, options: any, callback: (error: any, response: any) => void) => void;
    };
  }
}

@Component({
  selector: "app-import-management",
  templateUrl: "./import-management.component.html",
  styles: ["importer.scss"],
  providers: [
    PlaidValidator,
    PlaidConfigService,
    ScriptLoaderService,
    BasiqIoConfigService,
    ImportStore,
    ImportTypeStore,
    InstitutionStore,
    UploadStore,
    ArrangeStore,
  ],
})

/*TODO Clean this component with BEST PRACTICES of CLEAN CODE @Sinan*/
export class ImportManagementComponent implements OnInit {
  loading: boolean;
  isDataValidated = false;
  formPromise: Promise<ImportError>;
  linkHandler: any;
  publicToken: string;
  records: validationResult = undefined;
  localEnv = false;
  isImportFromApi = false;
  isBasiqJobsReady = false;
  showTable = true;
  isFromBasiq = false;
  userDateFormatIndex: DateFormatIndex;
  private dialogueRef: MatDialogRef<LinkingNewAccountsComponent>;
  private userDateFormatRef: MatDialogRef<UserChoiceDateFormatComponent>;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  userId: string;
  items: any[] = [];
  selectedItems: any[] = [];

  readonly vm$ = this.importStore.vm$;

  constructor(
    private tokenService: TokenService,
    private logService: LogService,
    private plaidValidator: PlaidValidator,
    private plaidConfigService: PlaidConfigService,
    private renderer: Renderer2,
    private scriptLoaderService: ScriptLoaderService,
    private transactionPlaidImporter: TransactionPlaidImporter,
    public dialog: MatDialog,
    private globalService: GlobalService,
    private readonly importStore: ImportStore,
    private readonly institutionStore: InstitutionStore,
    private institutionService: InstitutionService,
    private dataRepositoryService: DataRepositoryService,
    private route: ActivatedRoute,
    private basiqImporter: TransactionBasiqImporter,
    private basiqAuth: BasiqAuth
  ) {}

  async ngOnInit() {
    if (process.env.NODE_ENV === "development") {
      this.localEnv = true;
    }
    this.loading = true;
    //TODO see why we initialise plaid here .. cuz it is giving http errors..
    try {
      //  this.initializePlaidLinkHandler();
      /*TODO once new import flow is ready this piece of basiqImport will be obsolete*/
      const goTo = this.route.snapshot.queryParamMap.get("goTo");
      const jobIds = this.route.snapshot.queryParamMap.get("jobIds");
      if (jobIds) {
        this.isFromBasiq = true;
      }
      if (goTo) {
        if (goTo === "basiqImport") {
          this.isImportFromApi = true;
        }

        if (goTo === "csv") {
          this.importCsv();
        }
      }
      this.tokenService.getUserId().then(async (userId) => {
        this.userId = userId;
        await this.listFiles();
      });
      await this.handleBasiqRedirect();
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.logService.error(e);
    }
  }

  //-------------------------------------------------------------------------------------------//

  async listFiles() {
    const glossRequestObject = new GlossApiProxyRequest({ endpoint: "listFile" });
    return await this.dataRepositoryService
      .send(
        glossRequestObject.method,
        glossRequestObject.path,
        glossRequestObject.data,
        glossRequestObject.authed,
        glossRequestObject.hasResponse,
        glossRequestObject.url,
        glossRequestObject.alterHeaders
      )
      .then((response) => {
        const body = JSON.parse(JSON.stringify(response));
        body.forEach((item: any) => {
          if (item.fileName !== "") {
            this.items.push({ name: item.fileName });
          }
        });
      });
  }

  /*  downloadFile(fileName: string) {
    this.dataRepositoryService
      .send(
        "GET",
        `/files/${fileName}`,
        null,
        true,
        true,
        "http://mini.ironfly.syd:3000/local/v1",
        null
      )
      .then((response: Blob) => {
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }*/

  /*  deleteFile() {
    const deleteData: { name: string }[] = [];
    this.selectedItems.forEach((item, index) => {
      if (item === true) {
        deleteData.push({ name: this.items[index].name });
      }
    });
    this.dataRepositoryService
      .send(
        "DELETE",
        `/files`,
        { data: deleteData },
        true,
        false,
        "http://mini.ironfly.syd:3000/local/v1"
      )
      .then(() => {
        deleteData.forEach((data) => {
          this.items = this.items.filter((item) => item.name !== data.name);
        });
        this.selectedItems = [];
      });
  }*/

  // ------------------------------  user pre-signed url to upload file ------------------------------//
  // if (file){
  //   this.http.post<{body: string}>('http://localhost:3000/upload', {userId: this.tokenData.id}).subscribe(
  //     (response) => {
  //       const uploadUrl = JSON.parse(response.body).url;
  //       console.log(uploadUrl);
  //       try {
  //         this.http.put(uploadUrl, file, {
  //           headers: {
  //             'Content-Type': file.type
  //           }
  //         }).subscribe({
  //           next: (res) => {
  //             console.log('File uploaded successfully:', res);
  //           },
  //           error: (err) => {
  //             console.error('Error uploading file:', err);
  //           }
  //         })
  //         console.log("file upload end");
  //       } catch (e) {
  //         console.error('Error uploading file:', e);
  //         console.log("file upload end");
  //       }
  //     },
  //     (error) => {
  //       console.error('Error uploading file:', error);
  //       console.log("file upload end");
  //     }
  //   );
  // }
  // }
  // }

  //-------------------------------------------------------------------------------------------//

  async handleBasiqRedirect() {
    const shouldCallBasiq = await this.basiqImporter.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      await this.importBasiqIo();
    } else if (this.isFromBasiq) {
      this.globalService.showWarningMessage("warning", "couldNotRetrieveYourDataPleaseTryAgain");
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  goToImportFromApi() {
    this.isImportFromApi = true;
  }

  goBackToMainSection() {
    this.isImportFromApi = false;
    this.showTable = false;
  }

  async importPlaid() {
    try {
      this.loading = true;
      /** Load transactions and accounts from plaid */
      const access_token = await this.plaidConfigService.exchangePlaidToken(this.publicToken);
      const plaidResponse = await this.plaidConfigService.fetchPlaidTransactions(access_token);
      const plaidTransactions = plaidResponse.transactions;
      const plaidAccounts = plaidResponse.accounts;

      /** Show message if there are no transactions */
      if (plaidTransactions.length === 0) {
        this.globalService.showErrorMessage("errorOccurred", "noTransactionsFound");
        this.loading = false;

        return;
      }

      const plaidResults = await this.transactionPlaidImporter.parse(plaidTransactions);
      this.userDateFormatIndex = plaidResults.dateFormatIndex;

      /** set transaction account ids according to gloss*/
      const accountedTransactions =
        await this.transactionPlaidImporter.getAccountedTransactionsOfPlaid(
          plaidResults.transactions,
          plaidAccounts
        );

      /** Process the transactions whose accounts , date formats have been set*/
      await this.processAfterDateFormat(accountedTransactions, this.userDateFormatIndex);
    } catch (e) {
      this.logService.error(e);
    }
  }

  async goToPreview() {
    /*    this.importStore.setBalanceAlignmentObject(balanceAlignmentObject);
    this.arrangeStore.setRecords(records);

    //TODO check if institutions are working after preview is removed

    /!*Import transactions*!/
    await this.transactionImportService.import(records.preview, records.newAccounts);

    records.newAccounts.map((account: UnrecognizedAccount) => {
      account._id = crypto.randomUUID();
      return account;
    });
    await this.bookService.createSourceBooks(records.newAccounts);

    this.store.dispatch(TransactionActions.load());
    this.store.dispatch(RevaluationActions.create());

    this.loading = false;
    await this.router.navigate(["/primary-dashboard"]);*/
  }

  async importBasiqIo() {
    /*   try {
       this.isImportFromApi = true;
        this.showTable = true;
        this.loading = true;
        const { records, balanceAlignmentObject } = await this.basiqImporter.getBasiqData();
        if (records && balanceAlignmentObject) {
          if (records.preview.length) {
            await this.goToPreview(records, balanceAlignmentObject);
          } else {
            this.globalService.showWarningMessage("warning", "noTransactionToImport");
          }
        }
        this.loading = false;
      } catch (e) {
        this.logService.error(e);
      }*/
  }

  async connectToNewInstitution() {
    try {
      await this.basiqAuth.connectToAnotherInstitution();
    } catch (e) {
      this.logService.error(e);
    }
  }
  async updateConsent() {
    try {
      await this.basiqAuth.updateConsent();
    } catch (e) {
      this.logService.error(e);
    }
  }

  async extendConsents() {
    try {
      await this.basiqAuth.extendConsent();
    } catch (e) {
      this.logService.error(e);
    }
  }

  async manageConsents() {
    try {
      this.loading = true;
      await this.basiqAuth.manageConsent();
      this.loading = false;
    } catch (e) {
      this.logService.error(e);
    }
  }

  async processAfterDateFormat(plaidTransactions: any[], dateFormatIndex: DateFormatIndex) {
    /** Validate the transactions for preview */
    this.records = await this.plaidValidator.matchTransactionRecord(
      plaidTransactions,
      dateFormatIndex
    );

    /** Prompt user to act on new accounts*/
    if (this.records.newAccounts.length > 0) {
      await this.openLinkDialogue(this.records);
    } else {
      this.isDataValidated = true;
    }

    this.loading = false;
  }

  async openLinkDialogue(records: validationResult) {
    const dialogRef = this.dialog.open(LinkingNewAccountsComponent, {
      width: "800px",
      disableClose: true,
      data: {
        records: records,
        actionSucceeded: this.actionSucceeded.bind(this),
      },
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((records: validationResult) => {
        if (records) {
          this.records = records;
          this.isDataValidated = true;
        }
      });
  }

  async openUserDateFormatDialogue(possibleDateFormats: string[], formattedTransactions: any[]) {
    const dialogRef = this.dialog.open(UserChoiceDateFormatComponent, {
      width: "800px",
      disableClose: true,
      data: {
        possibleDateFormats: possibleDateFormats,
        formatSelected: this.formatSelected.bind(this),
      },
    });

    this.userDateFormatRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((dateFormat: string) => {
        if (dateFormat) {
          this.userDateFormatIndex = this.getDateFormatIndexByString(dateFormat);
          this.processAfterDateFormat(formattedTransactions, this.userDateFormatIndex);
        }
      });
  }
  handleCloseTable() {
    this.isDataValidated = false;
  }

  openLink() {
    this.importStore.startPlaidImport();
    if (!this.linkHandler) {
      this.initializePlaidLinkHandler();
      this.linkHandler.open();
    } else if (this.linkHandler.open) {
      this.linkHandler.open();
    }
  }

  importCsv() {
    this.showTable = true;
    this.importStore.startCsvImport();
  }

  async importCommonWealthBank() {
    /* TODO @sinan make this better to cover existing institutions as well  */
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    const cwInsto = institutions.length ? institutions[0] : null;
    if (!cwInsto) {
      const countries = await this.institutionService.getCountryMasterList();
      const australia = countries.find((country) => country.code === "AU");
      const allInstitutions = await this.institutionService.getCountryInstitutions(australia);
      const commonWealth = await this.dataRepositoryService.createInstitution(allInstitutions[0]);
      if (!commonWealth.balanceAlignmentMapper) {
        commonWealth.balanceAlignmentMapper = alignmentDefaultMapping;
      }
      this.institutionStore.setSelectedInstitution(commonWealth);
      this.institutionStore.setInstitutions([...institutions, commonWealth]);
    } else {
      if (!cwInsto.balanceAlignmentMapper) {
        cwInsto.balanceAlignmentMapper = alignmentDefaultMapping;
      }
      this.institutionStore.setInstitutions([...institutions]);
      this.institutionStore.setSelectedInstitution(cwInsto);
    }
    this.importCsv();
  }

  async importWestpacBank() {
    /* TODO @sinan make this better to cover existing institutions as well  */
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    const wpInsto = institutions.find((insto) => insto.name === "Westpac Banking Corporation");
    if (!wpInsto) {
      const countries = await this.institutionService.getCountryMasterList();
      const australia = countries.find((country) => country.code === "AU");
      const allInstitutions = await this.institutionService.getCountryInstitutions(australia);
      const westPac = await this.dataRepositoryService.createInstitution(allInstitutions[1]);
      if (!westPac.balanceAlignmentMapper) {
        westPac.balanceAlignmentMapper = alignmentDefaultMapping;
      }
      this.institutionStore.setSelectedInstitution(westPac);
      this.institutionStore.setInstitutions([...institutions, westPac]);
    } else {
      if (!wpInsto.balanceAlignmentMapper) {
        wpInsto.balanceAlignmentMapper = alignmentDefaultMapping;
      }
      this.institutionStore.setInstitutions([...institutions]);
      this.institutionStore.setSelectedInstitution(wpInsto);
    }
    this.importCsv();
  }

  private initializePlaidLinkHandler() {
    try {
      const plaidLinkInitialize = document.getElementById(this.scriptLoaderService.elementId);
      if (plaidLinkInitialize === null) {
        this.scriptLoaderService.loadJsScript(
          this.renderer,
          this.scriptLoaderService.linkInitializeUri
        );
      }

      this.plaidConfigService.fetchPlaidToken().then((token) => {
        const config = {
          clientName: this.plaidConfigService.clientName,
          token: token,
          product: this.plaidConfigService.product,
          onSuccess: (public_token: string) => {
            this.publicToken = public_token;
            this.importPlaid().then((r) => r);
          },
        };
        this.linkHandler = window.Plaid.create(config);
      });
    } catch (e) {
      this.logService.error(e);
    }
  }

  async formatSelected(userDateFormat: string) {
    this.userDateFormatRef?.close(userDateFormat);
  }
  async actionSucceeded(records: validationResult) {
    this.dialogueRef?.close(records);
  }

  /** generate a DateFormatIndex from a string like 'YYYY/MM/DD' */
  getDateFormatIndexByString(dateFormatString: string): DateFormatIndex {
    // @sinan todo test that I'm not sure it work
    // debugger
    const parts = dateFormatString.split(/\w+/);
    const datePartsMap = new Map<string, number>();
    const separator = parts[1];

    const dateParts = dateFormatString.split(separator);
    dateParts.forEach((dp, index) => {
      datePartsMap.set(dp, index);
    });

    return {
      year: datePartsMap.get("YYYY"),
      month: datePartsMap.get("MM"),
      day: datePartsMap.get("DD"),
      separator,
      format: "",
    };
  }

  protected readonly TransactionImportType = TransactionImportType;
  protected readonly ImportStepsEnum = ImportStepsEnum;
}
