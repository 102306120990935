<footer
  class="tw-border-t-1 tw-h-[4em] tw-w-full tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-solid tw-border-t-neutral-500"
>
  <div class="tw-flex tw-justify-between tw-p-4 sm:tw-flex-col">
    <div>&copy; {{ year }} Global Symbology Ventures</div>
    <div>
      {{ "versionNumber" | i18n : version }}
    </div>
  </div>
</footer>
