<div
  class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button"
>
  <div class="account-info-container tw-flex tw-flex-col">
    <span
      class="account-name tw-mb-0.5 tw-text-[13px] tw-font-semibold tw-leading-[125%] tw-text-[#292D32]"
      >{{ item.label }}</span
    >
    <ng-content></ng-content>
  </div>

  <div class="account-buttons-container tw-flex sm:tw-flex-col">
    <app-gloss-button [options]="editTransaction" class="tw-w-1/2 md:tw-w-full"></app-gloss-button>

    <app-gloss-button
      [options]="removeTransaction"
      class="tw-w-1/2 md:tw-w-full"
    ></app-gloss-button>
  </div>
</div>
