import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SecuritySettingsRoute } from "../../models/enum/routes.enum";
import { SecurityKeysComponent } from "../security-keys.component";
import { SettingsRouteComponent } from "../settings-route.component";
import { TwoFactorSetupComponent } from "../two-factor-setup.component";

import { ChangePasswordComponent } from "./change-password/change-password.component";

const routes: Routes = [
  {
    path: "",
    component: SettingsRouteComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "security" },
      {
        path: SecuritySettingsRoute.ChangePassword,
        component: ChangePasswordComponent,
        data: { titleId: "masterPassword" },
      },
      {
        path: SecuritySettingsRoute.LoginDevices,
        component: TwoFactorSetupComponent,
        data: { titleId: "loginDevices" },
      },
      {
        path: "security-keys",
        component: SecurityKeysComponent,
        data: { titleId: "keys" },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SecurityRoutingModule {}
