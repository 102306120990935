import { SelectionModel } from "@angular/cdk/collections";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";

import { PreviewTransactionMapper } from "@bitwarden/web-vault/app/importers/data-mapper/preview-transaction-mapper";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";

import { Transaction } from "../../models/data/blobby/transaction.data";

export class HelperListTable {
  dataSource: MatTableDataSource<Transaction>;
  selection: SelectionModel<any>;
  editIndex = -1;
  editForm: FormGroup;
  displayedColumns: Array<any>;

  constructor(dataSource: MatTableDataSource<Transaction>) {
    this.dataSource = dataSource;
    this.editForm = new FormGroup({
      categories: new FormControl(), // Initialize the form control
    });
  }

  /** Generate the transaction table header */
  generateHeader(transaction: Transaction) {
    const headers: string[] = [];
    const objectKeys = Object.keys(transaction) as Array<keyof Transaction>;

    PreviewTransactionMapper.header.forEach((field: string) => {
      for (const key of objectKeys) {
        const isMatched = field.match(key);
        if (isMatched && transaction[key]) {
          headers.push(field);
          break;
        }
      }
    });
    return headers;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeSelectedRows() {
    this.dataSource.data = this.dataSource.data.filter((u: any) => !this.selection.isSelected(u));

    // this is not updating the dataSource in the preview-transaction-table component
    // Todo create a data-source-service to be shared between preview-transaction-table, list-table and helper-list-table
    this.selection.clear();
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row: any) => this.selection.select(row));
    }
  }

  isAllSelected() {
    return this.dataSource.data.every((item: any) => this.selection.isSelected(item));
  }

  isAnySelected() {
    return this.dataSource.data.some((item: any) => this.selection.isSelected(item));
  }

  deleteRow(item: any) {
    this.dataSource.data = this.dataSource.data.filter((u: any) => u !== item);

    // Todo create a data-source-service to be shared between preview-transaction-table, list-table and helper-list-table
    // Remove the item from the selection
    this.selection.deselect(item);
  }

  startEdit(index: number) {
    this.editIndex = index;
    const { categories } = this.dataSource.data[index];
    this.editForm.controls["categories"].setValue(this.formattedCategories(categories));
  }

  saveEdit() {
    const editedItem = this.editForm.value;
    const editedCategories = editedItem.categories.split(", ");
    const formattedCategories = editedCategories.map((category: any) => ({ name: category }));

    // Update the edited item's categories
    this.dataSource.data[this.editIndex].categories = formattedCategories;
    this.cancelEdit();
  }

  cancelEdit() {
    this.editIndex = -1;
    this.editForm.reset();
  }

  formattedCategories(categories: SplitCategoryType[]): string {
    if (!categories || categories.length === 0) {
      return ""; // Return an empty string if categories are not provided
    }

    const categoryNames = categories.map((category) => category.name).join(", ");
    return categoryNames;
  }

  clearInput(control: AbstractControl) {
    control.setValue("");
  }

  getRowColor(row: any): string {
    // Check the cell value of the specified column for the row
    const cellValue = row["status"];

    // Return the appropriate CSS class based on the cell value
    return cellValue === true ? "row-duplicate" : "row-new";
  }
}
