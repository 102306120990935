import { add, format } from "date-fns";

import {
  BalanceDateElement,
  DateStartPreferences,
} from "@bitwarden/web-vault/app/models/types/balance.types";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GraphDataSet, GroupingDates } from "@bitwarden/web-vault/app/models/types/graph.types";
import { GranularityDates } from "@bitwarden/web-vault/app/services/dashboard/graph/granularityDates";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { TransactionBalancesWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.message";
import { TransactionBalancesWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.result";
import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

export class GraphTools {
  granularityDates: GranularityDates;

  constructor(datePreferences?: DateStartPreferences) {
    this.granularityDates = new GranularityDates(datePreferences);
  }

  getGraphDates(startDate: Date, endDate: Date, granularity: GranularityProperty): GroupingDates {
    const prevStartDate = this.granularityDates.getPreviousGranularityDate(
      new Date(startDate),
      granularity
    );

    const fullSetOfGroupingKeys = this.granularityDates.getGranularityGroupingKeys(
      new Date(prevStartDate),
      new Date(endDate),
      granularity
    );
    return fullSetOfGroupingKeys;
  }

  async formatDataSets(
    transactionBalancesResult: TransactionBalancesWorkerResult,
    groupingKeys: GroupingDates,
    startDate: Date,
    endDate: Date,
    blankDefault?: number
  ): Promise<Array<GraphDataSet>> {
    if (!startDate || !endDate) {
      return [];
    }

    const formattedDataSet: Array<GraphDataSet> = [];
    let prevBalance = blankDefault;
    const balanceCombined = this.combineBalanceInformation(transactionBalancesResult);
    let middleBalanceDate = null;
    let middleTimestamp = null;

    for (const groupingKey in groupingKeys) {
      const grouping = groupingKeys[groupingKey];
      const dataSet = this.createBlankDataSet(blankDefault);
      dataSet.date = format(grouping.startDate, "yyyyMMdd");
      dataSet.endDate = format(grouping.endDate, "yyyyMMdd");
      dataSet.nextStartDate = format(this.getNextDay(grouping.endDate), "yyyyMMdd");
      dataSet.prevBalance = prevBalance;

      const endTimestamp = this.dateToTimestamp(grouping.endDate);
      dataSet.timeStamp = endTimestamp;

      if (grouping.endDate.getTime() < new Date(new Date(startDate).toDateString()).getTime()) {
        middleBalanceDate = new Date(new Date(startDate).toDateString());
        dataSet.midDate = middleBalanceDate;
        middleTimestamp = middleBalanceDate.getTime() / 1000 - 1;
      }

      if (grouping.endDate.getTime() > new Date(new Date(endDate).toDateString()).getTime()) {
        middleBalanceDate = new Date(new Date(endDate).toDateString());
        dataSet.midDate = middleBalanceDate;
        middleTimestamp = middleBalanceDate.getTime() / 1000;
      }

      // check for the matching endDate in the balanceCombined array
      // if the middle date is set, then look for the balance on the middle date
      if (dataSet?.midDate) {
        // const middleTimestamp = middleBalanceDate.getTime() / 1000;
        if (balanceCombined?.[middleTimestamp]) {
          dataSet.balance = balanceCombined?.[middleTimestamp].balance;
          dataSet.timeStamp = middleTimestamp;
          dataSet.in = balanceCombined?.[middleTimestamp].in;
          dataSet.out = balanceCombined?.[middleTimestamp].out;
        }
      } else if (balanceCombined?.[endTimestamp]) {
        dataSet.balance = balanceCombined?.[endTimestamp].balance;
        dataSet.in = balanceCombined?.[endTimestamp].in;
        dataSet.out = balanceCombined?.[endTimestamp].out;
      }
      if (dataSet.balance !== null) {
        prevBalance = dataSet.balance;
      }
      formattedDataSet.push(dataSet);
    }
    return formattedDataSet;
  }

  combineBalanceInformation(
    transactionBalancesResult: TransactionBalancesWorkerResult
  ): Record<number, BalanceDateElement> {
    const balanceArray = new Float64Array(transactionBalancesResult.balanceAmounts);
    const balanceDates = new Uint32Array(transactionBalancesResult.balanceDates);
    const balanceIn = new Float64Array(transactionBalancesResult.balanceIn);
    const balanceOut = new Float64Array(transactionBalancesResult.balanceOut);
    const balanceCombined: Record<number, BalanceDateElement> = {};

    for (const index in balanceDates) {
      const balanceTimeStamp = balanceDates[index];
      balanceCombined[balanceTimeStamp] = {
        balance: balanceArray[index],
        date: new Date(balanceTimeStamp * 1000),
        in: balanceIn[index],
        out: balanceOut[index],
      };
    }
    return balanceCombined;
  }

  getNextDay(date: Date) {
    return add(date, { days: 1 });
  }

  createBlankDataSet(blankDefault: number): GraphDataSet {
    return {
      date: "",
      endDate: "",
      nextStartDate: "",
      in: blankDefault,
      out: blankDefault,
      balance: blankDefault,
      prevBalance: blankDefault,
      estimateIn: blankDefault,
      estimateOut: blankDefault,
      estimateBalance: blankDefault,
      estimatePrevBalance: blankDefault,
      lastTransactionDate: null,
      accountGrouping: {},
    };
  }

  dateToTimestamp(date: Date) {
    return Math.round(date.valueOf() / 1000);
  }

  async buildBalanceForGraphing(
    webWorkerQueue: WebWorkerQueue,
    id: string,
    balanceGraphGroupings: GroupingDates,
    graphStartDate: Date,
    graphEndDate: Date,
    graphTransactionDatesBuffer: ArrayBuffer,
    graphTransactionAmountsBuffer: ArrayBuffer
  ): Promise<WorkerMessage> {
    const balanceDates = this.getDates(balanceGraphGroupings, graphStartDate, graphEndDate);

    // get the balance breakdown for the graphing component
    const balanceRequest = new TransactionBalancesWorkerMessage(
      id,
      "getBalances",
      graphTransactionDatesBuffer,
      graphTransactionAmountsBuffer,
      null,
      new Uint32Array(balanceDates).buffer
    );

    const graphBalancePromise = webWorkerQueue.postMessagePromise(balanceRequest);

    // get the account breakdown of the transactions for HUD
    return graphBalancePromise;
  }

  getDates(groupingDates: GroupingDates, startDate: Date, endDate: Date): Array<number> {
    const zeroedStartDate = new Date(startDate);
    zeroedStartDate.setHours(0, 0, 0, 0);
    const zeroedEndDate = new Date(endDate);
    zeroedEndDate.setHours(0, 0, 0, 0);

    const startDateTimeStamp = this.dateToTimestamp(zeroedStartDate);
    const openingStartDateStamp = startDateTimeStamp - 1;
    const graphDates: Array<number> = [openingStartDateStamp];
    for (const groupingKey in groupingDates) {
      const grouping = groupingDates[groupingKey];
      if (grouping.endDate < zeroedStartDate || grouping.endDate >= endDate) {
        continue;
      }
      graphDates.push(this.dateToTimestamp(grouping.endDate));
    }

    graphDates.push(this.dateToTimestamp(zeroedEndDate));
    return graphDates;
  }
}
