<best-scenario-earnings-card>
  <app-earnings-header
    header
    [header]="earningsData.header"
    [headerDescription]="earningsData.headerDescription"
  >
  </app-earnings-header>

  <app-earnings-content
    content
    [earningAmount]="earningsData.earningAmount"
    [currency]="earningsData.currency"
  >
  </app-earnings-content>

  <app-earnings-footer footer [footerDescription]="earningsData.footerDescription">
  </app-earnings-footer>
</best-scenario-earnings-card>
