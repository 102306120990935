<ng-template #filePreviewTemplate>
  <div class="!tw-flex tw-h-full tw-w-full">
    <ng-container *ngIf="data.file && data.file.type">
      <ng-container
        *ngIf="isSupportedFileType(data.file.type) && !isAndroidMobile; else unsupportedFile"
      >
        <iframe
          [src]="data.file | fileUrl"
          class="tw-h-full tw-w-full tw-rounded-xl tw-border-none"
        ></iframe>
      </ng-container>
      <ng-template #unsupportedFile>
        <p>{{ "previewNotAvailable" | i18n }}</p>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!data.file || !data.file.type">
      <p>{{ "FileDataNotAvailable" | i18n }}</p>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="hasContainer; else noContainer">
  <div
    class="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-4 tw-rounded-xl tw-bg-white tw-px-12 tw-py-8"
  >
    <span class="tw-text-2xl xs:tw-text-lg">{{ "filePreview" | i18n }}</span>
    <div class="!tw-flex tw-h-full tw-w-full">
      <ng-container *ngTemplateOutlet="filePreviewTemplate"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noContainer>
  <ng-container *ngTemplateOutlet="filePreviewTemplate"></ng-container>
</ng-template>
