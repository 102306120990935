import { Injectable } from "@angular/core";

import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { DateStartPreferences } from "@bitwarden/web-vault/app/models/types/balance.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

import { Transaction } from "../../../models/data/blobby/transaction.data";

@Injectable({
  providedIn: "root",
})
export class BalanceAlignmentWorkerService {
  private port1: MessagePort;
  private worker: Worker;

  constructor(
    transactions: Array<Transaction>,
    dashboardService: DashboardService,
    baseCurrency: string,
    referenceData: ReferenceData[],
    datePreferences: DateStartPreferences
    // callback: (transactions: Array<Transaction>) => void
  ) {
    // Create a MessageChannel
    const channel = new MessageChannel();
    this.port1 = channel.port1; // Port to use on the main thread
    const port2 = channel.port2; // Port to transfer to the web worker

    // Create the web worker and transfer port2
    this.worker = new Worker(new URL("./balance-alignment.worker", import.meta.url), {
      type: "module",
    });

    // Transfer port2 to the worker
    this.worker.postMessage(
      {
        port: port2,
        transactions: transactions,
        baseCurrency: baseCurrency,
        referenceData: referenceData,
        datePreferences: datePreferences,
      },
      [port2]
    );

    // Set up message handling on port1
    this.port1.onmessage = (event) => {
      if (event?.data?.transactions) {
        // convert the arrays back to transactions
        const transactions = DataTransformer.castToTransactionArray(event.data.transactions);
        dashboardService.setBalancedTransactions(transactions);
      }
    };

    // Send a message through port1
    this.port1.postMessage({ message: "Hello from main thread!" });
  }
}
