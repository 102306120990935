<div class="tw-flex tw-h-full tw-flex-col sm:tw-justify-between">
  <ng-container *ngIf="isMobileFirstSetup">
    <div>
      <div class="tw-text-center">
        <logo-header [text]="'biometricVerification' | i18n"></logo-header>
        <span> {{ "biometricVerificationDesc" | i18n }}</span>
      </div>

      <div class="tw-mt-5 tw-text-center">
        <icon [name]="'face-id'" [size]="16"></icon>
        <icon [name]="'fingerprint'" [size]="16"></icon>
      </div>
    </div>
    <div class="tw-flex tw-gap-2 xs:tw-flex-col">
      <app-gloss-button class="button-resize" [options]="enableBiometricsOptions">
      </app-gloss-button>
      <app-gloss-button class="button-resize sm:tw-hidden" [options]="cancelButtonOptions">
      </app-gloss-button>
    </div>
  </ng-container>
  <div>
    <div class="tw-w-full tw-rounded-lg tw-bg-white tw-p-6">
      <span class="tw-text-xl tw-font-medium">{{ "thisDevice" | i18n }}</span>
      <ng-container *ngTemplateOutlet="deviceInfo"></ng-container>
      <div
        class="tw-flex tw-justify-between tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-solid tw-border-neutral-300 tw-py-4"
      >
        <span class="tw-text-sm">{{ "enableBiometricVerification" | i18n }}</span>
        <toggle-switch
          (onClick)="onEnableBiometrics($event)"
          [isEnabled]="enableToggle"
        ></toggle-switch>
      </div>
    </div>
    <!--    <div>-->
    <!--      <span class="tw-text-xl tw-font-medium tw-mb-2">{{ "otherDevices" | i18n }}</span>-->
    <!--      <div class="tw-w-full tw-rounded-lg tw-bg-white tw-p-6">-->
    <!--        <ng-container *ngTemplateOutlet="deviceInfo"></ng-container>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <ng-template #deviceInfo>
    <div class="tw-flex tw-gap-4">
      <!--      <div class="tw-w-8">Icon</div>-->
      <div class="tw-flex tw-flex-col tw-py-4">
        <!--        <span class="tw-text-xl">Current session</span>-->
        <span class="tw-text-lg tw-capitalize">{{ deviceType }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #webAuthnTemplate></ng-template>
</div>
