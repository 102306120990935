import { Component, EventEmitter, Output, Input } from "@angular/core";

import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

export type transactionDirectionType = TransactionDirection.In | TransactionDirection.Out;

@Component({
  selector: "app-auto-transaction-direction",
  templateUrl: "./auto-complete-transaction-direction.component.html",
})
export class AutoCompleteTransactionDirectionComponent {
  transactionDirection: string[] = [TransactionDirection.In, TransactionDirection.Out];
  direction: transactionDirectionType = TransactionDirection.In;

  @Input() label: string;
  @Input() newDirection: transactionDirectionType;

  @Output() selectTransactionDirection = new EventEmitter<transactionDirectionType>();

  /**
   * Emits the transactionDirectionSelect event to deal with create transaction event.
   * @param {string} direction - The direction that was selected.
   */
  transactionDirectionSelected(direction: transactionDirectionType) {
    if (this.selectTransactionDirection) {
      this.selectTransactionDirection.emit(direction);
      this.setDirectionValue(direction);
    }
  }

  selectionCleared() {
    this.selectTransactionDirection.emit(null);
  }

  setDirectionValue(direction: transactionDirectionType) {
    this.direction = direction;
  }
}
