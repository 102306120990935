import { Component, EventEmitter, Output, OnInit } from "@angular/core";

import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";

/**
 * CountriesComponent is a component that provides a form field for selecting countries.
 * It uses Angular Material's autocomplete feature to suggest countries as the user types.
 * The selected country is emitted as events.
 * TODO: Add a feature to select multiple countries.
 * TODO: Add a feature of custom styling.
 */
@Component({
  selector: "app-countries",
  templateUrl: "./countries.component.html",
})
export class CountriesComponent implements OnInit {
  countries: Country[] = [];
  constructor(
    private institutionService: InstitutionService,
    private preferenceService: PreferenceService
  ) {}

  async ngOnInit() {
    this.countries = await this.institutionService.getCountriesMasterListObservable();
    await this.filterCountriesInWizard();
  }

  /** If in wizard use selected region only */
  async filterCountriesInWizard() {
    const isInWizard = !!localStorage.getItem("inWizard");
    if (isInWizard) {
      const userLocation = (await this.preferenceService.get(
        PreferenceType.userLocation
      )) as string;
      this.countries = this.countries.filter((country) => country.code === userLocation);
    }
  }

  /**
   * Event that is emitted when a country is selected.
   */
  @Output() selectedCountry: EventEmitter<Country> = new EventEmitter<Country>();

  /**
   * Event that is emitted when multiple countries are selected.
   */
  @Output() selectedCountries: EventEmitter<Country[]> = new EventEmitter<Country[]>();

  /**
   * Emits the selectedCountry event with the provided country.
   * @param {Country} country - The country that was selected.
   */
  countrySelected(country: Country) {
    if (this.selectedCountry) {
      this.selectedCountry.emit(country);
    }
  }
  /**
   * Clears the selection of the country.
   * This method is used to emit a null value to the `selectedCountry` event emitter,
   * indicating that the country selection has been cleared.
   */
  selectionCleared() {
    this.selectedCountry.emit(null);
  }
}
