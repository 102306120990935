import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import {
  AlignmentMappingItem,
  MappingConfigurationItem,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { ArrangeStore } from "@bitwarden/web-vault/app/importers/store/arrange.import.strore";
import { ImportStore } from "@bitwarden/web-vault/app/importers/store/import.store";
import {
  ImportInstitutionsInterface,
  InstitutionStore,
} from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import {
  ImportUploadInterface,
  UploadStore,
} from "@bitwarden/web-vault/app/importers/store/upload.import.store";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import { ImportState } from "@bitwarden/web-vault/app/models/types/import.types";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { TransactionState } from "@bitwarden/web-vault/app/services/DataService/transaction/state/transaction.reducer";
import { HelperListTable } from "@bitwarden/web-vault/app/shared/utils/helper-list-table";
import Actions from "@bitwarden/web-vault/app/state/app.actions";

import { BookService } from "../../../app/services/DataService/book/book.service";
import { SourceImportService } from "../../../app/services/DataService/source-import/source-import.service";
import { TransactionImportService } from "../../../app/services/transaction-import.service";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { TransactionConversionService } from "../../services/DataService/transaction/transaction-conversion.service";
import "./preview.scss";

@Component({
  selector: "app-preview-transaction-table",
  templateUrl: "./preview-transaction-table.component.html",
  styles: ["preview.scss"],
  providers: [TransactionConversionService],
})
export class PreviewTransactionTableComponent implements OnInit, AfterViewInit {
  @ViewChild("cardContentRef") cardContentRef: ElementRef;
  @Output() closeTable: EventEmitter<void> = new EventEmitter<void>();
  @Output() backToArrange: EventEmitter<void> = new EventEmitter<void>();
  records: any;
  userDateFormatIndex: DateFormatIndex;
  institutionCsvMapper: MappingConfigurationItem[];
  selectedInstitution: Institution;
  importState: ImportState;
  uploadState: ImportUploadInterface;
  columns: Array<any>;
  displayedColumns: Array<any>;
  dataSource: any;
  previewData: Transaction[] = [];
  helperListTable: HelperListTable;
  loading = false;
  institutionState: ImportInstitutionsInterface = null;
  vmInstitution$ = this.institutionStore.vmInstitution$;
  vmUpload$ = this.uploadStore.vmUpload$;
  vmArrange$ = this.arrangeStore.vmArrange$;
  vmImport$ = this.importStore.vm$;

  private destroy$: Subject<boolean> = new Subject();
  private viewChecked = false;

  constructor(
    private logService: LogService,
    private i18nService: I18nService,
    private sourceImportService: SourceImportService,
    private transactionImportService: TransactionImportService,
    private institutionService: InstitutionService,
    private platformUtilsService: PlatformUtilsService,
    private datePipe: DatePipe,
    private router: Router,
    private transactionConversion: TransactionConversionService,
    private globalService: GlobalService,
    private bookService: BookService,
    private store: Store<TransactionState>,
    private institutionStore: InstitutionStore,
    private uploadStore: UploadStore,
    private arrangeStore: ArrangeStore,
    private importStore: ImportStore
  ) {}

  async ngOnInit() {
    this.vmImport$.pipe(takeUntil(this.destroy$)).subscribe((importState) => {
      this.importState = importState;
    });

    this.vmInstitution$.pipe(takeUntil(this.destroy$)).subscribe((institutionState) => {
      this.institutionState = institutionState;
      this.selectedInstitution = institutionState.selectedInstitution;
      this.institutionCsvMapper = institutionState.selectedInstitution.csvMapper;
    });

    this.vmUpload$.pipe(takeUntil(this.destroy$)).subscribe((uploadState) => {
      this.userDateFormatIndex = uploadState.userDateFormatIndex;
      this.uploadState = uploadState;
    });

    this.vmArrange$.pipe(takeUntil(this.destroy$)).subscribe((arrangeState) => {
      this.records = arrangeState.records;
      /** Firstly, check if there are related transactions then link together */
      this.transactionConversion.findRelatedTransaction(this.records.preview);

      for (const item of this.records.preview) {
        const balance = isNaN(item.bankImportedBalance) ? "" : item.bankImportedBalance;
        const quantity = item._quantity.actualQuantity.amount;
        this.previewData.push({
          // todo move staless select and actions properties in the transactionView when we have that concept gloss-client#64
          balance: balance,
          amount: quantity,
          select: "-",
          ...item,
          definition: "-",
          actions: "-",
        });
      }
      /** Set the dataSource for <mat-table> */
      this.dataSource = new MatTableDataSource(this.previewData);
      this.helperListTable = new HelperListTable(this.dataSource);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngAfterViewInit() {
    this.viewChecked = true;
  }

  async getRecordAccount(accountId: string): Promise<UnrecognizedAccount> {
    for (const newAccount of this.records.newAccounts) {
      const book = await this.bookService.getByName(accountId);
      if (book) {
        if (newAccount.accountId === book.id) {
          return newAccount;
        }
      } else {
        if (newAccount.accountId === accountId) {
          return newAccount;
        }
      }
    }
  }
  async addCorrectCurrency(selected: any[]) {
    const currencifiedSelecteds = selected;
    if (this.records.noCurrencyTransactions.length > 0) {
      for (const noCurrencyTransaction of this.records.noCurrencyTransactions) {
        for (const currencifiedSelected of currencifiedSelecteds) {
          if (currencifiedSelected._id === noCurrencyTransaction._id) {
            const account: UnrecognizedAccount = await this.getRecordAccount(
              noCurrencyTransaction.accountId
            );
            currencifiedSelected._quantity.currency = account.selectedCurrency;
            currencifiedSelected._quantity.actualQuantity.symbol = account.selectedCurrency;
            break;
          }
        }
      }
    }

    return currencifiedSelecteds;
  }

  callTransactionDefinitionChange(data: {
    definition: AlignmentMappingItem;
    row: any;
    rowIndex: number;
  }) {
    const { definition, row, rowIndex } = data;
    const originalDescription = this.uploadState.fileRows[rowIndex].description;
    this.selectedInstitution.balanceAlignmentMapper =
      this.institutionState.selectedInstitution.balanceAlignmentMapper.map((mappingItem) => {
        if (mappingItem.keyOnTransaction === definition.key) {
          mappingItem.mapping.push(originalDescription);
        }

        if (mappingItem.keyOnTransaction === row.definition) {
          mappingItem.mapping = mappingItem.mapping.filter((oldItem) => {
            return (
              oldItem.toLowerCase().replace(/\s/g, "") !==
              originalDescription.toLowerCase().replace(/\s/g, "")
            );
          });
        }

        return mappingItem;
      });

    this.dataSource.data = this.dataSource.data.map((transaction: any) => {
      if (transaction._id === row._id) {
        transaction._definition = definition.keyOnTransaction;
        transaction.definition = definition.keyOnTransaction;
      }

      return transaction;
    });
  }
  async handleImportProcess() {
    try {
      this.loading = true;
      const { RevaluationActions } = Actions;
      const selected = this.dataSource.data.filter((u: any) =>
        this.helperListTable.selection.isSelected(u)
      );

      if (selected.length > 0) {
        /** Update institution csvMapper */
        if (this.selectedInstitution?.csvMapper) {
          this.selectedInstitution.csvMapper = this.institutionState.selectedInstitution.csvMapper;
          await this.institutionService.update(this.selectedInstitution, false);
        }

        /** process the noCurrencyTransactions */
        const currencififedSelecteds = await this.addCorrectCurrency(selected);
        if (this.selectedInstitution) {
          for (const newAccount of this.records.newAccounts) {
            newAccount.institutionAccountLink = {
              institutionId: this.selectedInstitution.id,
              institutionAccountId: newAccount.institutionAccountLink
                ? newAccount.institutionAccountLink.institutionAccountId
                : undefined,
            };
          }
        }

        /** Manage other imports */
        await this.transactionImportService.import(
          currencififedSelecteds,
          this.records.newAccounts
        );

        this.records.newAccounts.map((account: UnrecognizedAccount) => {
          account._id = crypto.randomUUID();
          return account;
        });
        await this.bookService.createSourceBooks(this.records.newAccounts);

        /** Manage NgRx */
        this.store.dispatch(RevaluationActions.create());

        this.loading = false;
        this.platformUtilsService.showToast("success", null, this.i18nService.t("importSuccess"));

        await this.router.navigate(["/primary-dashboard"]);
      } else {
        await this.globalService.showErrorMessage("errorOccurred", "selectAtLeastOneTransaction");
        this.loading = false;
      }
    } catch (e) {
      this.loading = false;
      this.logService.error(e.message);
    }
  }

  removeSelectedRows() {
    this.helperListTable.removeSelectedRows();
  }

  async onCloseTable() {
    const confirmed = await this.platformUtilsService.showDialog(
      this.i18nService.t("allYourSettingsForImportWillDisappear"),
      this.i18nService.t("areYouSureToCancelImport"),
      this.i18nService.t("yes"),
      this.i18nService.t("no"),
      "warning",
      false,
      "app-group-add-edit .modal-content"
    );
    if (confirmed) {
      this.arrangeStore.reset();
      this.uploadStore.reset();
      this.institutionStore.reset();
      this.importStore.goBackToImportTypeSelection();
    }
    this.closeTable.emit();
  }
  goBackToArrange() {
    this.importStore.goBackToArrangement();
  }
}
