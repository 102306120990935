<ng-container>
  <app-spinner [showSpinner]="loading"></app-spinner>
  <div class="tw-flex xs:tw-flex-col md:tw-h-full">
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-h-full">
      <form
        #form
        class="tw-flex tw-w-fit tw-min-w-[270px] tw-max-w-[400px] tw-flex-col tw-gap-6 md:tw-h-full md:tw-items-center md:tw-justify-between"
        id="preference"
      >
        <div class="tw-flex tw-flex-col tw-gap-6">
          <div class="">
            <label>{{ "userLocation" | i18n }} *</label>
            <div
              class="tw-relative tw-flex tw-w-fit tw-min-w-[300px] tw-max-w-[400px] tw-items-center tw-justify-between tw-rounded-lg tw-bg-neutral-50"
            >
              <input
                disabled
                class="tw-h-12 tw-w-full tw-border-0 tw-border-neutral-100 !tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
                type="text"
                id="userLocation"
                [(ngModel)]="userLocation"
                placeholder="{{ 'selectCurrency' | i18n }}"
                aria-label="Number"
                matInput
                name="userLocation"
                [matAutocomplete]="autoUserLocation"
              />
              <svg class="tw-absolute tw-right-4 tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>
              <mat-autocomplete autoActiveFirstOption #autoUserLocation="matAutocomplete">
                <mat-option *ngFor="let option of [userLocation]" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>

          <div class="">
            <label>{{ "baseCurrency" | i18n }} *</label>
            <div
              class="tw-relative tw-flex tw-w-fit tw-min-w-[300px] tw-max-w-[400px] tw-items-center tw-justify-between tw-rounded-lg tw-bg-neutral-50"
            >
              <input
                class="tw-h-12 tw-w-full tw-border-0 tw-border-neutral-100 !tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
                type="text"
                id="baseCurrency"
                [(ngModel)]="preferredCurrency"
                placeholder="Pick a currency"
                aria-label="Number"
                matInput
                (keyup)="searchForCurrency()"
                (blur)="onBaseCurrencyBlur()"
                name="currency"
                [matAutocomplete]="autoCurrency"
              />
              <svg class="tw-absolute tw-right-4 tw-h-7 tw-w-7">
                <use xlink:href="images/sprite.svg#down-arrow" />
              </svg>
              <mat-autocomplete autoActiveFirstOption #autoCurrency="matAutocomplete">
                <mat-option *ngFor="let option of filteredCurrencies" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>

          <ng-container *ngIf="!hideField('timezone')">
            <div class="">
              <label>Timezone *</label>
              <div
                class="tw-relative tw-flex tw-w-fit tw-min-w-[300px] tw-max-w-[400px] tw-items-center tw-justify-between tw-rounded-lg tw-bg-neutral-50"
              >
                <input
                  class="tw-h-12 tw-w-full tw-border-0 tw-border-neutral-100 tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
                  type="text"
                  id="baseTimeZone"
                  [(ngModel)]="preferredTimeZone"
                  placeholder="Pick a timezone"
                  aria-label="Number"
                  (keyup)="searchForTimeZones()"
                  (blur)="onTimeZonesBlur()"
                  matInput
                  name="zone"
                  [matAutocomplete]="autoTimezones"
                />
                <mat-autocomplete autoActiveFirstOption #autoTimezones="matAutocomplete">
                  <mat-option *ngFor="let tz of filteredZones" [value]="tz">
                    {{ tz }}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </ng-container>

          <ng-container>
            <div class="">
              <label>{{ "dateFormats" | i18n }} *</label>
              <div
                class="tw-relative tw-flex tw-w-fit tw-min-w-[300px] tw-max-w-[400px] tw-items-center tw-justify-between tw-rounded-lg tw-bg-neutral-50"
              >
                <input
                  disabled
                  class="tw-h-12 tw-w-full tw-border-0 tw-border-neutral-100 !tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
                  type="text"
                  id="baseDateFormat"
                  [(ngModel)]="preferredDateFormat"
                  placeholder="Pick a date format"
                  aria-label="Date"
                  (keyup)="searchForDateFormat()"
                  (blur)="onDateFormatBlur()"
                  matInput
                  name="date"
                  [matAutocomplete]="autoDateFormat"
                />
                <svg class="tw-absolute tw-right-4 tw-h-7 tw-w-7">
                  <use xlink:href="images/sprite.svg#down-arrow" />
                </svg>
                <mat-autocomplete autoActiveFirstOption #autoDateFormat="matAutocomplete">
                  <mat-option *ngFor="let df of filteredDateFormats" [value]="df">
                    {{ df }}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </ng-container>

          <!--        todo: unused? if use, make mat-select-> mat-autocomplete to be consistent -->
          <ng-container *ngIf="!hideField('dateStart')">
            <div class="form-group">
              <label>{{ "yearStartMonth" | i18n }}</label>
              <div class="form-control">
                <mat-select [(value)]="preferredVisualYearMonthStartName" id="yearMonths">
                  <mat-option *ngFor="let ym of yearMonthsArr" [value]="ym.month">
                    {{ ym.month }}
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div class="form-group mb-4">
              <label>Week Start Day</label>
              <div class="form-control">
                <mat-select [(value)]="preferredVisualWeekDayStartName" id="weekDays">
                  <mat-option *ngFor="let wd of weekDaysArr" [value]="wd.day">
                    {{ wd.day }}
                  </mat-option>
                </mat-select>
              </div>
            </div>

            <div class="form-group mb-4">
              <label>Month Start Day</label>
              <div class="input-search-container">
                <div class="form-control">
                  <mat-select [(value)]="preferredMonthDayStart" id="monthDays">
                    <mat-option *ngFor="let md of monthDays" [value]="md">
                      {{ md }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'save' | i18n,
            type: 'submit',
            class: 'primary',
            onClick: submit.bind(this),
            isEnabled: !form.loading
          }"
        ></app-gloss-button>
      </form>
    </div>
  </div>
</ng-container>
