import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { ScenarioGroupResponse } from "@bitwarden/web-vault/app/models/data/response/scenario-group.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { ScenarioSource } from "@bitwarden/web-vault/app/models/enum/scenario-and-groups.enum";

import { anchorPoint } from "./scenario.service.test.data-anchor-point";
import {
  BestExistingAccountScenario,
  BestNewAccountScenario,
  CurrentScenario,
} from "./scenario.service.test.data-scenarios";
import { transactionFilter } from "./scenario.service.test.data-transaction-filter";

export const SystemDefaultScenarioGroup: ScenarioGroup = new ScenarioGroup(
  new ScenarioGroupResponse({
    _id: crypto.randomUUID(),
    _dateModified: new Date().toUTCString(),
    _dateCreated: new Date().toUTCString(),
    name: "AUD Comparison Scenarios",
    symbol: GLOBAL_BASE_CURRENCY,
    estimateActions: [],
    anchorPoint: anchorPoint,
    scenarios: [CurrentScenario, BestExistingAccountScenario, BestNewAccountScenario],
    userDefinedEstimates: [],
    transactionFilters: transactionFilter,
    source: ScenarioSource.system,
  })
);
