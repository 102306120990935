import { TransactionBalancesWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.message";
import { WebWorkerImplementation } from "@bitwarden/web-vault/web-worker/web.worker.implementation";

export class TransactionBalancesWorker extends WebWorkerImplementation<TransactionBalancesWorkerMessage> {
  _callback: (data: any) => void;
  _resolve: (value?: any) => void;
  _reject: (reason?: any) => void;
  _workerType = "TransactionBalancesWorker";

  protected constructor() {
    const worker = new Worker(new URL("./transaction-balances.job.ts", import.meta.url), {
      type: "module",
    });
    super(worker);

    this.addEventListener("message", this.onMessageFromWorker.bind(this));
  }

  get callback(): (data: any) => void {
    return this._callback;
  }
  set callback(callback: (data: any) => void) {
    this._callback = callback;
  }

  static getInstance(): TransactionBalancesWorker {
    return new TransactionBalancesWorker();
  }

  async getBalanceForTransactions(message: TransactionBalancesWorkerMessage) {
    try {
      this.postMessage(message, [message.transactionDates, message.transactionAmounts]);
    } catch (e) {
      this.reject(e);
    }
  }

  /**
   * function to handle the message back from the worker service
   * @param message
   */
  onMessageFromWorker(message: MessageEvent): void {
    if (message.data.complete) {
      if (this.resolve) {
        this.resolve(message.data);
      } else if (this.reject) {
        this.reject(message.data);
      }
    }
  }

  get resolve(): (value?: any) => void {
    return this._resolve;
  }
  set resolve(resolve: (value?: any) => void) {
    this._resolve = resolve;
  }

  get reject(): (value?: any) => void {
    return this._reject;
  }
  set reject(reject: (value?: any) => void) {
    this._reject = reject;
  }
}
