import { animate, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { UserVerificationComponent as BaseComponent } from "@bitwarden/angular/components/user-verification.component";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { KeyConnectorService } from "@bitwarden/common/abstractions/keyConnector.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { userVerificationInputOptions } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/component.options";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";

@Component({
  selector: "app-user-verification",
  templateUrl: "user-verification.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserVerificationComponent,
    },
  ],
  animations: [
    trigger("sent", [
      transition(":enter", [style({ opacity: 0 }), animate("100ms", style({ opacity: 1 }))]),
    ]),
  ],
})
export class UserVerificationComponent extends BaseComponent {
  constructor(
    private i18nService: I18nService,
    keyConnectorService: KeyConnectorService,
    userVerificationService: UserVerificationService
  ) {
    super(keyConnectorService, userVerificationService);
  }

  passwordInputOptions: GlossInputOptions = {
    ...userVerificationInputOptions,
    label: this.i18nService.t(userVerificationInputOptions.label),
    placeholder: this.i18nService.t(userVerificationInputOptions.placeholder),
    hint: this.i18nService.t(userVerificationInputOptions.hint),
    onInput: (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      this.secret.setValue(inputElement.value);
      this.passwordInputOptions.value = inputElement.value;
    },
  };
}
