import { Component, OnInit } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { BlobbyDataTypeEnum } from "@bitwarden/web-vault/app/models/enum/blobbyDataTypeEnum";
import { IMainProgressBar } from "@bitwarden/web-vault/app/models/interfaces/main-progress-bar";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { BasiqProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/basiq-progress-bar";

@Component({
  selector: "app-basiq-progress-bar",
  templateUrl: "./basiq-progress-bar.component.html",
})
export class BasiqProgressBarComponent implements OnInit {
  progressbar: IMainProgressBar;
  loadingPercentage: number;
  totalItems = 0;
  private destroy$ = new Subject<void>();

  constructor(protected basiqProgressBar: BasiqProgressBar, private blobbyService: BlobbyService) {
    this.progressbar = basiqProgressBar;
  }

  calculatePercentage(value: number, totalItems?: number): number {
    if (totalItems) {
      this.totalItems = totalItems;
    }
    return Math.round(this.totalItems === 0 ? 0 : (value / this.totalItems) * 100);
  }

  ngOnInit() {
    this.show();
  }

  show() {
    this.blobbyService.inProcessItem$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (inProcessItem) => {
        this.processItems(inProcessItem.type);
      },
    });
  }

  separateDataTypeWords(string: BlobbyDataTypeEnum): string {
    return string.replace(/([A-Z])/g, " $1").trim();
  }

  processItems(type: BlobbyDataTypeEnum) {
    const progressLabel = this.separateDataTypeWords(type);
    this.basiqProgressBar.setProgressLabel(progressLabel);
    this.blobbyService.startReferenceImport();
    this.blobbyService.loadingItemReference$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (value) => {
        this.totalItems = this.blobbyService.totalItemsToProcess;
        const loadingPercent = this.calculatePercentage(value);
        this.basiqProgressBar.setLoadingPercentage(loadingPercent);
        if (this.totalItems === 0) {
          this.blobbyService.completeReferenceProgress();
          this.basiqProgressBar.reset();
          this.basiqProgressBar.setIsLoadingCompleted(true);
        }
      },
    });
  }

  getBackgroundStyle() {
    const percentage = this.basiqProgressBar.getLoadingPercentage();
    return {
      background: `conic-gradient(#00C4C4 ${percentage * 3.6}deg, #F5F5F5 ${percentage}deg)`,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
