import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { TwoFactorProviderType } from "@bitwarden/common/enums/twoFactorProviderType";
import { VerificationType } from "@bitwarden/common/enums/verificationType";
import { SecretVerificationRequest } from "@bitwarden/common/models/request/secret-verification.request";
import { AuthResponse } from "@bitwarden/common/types/authResponse";
import { TwoFactorResponse } from "@bitwarden/common/types/twoFactorResponse";
import { Verification } from "@bitwarden/common/types/verification";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";

@Component({
  selector: "app-two-factor-verify",
  templateUrl: "two-factor-verify.component.html",
})
export class TwoFactorVerifyComponent {
  @Input() type: TwoFactorProviderType;
  @Input() organizationId: string;
  @Output() onAuthed = new EventEmitter<AuthResponse<TwoFactorResponse>>();

  secret: Verification;
  formPromise: Promise<TwoFactorResponse>;
  continueButtonOptions: ActionButton;
  cancelButtonOptions: ActionButton;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private userVerificationService: UserVerificationService,
    private i18nService: I18nService
  ) {
    this.continueButtonOptions = new ActionButton({
      text: this.i18nService.t("continue"),
      type: "submit",
      onClick: this.submit.bind(this),
    });

    this.cancelButtonOptions = new ActionButton({
      class: "neutral",
      text: this.i18nService.t("cancel"),
    });
  }

  async submit() {
    let hashedSecret: string;

    try {
      this.formPromise = this.userVerificationService.buildRequest(this.secret).then((request) => {
        hashedSecret =
          this.secret.type === VerificationType.MasterPassword
            ? request.masterPasswordHash
            : request.otp;
        return this.apiCall(request);
      });

      const response = await this.formPromise;
      this.onAuthed.emit({
        response: response,
        secret: hashedSecret,
        verificationType: this.secret.type,
      });
    } catch (e) {
      // this.continueButtonOptions.enableButton(true);
      //@Marnie: fix this dodgy declaration as OnPush change detection will not detect this change for Gloss-Button
      this.continueButtonOptions = new ActionButton({
        text: this.i18nService.t("continue"),
        type: "submit",
        onClick: this.submit.bind(this),
        isEnabled: true,
      });
      this.logService.error(e);
    }
  }

  private apiCall(request: SecretVerificationRequest): Promise<TwoFactorResponse> {
    switch (this.type) {
      case -1 as TwoFactorProviderType:
        return this.apiService.getTwoFactorRecover(request);
      case TwoFactorProviderType.Duo:
      case TwoFactorProviderType.OrganizationDuo:
        if (this.organizationId != null) {
          return this.apiService.getTwoFactorOrganizationDuo(this.organizationId, request);
        } else {
          return this.apiService.getTwoFactorDuo(request);
        }
      case TwoFactorProviderType.Email:
        return this.apiService.getTwoFactorEmail(request);
      case TwoFactorProviderType.WebAuthn:
        return this.apiService.getTwoFactorWebAuthn(request);
      case TwoFactorProviderType.Authenticator:
        return this.apiService.getTwoFactorAuthenticator(request);
      case TwoFactorProviderType.Yubikey:
        return this.apiService.getTwoFactorYubiKey(request);
    }
  }
}
