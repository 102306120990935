<div
  class="tw-flex tw-h-auto tw-w-full tw-justify-center tw-bg-neutral-50 tw-pt-6 md:tw-mb-[88px] md:tw-pt-0"
>
  <div
    class="tw-flex tw-h-auto tw-w-[80%] tw-flex-col tw-gap-6 tw-rounded-lg md:tw-mt-6 md:tw-h-full md:tw-w-[90%]"
  >
    <!--    mobile settings title-->
    <div class="tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <h1 class="tw-mb-0 tw-text-lg">{{ "settings" | i18n }}</h1>
    </div>
    <!--    Personal-->
    <div class="" *appRoleScope="RoleScope.GLOSS">
      <div class="tw-flex tw-flex-col tw-gap-1">
        <!--        title-->
        <div
          class="tw-h-auto tw-w-full tw-items-center tw-rounded-t-lg tw-bg-white tw-p-6 md:tw-p-4"
        >
          <span class="tw-mb-0 tw-text-3xl tw-font-medium md:tw-text-lg">{{
            "personal" | i18n
          }}</span>
        </div>

        <div class="tw-flex tw-h-auto tw-flex-col tw-gap-1">
          <!--profile-->
          <nav-panel
            [title]="'profile' | i18n"
            [routerLink]="getSettingsRoute(MainSettingsRoute.Profile)"
          >
            <div>{{ "name" | i18n }}</div>
            <div>{{ "email" | i18n }}</div>
          </nav-panel>

          <!--security-->
          <nav-panel
            [title]="'security' | i18n"
            [routerLink]="getSettingsRoute(MainSettingsRoute.Security)"
          >
            <div>{{ "changePassword" | i18n }}</div>
            <div class="tw-whitespace-nowrap">{{ "masterPassHintLabel" | i18n }}</div>
            <div class="tw-whitespace-nowrap">{{ "loginDevices" | i18n }}</div>
          </nav-panel>

          <!--advanced features-->
          <nav-panel
            [title]="'advancedFeatures' | i18n"
            [routerLink]="getSettingsRoute(MainSettingsRoute.AdvancedFeatures)"
          >
            <div class="tw-text-danger">
              <div class="tw-whitespace-nowrap">{{ "deauthorizeSessions" | i18n }}</div>
              <div class="tw-whitespace-nowrap" *appRoleScope="RoleScope.BETA_ACCESS">
                {{ "removeBasiqConsent" | i18n }}
              </div>
              <div class="tw-whitespace-nowrap" *appRoleScope="RoleScope.BETA_ACCESS">
                {{ "purgeVault" | i18n }}
              </div>
              <div class="tw-whitespace-nowrap">{{ "deleteAccount" | i18n }}</div>
            </div>
          </nav-panel>
        </div>
      </div>
    </div>

    <!--    Preferences-->
    <div
      [routerLink]="getSettingsRoute(MainSettingsRoute.Preferences)"
      class="tw-flex tw-h-auto tw-w-full md:tw-cursor-pointer"
    >
      <div
        class="tw-flex tw-w-full tw-flex-col tw-gap-1 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-rounded-lg md:tw-bg-white md:tw-pr-4"
      >
        <div class="tw-w-full tw-rounded-t-lg tw-bg-white tw-p-6 md:tw-rounded-lg md:tw-p-4">
          <span class="tw-mb-0 tw-text-3xl tw-font-medium md:tw-text-lg">{{
            "preferences" | i18n
          }}</span>
        </div>

        <div
          [routerLink]="getSettingsRoute(MainSettingsRoute.Preferences)"
          class="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-b-lg tw-bg-white tw-p-6 md:tw-hidden md:tw-p-4"
        >
          <div class="tw-flex tw-items-center">
            <div class="tw-w-[180px] tw-font-medium">
              Gloss <br />
              Preferences
            </div>

            <div class="tw-flex tw-flex-col tw-gap-1">
              <div class="tw-whitespace-nowrap">{{ "baseCurrency" | i18n }}</div>
              <div class="tw-whitespace-nowrap">{{ "dateFormats" | i18n }}</div>
            </div>
          </div>
          <div>
            <svg class="tw-h-7 tw-w-7">
              <use xlink:href="images/sprite.svg#right-arrow" />
            </svg>
          </div>
        </div>

        <div class="tw-hidden md:tw-flex">
          <svg class="tw-h-7 tw-w-7">
            <use xlink:href="images/sprite.svg#right-arrow" />
          </svg>
        </div>
      </div>
    </div>

    <!--Access-->
    <div [routerLink]="getSettingsRoute(MainSettingsRoute.Access)" class="tw-h-auto tw-w-full">
      <div
        class="tw-flex tw-flex-col tw-gap-1 md:tw-h-auto md:tw-flex-row md:tw-items-center md:tw-gap-0 md:tw-rounded-lg md:tw-bg-white"
      >
        <div
          class="tw-w-full tw-rounded-t-lg tw-bg-white tw-p-6 md:tw-w-auto md:tw-rounded-lg md:tw-p-4"
        >
          <span class="tw-text-3xl tw-font-medium md:tw-mb-0 md:tw-text-lg">{{
            "access" | i18n
          }}</span>
        </div>

        <div
          [routerLink]="getSettingsRoute(MainSettingsRoute.Access)"
          class="tw-flex tw-cursor-pointer tw-justify-between tw-rounded-b-lg tw-bg-white tw-p-6 md:tw-w-full md:tw-rounded-lg md:tw-p-4"
        >
          <div
            class="tw-flex tw-rounded-full tw-bg-neutral-50 tw-px-4 tw-py-2 tw-font-medium tw-text-primary"
          >
            <div *ngIf="!isPremiumUser">{{ "accessStatus1" | i18n }}</div>
            <div *ngIf="isPremiumUser">{{ "accessStatus2" | i18n }}</div>
          </div>

          <div class="tw-self-center tw-justify-self-center">
            <svg class="tw-h-7 tw-w-7">
              <use xlink:href="images/sprite.svg#right-arrow" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!--Privacy Protection-->
    <div
      class="tw-mb-2 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-p-6 md:tw-p-4"
    >
      <div class="tw-flex tw-flex-col tw-gap-6 md:tw-gap-4">
        <span class="tw-whitespace-nowrap tw-text-3xl tw-font-medium md:tw-text-lg">{{
          "privacyProtection" | i18n
        }}</span>
        <div class="tw-flex tw-gap-6 xs:tw-justify-around md:tw-gap-4">
          <app-gloss-button
            [options]="{
              text: 'lockNow' | i18n,
              class: 'neutral',
              isEnabled: true,
              onClick: lock.bind(this),
              icon: 'lock'
            }"
          ></app-gloss-button>

          <app-gloss-button
            [options]="{
              text: 'logOut' | i18n,
              class: 'neutral',
              isEnabled: true,
              onClick: logOut.bind(this),
              icon: 'logOut'
            }"
          ></app-gloss-button>
        </div>
      </div>
    </div>
  </div>
</div>
