import { AccountGroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/accountGroupingNode";
import { AllocationGroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/allocationGroupingNode";
import { GroupingNode } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/groupingNode";

export type BalanceByAccounts = Record<string, Record<string, number>>;

export const GroupingNodeTypeClasses = {
  root: GroupingNode,
  account: AccountGroupingNode,
  allocation: AllocationGroupingNode,
};

export type GroupingNodeTypeProperties = typeof GroupingNodeTypeClasses;
export type GroupingNodeType = keyof GroupingNodeTypeProperties;

export type ChildNodeGroupings = Record<GroupingNodeType, ChildNodes>;
export type ChildNodes = Record<string, GroupingNode>;

export type GranularityType = {
  day: GroupingKeyType;
  week: GroupingKeyType;
  month: GroupingKeyType;
  quarter: GroupingKeyType;
  year: GroupingKeyType;
  //  if adding new granularity option make sure to update granularityProperties and GranularityFormat
};

export type GroupingKeyType = Record<string, GroupingNode>;

export type GranularityProperty = keyof GranularityType;
export const dayGranularity = "day" as GranularityProperty;
export const weekGranularity = "week" as GranularityProperty;
export const monthGranularity = "month" as GranularityProperty;
export const quarterGranularity = "quarter" as GranularityProperty;
export const yearGranularity = "year" as GranularityProperty;

export const granularityProperties: Array<GranularityProperty> = [
  dayGranularity,
  weekGranularity,
  monthGranularity,
  quarterGranularity,
  yearGranularity,
];

export type BalanceGroupingAttributes = {
  dateStart: Date;
  dateEnd: Date; // holds the date of the last transaction
  lastRunningDateEnd: Date; // hold the date of that the summing and filling granularity was last run to
  weekStartDay: number;
  monthStartDay: number;
};

export type GranularityDefault = {
  limit: number;
  limitType: GranularityProperty;
  default: GranularityProperty;
};

export const GranularityDefaults: Array<GranularityDefault> = [
  { limit: 24, limitType: dayGranularity, default: dayGranularity },
  { limit: 168, limitType: dayGranularity, default: weekGranularity },
  { limit: 2, limitType: yearGranularity, default: monthGranularity },
  { limit: 6, limitType: yearGranularity, default: quarterGranularity },
  { limit: null, limitType: null, default: yearGranularity },
];

export type GranularityOption = {
  limit: number;
  limitType: GranularityProperty;
  options: Array<GranularityProperty>;
};

export const GranularityOptions: Array<GranularityOption> = [
  { limit: 21, limitType: dayGranularity, options: [dayGranularity] },
  { limit: 24, limitType: dayGranularity, options: [dayGranularity, weekGranularity] },
  { limit: 3, limitType: monthGranularity, options: [weekGranularity] },
  {
    limit: 168,
    limitType: dayGranularity,
    options: [weekGranularity, monthGranularity, quarterGranularity],
  },
  { limit: 2, limitType: yearGranularity, options: [monthGranularity, quarterGranularity] },
  { limit: 6, limitType: yearGranularity, options: [quarterGranularity, yearGranularity] },
  { limit: null, limitType: null, options: [yearGranularity] },
];

// TODO: Get Sinan to fix this to be a proper type instead of any!
// Moved from GranularityAndGroupingTypes after it was deprecated
export type DetailedBalance = any;
