import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

export class EstimateActionWorkerMessage implements WorkerMessage {
  id: string;
  workerType = "EstimateActionWorker";
  data?: any;
  constructor(id: string, data?: any) {
    this.id = id;
    this.data = data;
  }
}
