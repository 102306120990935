import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { devFlagEnabled } from "@bitwarden/common/misc/flags";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { ReferenceAddEditComponent } from "@bitwarden/web-vault/app/gloss/import/reference-management/reference-add-edit/reference-add-edit.component";
import { manageButtonProperties } from "@bitwarden/web-vault/app/gloss/manage/manage.components";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";

@Component({
  selector: "app-reference-management",
  templateUrl: "./reference-management.component.html",
})
export class ReferenceManagementComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private unsubscribe$ = new Subject<void>();
  loading = false;
  referenceData: Array<ReferenceData>;
  previewData: Array<ReferenceData>;
  showPreview = false;
  showRetrievingCurrenciesProgressBar = false;
  showSavingCurrenciesProgressBar = false;
  showImportProgress = false;
  showTransactionUpdateProgress = false;
  retrievingCurrenciesProgressValue = 0;
  savingCurrenciesProgressValue = 0;
  selectedTabIndex = 0;
  dialogueRef: MatDialogRef<ReferenceAddEditComponent>;
  existingReferenceData: ReferenceData[];

  protected readonly devFlagEnabled = devFlagEnabled;

  constructor(
    private referenceDataService: ReferenceDataService,
    protected i18nService: I18nService,
    protected platformUtilsService: PlatformUtilsService,
    private transactionService: TransactionService,
    private transactionNormalizeService: TransactionNormalizeService,
    private dataRepositoryService: DataRepositoryService,
    private marketDataService: MarketDataService,
    private blobbyService: BlobbyService,
    public dialog: MatDialog,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.loading = false;
    this.referenceData = await this.referenceDataService.getAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePreview(actionData: { createTab: boolean; data?: any }) {
    if (actionData.createTab) {
      this.showPreview = true;
      this.selectedTabIndex = 0;
      this.previewData = actionData.data;
    }
  }

  addReferenceDataButton = new ActionButton({
    ...manageButtonProperties,
    text: this.i18nService.t("addReferenceData"),
    onClick: this.addReferenceData.bind(this),
  });

  onCancelPreview() {
    this.showPreview = false;
  }
  private listenToReferenceData() {
    this.showImportProgress = true;
    this.blobbyService.loadingItemReference$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (batchNum) => {
        this.savingCurrenciesProgressValue =
          this.blobbyService.totalItemsToProcess === 0
            ? 0
            : (batchNum / this.blobbyService.totalItemsToProcess) * 100;

        if (this.savingCurrenciesProgressValue >= 100) {
          this.blobbyService.completeReferenceProgress();
        }
      },
      complete: async () => {
        this.showImportProgress = false;
        this.showTransactionUpdateProgress = true;
        let transactions: Transaction[];
        /** Todo : now if we are updating currency it re-normalize every transaction ... it should only re-normalize affected one */
        if (this.previewData && this.previewData[this.previewData.length - 1]) {
          /** Todo create a method to make sure that it grabs the start date accurately. as the list's last or first element might not be the start date*/
          const startRefDateTime = new Date(
            this.previewData[this.previewData.length - 1].date.date
          );
          /*TODO update only affected transactions, meaning retrieve only affected transactions from blobby */
          transactions = await this.transactionService.getTransactionsEffectedByReferenceData(
            startRefDateTime.getTime()
          );
        } else {
          transactions = await this.transactionService.getAll();
        }
        this.showTransactionUpdateProgress = true;
        for (const transaction of transactions) {
          await this.transactionNormalizeService.normalizeImportedTransaction(transaction);
          await this.dataRepositoryService.updateTransaction(transaction);
        }

        this.showSavingCurrenciesProgressBar = false;
        this.savingCurrenciesProgressValue = 0;
        this.showTransactionUpdateProgress = false;
        this.platformUtilsService.showToast("success", null, this.i18nService.t("importSuccess"));
        this.showPreview = false;
        this.selectedTabIndex = 1;
      },
    });
  }

  private listenToApiCall() {
    this.marketDataService.totalPage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ page, totalPage }) => {
        this.showRetrievingCurrenciesProgressBar = true;

        /** TODO pagination doesn't work so commenting this for now */
        // this.retrievingCurrenciesProgressValue =
        //   page === 0 || totalPage === 0 ? 0 : (page / totalPage) * 100;
        this.retrievingCurrenciesProgressValue = page;

        if (this.retrievingCurrenciesProgressValue >= 100) {
          this.listenToReferenceData();
          this.retrievingCurrenciesProgressValue = 100;
        }
      });
  }

  async importReferenceData(): Promise<void> {
    this.blobbyService.startReferenceImport();
    this.listenToReferenceData();
    this.loading = true;
    this.showSavingCurrenciesProgressBar = true;
    try {
      // do a deduplication
      await this.referenceDataService.import(this.previewData);
    } catch (e) {
      this.platformUtilsService.showToast("error", null, this.i18nService.t("importError"));
    } finally {
      this.showSavingCurrenciesProgressBar = false;
      this.platformUtilsService.showToast("success", null, this.i18nService.t("importSuccess"));
    }
  }

  async updateCurrencies() {
    this.marketDataService.startPullingData();
    this.listenToApiCall();
    await this.marketDataService.refreshCurrencyRates();

    this.showRetrievingCurrenciesProgressBar = false;
    this.platformUtilsService.showToast("success", null, this.i18nService.t("updatedSuccessfully"));
  }

  addReferenceData() {
    this.edit(null);
  }
  async edit(reference: ReferenceData): Promise<void> {
    const dialogRef = this.dialog.open(ReferenceAddEditComponent, {
      width: "800px", // for mat-dialog-container, built in max-width: 80vw; so this width works for smaller screens too
      data: {
        reference,
        actionSucceeded: this.actionSucceeded.bind(this),
        // delete: this.delete.bind(this, institution),
      },
      autoFocus: ".auto-focus",
      backdropClass: "custom-backdrop-class",
      disableClose: true,
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newInstitutions) => {
        if (newInstitutions) {
          this.existingReferenceData = newInstitutions;
        }
      });
  }

  async actionSucceeded(actionMessage: string) {
    this.existingReferenceData = await this.referenceDataService.getAll();
    this.dialogueRef?.close(this.existingReferenceData);
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }

  protected readonly HelperCommon = HelperCommon;
}
