import { Component, Input } from "@angular/core";

@Component({
  selector: "app-earnings-header",
  templateUrl: "./earnings-header.component.html",
})
export class EarningsHeaderComponent {
  @Input() header!: string;
  @Input() headerDescription!: string;
}
