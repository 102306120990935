import { Component, NgZone, Injector } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { CryptoService } from "@bitwarden/common/abstractions/crypto.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { TwoFactorWebAuthnResponse } from "@bitwarden/common/models/response/two-factor-web-authn.response";
import { AuthResponse } from "@bitwarden/common/types/authResponse";
import { StateService } from "@bitwarden/web-vault/app/core";

import { TwoFactorWebAuthnBaseComponent } from "./two-factor-webauthn-base.component";

@Component({
  selector: "app-two-factor-webauthn",
  templateUrl: "two-factor-webauthn.component.html",
})
export class TwoFactorWebAuthnComponent extends TwoFactorWebAuthnBaseComponent {
  private cryptoService: CryptoService;
  private stateService: StateService;

  constructor(injector: Injector, private dialogRef: MatDialogRef<TwoFactorWebAuthnComponent>) {
    super(
      injector.get(ApiService),
      injector.get(I18nService),
      injector.get(PlatformUtilsService),
      injector.get(NgZone),
      injector.get(LogService),
      injector.get(UserVerificationService)
    );
    this.cryptoService = injector.get(CryptoService);
    this.stateService = injector.get(StateService);
  }

  onReadKey(credentials: PublicKeyCredential): void {
    // rpidHash(32) + flags(1) + signCount(4) https://www.w3.org/TR/webauthn/?form=MG0AV3#sctn-authenticator-data
    // TODO: use the whole authenticator data instead
    // only use rpidHash of the authenticator data, windows hello does not like the whole authenticator data
    const length = 32;
    const data: Uint8Array = (credentials.response as any).getAuthenticatorData().slice(0, length);
    // data[32] = data[32] ^ 0x40; // clear the attested credential data flag
    this.cryptoService
      .setBiometricsKey(data)
      .then(() => {
        super.onReadKey(credentials);
      })
      .then(() => {
        return this.submit();
      })
      .then(() => {
        this.stateService.setBiometricFingerprintValidated(true);
      })
      .catch((e) => {
        this.logService.error(`Error setting biometrics key ${e}`);
      })
      .finally(() => {
        this.dialogRef.close();
      });
  }

  auth(authResponse: AuthResponse<TwoFactorWebAuthnResponse>) {
    super.auth(authResponse);
    this.name = crypto.randomUUID();
    if (this.authed) {
      this.readKey();
    }
    // todo handle errors
  }
}
