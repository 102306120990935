import { GlossRouteEnum } from "../../models/enum/routes.enum";

/**
 * Function to get the route name for navigation based on the provided route.
 *
 * @param {GlossRouteEnum} route - The route enum value.
 * @returns {string} - The route name for navigation.
 *
 * @example
 * // returns "primary-dashboard"
 * getRouteNameForNavigation(GlossRouteEnum.dashboard);
 *
 * @example
 * // returns "account"
 * getRouteNameForNavigation(GlossRouteEnum.account);
 */
// todo get rid of this redundancy
export const getRouteNameForNavigation = (route: GlossRouteEnum): string => {
  switch (route) {
    case GlossRouteEnum.dashboard:
      return "primary-dashboard";
    case GlossRouteEnum.account:
      return "account";
    case GlossRouteEnum.categories:
      return "categories";
    case GlossRouteEnum.classifications:
      return "classifications";
    case GlossRouteEnum.institutions:
      return "institutions";
    case GlossRouteEnum.import:
      return "import-management";
    case GlossRouteEnum.reference:
      return "reference-management";
    case GlossRouteEnum.estimates:
      return "estimates";
    case GlossRouteEnum.preferences:
      return "preferences";
    default:
      return "";
  }
};
