<form #form [appApiAction]="formPromise" ngNativeValidate>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-px-8 tw-py-8 xs:tw-px-2"
  >
    <logo-header [text]="'passwordHint' | i18n"></logo-header>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-flex tw-flex-col tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl xs:tw-flex-none"
    >
      <div class="autocomplete-container">
        <label for="email">{{ "emailAddress" | i18n }} *</label>
        <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
          <input
            id="email"
            type="text"
            name="Email"
            class="autocomplete-input"
            [(ngModel)]="email"
            #emailModel="ngModel"
            matInput
            required
            appAutofocus
            inputmode="email"
            appInputVerbatim="false"
          />
          <mat-error class="error-inline input-error" *ngIf="emailModel.errors?.required">
            {{ "inputRequired" | i18n }}
          </mat-error>
        </mat-form-field>
      </div>
      <small class="tw-mt-1.5">{{ "enterEmailToGetHint" | i18n }}</small>
      <hr />
      <div class="tw-flex tw-gap-2 xs:tw-flex-col">
        <app-gloss-button class="button-resize" [options]="submitButtonOptions"> </app-gloss-button>
        <app-gloss-button class="button-resize" [options]="cancelButtonOptions"> </app-gloss-button>
      </div>
    </div>
  </div>
</form>
