<div class="tw-rounded-xl tw-bg-white tw-p-4">
  <div class="tw-mb-4 tw-flex tw-items-center tw-justify-between">
    <div class="tw-flex">
      <icon
        [size]="6"
        [name]="iconName"
        class="tw-flex tw-w-10 tw-items-center tw-justify-center tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100"
      >
      </icon>
      <span class="tw-ml-4 tw-text-xl tw-font-medium">{{ title }}</span>
    </div>
    <div *ngIf="menu" class="tw-rounded-xl tw-bg-neutral-50 tw-p-1">
      <div class="tw-flex tw-cursor-pointer tw-items-center tw-gap-1">
        <ng-container *ngIf="menu.type === 'texts'">
          <div
            *ngFor="let option of menu.options"
            class="tw-p-1"
            [ngClass]="{ 'widget-menu-selected': option.isSelected }"
            (click)="onOptionClick(option.value)"
          >
            {{ option.displayValue }}
          </div>
        </ng-container>

        <ng-container *ngIf="menu.type === 'icons'">
          <icon
            *ngFor="let option of menu.options"
            class="tw-p-1"
            [ngClass]="{ 'widget-menu-selected': option.isSelected }"
            [size]="5"
            (click)="onOptionClick(option.value)"
            [name]="option.displayValue"
          >
          </icon>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
