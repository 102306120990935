import { Injectable } from "@angular/core";
import { MenuItemDef } from "ag-grid-enterprise";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { getIcon } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/context-menu";
import { ICabinetContextMenu } from "@bitwarden/web-vault/app/services/cabinet/context-menu.interface";

@Injectable({
  providedIn: "root",
}) // todo consolidate wth context-menu.ts
export class CabinetContextMenuService {
  constructor(private i18nService: I18nService) {}

  /**
   * Helper function to create a menu item.
   * @param name - Name of the menu item.
   * @param icon - Icon for the menu item.
   * @param action - Action to be performed on menu item click.
   * @param disabled - Whether the menu item is disabled.
   * @returns The created menu item.
   */
  private createMenuItem(
    name: string,
    icon: string,
    action?: () => void,
    disabled = false
  ): MenuItemDef {
    return {
      name: this.i18nService.t(name),
      icon: getIcon(icon),
      action,
      cssClasses: ["custom-menu-item-container"],
      disabled,
    };
  }

  /**
   * Generates context menu items, aka right-click buttons, for the cabinet.
   * @param contextMenuParams - Parameters for generating context menu items.
   * @returns Array of context menu items.
   */
  getContextMenuItems(contextMenuParams: ICabinetContextMenu): (string | MenuItemDef)[] {
    const { params, downloadFile, editFile, deleteFile } = contextMenuParams;
    const vaultFileView = params.node.data;
    return [
      this.createMenuItem("download", "download", () => downloadFile(vaultFileView)),
      this.createMenuItem("view", "view", undefined, true),
      this.createMenuItem("edit", "edit", () => editFile(vaultFileView), true),
      this.createMenuItem("delete", "trash", () => deleteFile(vaultFileView)),
    ];
  }
}
