<app-tooltip-card [backgroundClass]="backgroundClass" [contentTemplate]="bestScenarioInfo">
  <div
    main-icon
    class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-teal-100 xs:tw-h-7 xs:tw-w-7"
  >
    <svg class="tw-h-7 tw-w-7 tw-fill-teal-500 xs:tw-h-5 xs:tw-w-5">
      <use [attr.xlink:href]="'images/sprite.svg#bank'"></use>
    </svg>
  </div>
  <app-best-scenario-dialog-title title>
    <ng-content></ng-content>
  </app-best-scenario-dialog-title>
</app-tooltip-card>

<ng-template #bestScenarioInfo>
  <app-best-interest-account [date]="lastViewDate"></app-best-interest-account>
</ng-template>
