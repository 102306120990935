import { Component, EventEmitter, Output, Input, ChangeDetectorRef } from "@angular/core";

import "./currencies.component.style.scss";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { UserLocationCurrencyEnum } from "@bitwarden/web-vault/app/models/enum/user-location.enum";
import { SystemCurrenciesString } from "@bitwarden/web-vault/app/models/types/system-currencies.type";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";

/**
 * CountriesComponent is a component that provides a form field for selecting countries.
 * It uses Angular Material's autocomplete feature to suggest countries as the user types.
 * The selected country is emitted as events.
 * TODO: Add a feature to select multiple countries.
 * TODO: Add a feature of custom styling.
 */
@Component({
  selector: "app-currencies",
  templateUrl: "./currencies.component.html",
  styles: ["currencies.component.style.scss"],
})
export class CurrenciesComponent {
  allCurrencies: string[] = SystemCurrenciesString;
  filteredCurrencies: string[] = SystemCurrenciesString;
  currency: string;
  @Input() label: string;
  @Input() accountCurrency: string;
  @Input() disabled = false;

  /**
   * Event that is emitted when a currency is selected.
   */
  @Output() selectedCurrency: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event that is emitted when multiple currencies are selected.
   */
  @Output() selectedCurrencies: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private preferenceService: PreferenceService, private cdr: ChangeDetectorRef) {}

  async ngOnInit() {
    await this.setCurrency();
  }

  async ngOnChanges() {
    await this.checkAccountCurrency();
  }

  private async checkAccountCurrency() {
    if (this.accountCurrency) {
      await this.setCurrency();
      this.cdr.detectChanges();
    }
  }

  private async setCurrency() {
    if (!this.accountCurrency) {
      const locationKey = PreferenceType.userLocation;
      const userLocation = (await this.preferenceService.get(
        locationKey
      )) as keyof typeof UserLocationCurrencyEnum;
      this.currency = UserLocationCurrencyEnum[userLocation];
    } else {
      this.currency = this.accountCurrency;
    }

    this.filterCurrenciesInWizard();
  }

  /** use the currency of the region only, in wizard */
  filterCurrenciesInWizard() {
    const isInWizard = !!localStorage.getItem("inWizard");
    if (isInWizard) {
      this.filteredCurrencies = this.allCurrencies.filter((currency) => currency === this.currency);
      this.currency = this.accountCurrency;
    }
  }

  /**
   * Emits the selectedCountry event with the provided country.
   * @param {string} currency - The country that was selected.
   */
  currencySelected(currency: string) {
    if (this.selectedCurrency) {
      this.selectedCurrency.emit(currency);
    }
  }
  /**
   * Clears the selection of the country.
   * This method is used to emit a null value to the `selectedCountry` event emitter,
   * indicating that the country selection has been cleared.
   */
  selectionCleared() {
    this.selectedCurrency.emit(null);
  }
}
