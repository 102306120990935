import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";

import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { BaseStepper } from "@bitwarden/web-vault/app/components/stepper/base-stepper";
import { StepperActionsEnum } from "@bitwarden/web-vault/app/models/enum/stepper.enum";
import {
  Step,
  StepActionData,
  StepperAction,
} from "@bitwarden/web-vault/app/models/types/stepper.types";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
})
export class StepperComponent extends BaseStepper implements OnInit, OnDestroy {
  @Input() steps: Step[] = [];

  @Output() onMove = new EventEmitter<StepActionData>();

  @ViewChild("stepContainer", { read: ViewContainerRef, static: true })
  stepContainer: ViewContainerRef;

  nextBtnOptions: ActionButton;

  ngOnInit() {
    this.loadStepInstance();
  }

  loadStepInstance() {
    const currentStep = this.currentStep();
    this.stepContainer.clear();
    const componentRef = this.stepContainer.createComponent(currentStep.component.type);

    if (!currentStep.component.instance) {
      currentStep.component.instance = componentRef.instance;
      currentStep.component.instance.onAction.subscribe((action: StepperAction) => {
        this.onMove?.emit({ action, step: currentStep });
      });
    }

    if (currentStep.component.instance.generateNextBtn) {
      this.nextBtnOptions = currentStep.component.instance.generateNextBtn(this.next.bind(this));
    }
  }

  next() {
    if (this.isLastStep()) {
      this.onMove?.emit({ action: StepperActionsEnum.completeSteps, step: this.currentStep() });
      return;
    }

    super.next();
    this.loadStepInstance();
  }

  ngOnDestroy() {
    this.stepContainer.clear();
  }
}
