import { GlossRouteEnum } from "../models/enum/routes.enum";
import { DashboardHelp, GlossRouteInfo } from "../models/types/navigation.types";

const dashboardHelpText: DashboardHelp = {
  overview: [
    {
      type: "transactionOnly",
      title: "Summary View",
      text:
        "The Summary View condenses all of your accounts into a single page " +
        "for your convenience.<br />" +
        "The top sections shows how your total balance has changed over time.<br />" +
        "The bottom section lets you see every transaction in the defined time periods, " +
        "so you can see where that balance comes from.",
    },
    {
      type: "scenarioOnly",
      title: "Forecast View",
      text:
        "The Forecast View shows you the possibilities of the future. It reflects the " +
        "potential outcomes that different choices may lead to, listed out as diffferent " +
        "paths. <br/>" +
        "It is arranged similarly to the Summary View, with total balance over time " +
        "on the top, and transactions at the bottom. The main difference is that the " +
        "Forecast View looks forward in time, whilst the Summary View represents the past.",
    },
    {
      type: "transactionAndScenario",
      title: "Combined View",
      text:
        "The Combined View lets you see the past and potential futures in the same place, " +
        "giving you elements found in both the Summary and Scenario Views.",
    },
  ],
  scenarioHelp: [
    "<div class='help-heading'>Path 1: Current Account Growth</div>" +
      "<div>Estimates growth in your current accounts, indicating the potential impact of existing interest rates over time." +
      "</div>",
    "<div class='help-heading'>Path 2: Best Existing Account</div>" +
      "<div>Compares the interest rates of your current accounts, indicating the potential growth if all funds are moved " +
      "to the highest-yield account." +
      "</div>",
    "<div class='help-heading'>Path 3: Top Regional Account</div>" +
      "<div>Estimates potential growth if all funds are moved to the highest interest rate account available in your " +
      "geographical region, indicating the optimal impact on your savings." +
      "</div>",
  ],
};

const accountHelpText =
  "<p class='section-info-text'>" +
  "<span class='help-heading'></span><br />" +
  "<span class='stand-out-color paragraph-start'>An account</span> is like a type of file folder where a group of transactions take place.\n" +
  "Commonly, this might be a bank savings account, credit card account, an account with other assets like stocks, or even something you keep track of yourself, like what's in your wallet." +
  "</p>";

const institutionsHelpText =
  "<p class='section-info-text'>" +
  "<span class='help-heading'></span><br />" +
  "<span class='stand-out-color paragraph-start'>An institution</span>, as defined in the GLOSS Vault, is a company or entity you interact with when making transactions and/or holding assets you value.\n" +
  "</br></br>" +
  "<span class='paragraph-start'>Commonly</span>, these interactions are monetary, such as deposits, loans, investments, and currency exchange, but can encompass much more.\n" +
  "</br></br>" +
  "<span class='paragraph-start'>Examples</span> include (but are not limited to) banks, credit unions, credit/loan providers, insurance companies, brokerage firms, investment dealers, casinos, reward/club card providers, cryptocurrency issuers/decentralized organizations, and stored value/credit systems." +
  "</p>";

const categoriesHelpText =
  "<p class='section-info-text'>" +
  "<span class='help-heading'></span><br />" +
  "<span class='stand-out-color paragraph-start'>Categories</span>,  enable you to group your transactions. It's useful for identifying money inflow and outflow patterns over time, and identifying the split between one group of transactions against another within a certain time period." +
  "</br></br>" +
  "<span class='paragraph-start'>Examples</span>  may include (but are not limited to) Food, Entertainment, Transportation, Healthcare, Insurance, Utilities, Salary, or Investment Property Income." +
  "</p>";

const classificationsHelpText =
  "<p class='section-info-text'>" +
  "<span class='help-heading'></span><br />" +
  "<span class='stand-out-color paragraph-start'>Classifications</span>, enable you to allocate transactions to create sub-accounts, or to group accounts together, with the purpose of identifying combined balances." +
  "</br></br>" +
  "<span class='paragraph-start'>Examples</span> may include (but are not limited to) an Emergency Fund, Kid's College Fund, Family Expenses, Stock Portfolio, or Investment Property." +
  "</p>";

export const glossRouteInfo: GlossRouteInfo = {
  [GlossRouteEnum.dashboard]: dashboardHelpText,
  [GlossRouteEnum.cabinet]: null,
  [GlossRouteEnum.account]: accountHelpText,
  [GlossRouteEnum.categories]: categoriesHelpText,
  [GlossRouteEnum.classifications]: classificationsHelpText,
  [GlossRouteEnum.institutions]: institutionsHelpText,
  [GlossRouteEnum.import]: null,
  [GlossRouteEnum.reference]: null,
  [GlossRouteEnum.estimates]: null,
  [GlossRouteEnum.preferences]: null,
  [GlossRouteEnum.subscription]: null,
  [GlossRouteEnum.sync]: null,
};
