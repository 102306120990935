<div
  class="tw-inset-0 tw-flex tw-overflow-visible"
  role="dialog"
  aria-modal="true"
  aria-labelledby="removeBasiqConsentTitle"
>
  <div class="tw-relative tw-mx-auto tw-w-full tw-rounded-lg tw-bg-white tw-p-6" role="document">
    <form class="tw-flex tw-flex-col tw-gap-6">
      <div class="tw-flex md:tw-justify-between">
        <h1 class="tw-text-xl" id="removeBasiqConsentTitle">{{ "removeBasiqConsent" | i18n }}</h1>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-mb-0 tw-text-justify">{{ "removeBasiqConsentDesc" | i18n }}</p>
        <app-gloss-callout title="warning" body="removeBasiqConsentWarning"></app-gloss-callout>
        <app-gloss-callout
          *ngIf="!keepDataIn"
          title="warning"
          body="deleteBasiqDataWarning"
        ></app-gloss-callout>
        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="tw-w-full" id="keep-basiq-data-cb">
        <app-checkbox
          [checked]="true"
          [background]="'danger'"
          [title]="'keepExistingDataFromBasiq' | i18n"
          (onChange)="setKeepDataIn($event)"
        ></app-checkbox>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6 md:tw-flex-col md:tw-gap-4">
        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'removeBasiqConsent' | i18n,
            type: 'submit',
            class: 'danger-invert',
            onClick: submit.bind(this),
            isEnabled: !deleting
          }"
        ></app-gloss-button>

        <app-gloss-button
          type="button"
          class="tw-w-full"
          [options]="{
            text: 'close' | i18n,
            class: 'neutral',
            isEnabled: true,
            onClick: closeForm.bind(this)
          }"
        ></app-gloss-button>
      </div>
    </form>
  </div>
</div>
