import { Component, Input } from "@angular/core";

@Component({
  selector: "accordion-component",
  templateUrl: "./accordion.component.html",
})
export class AccordionComponent {
  protected _id: string;
  protected isCollapsed = true;

  @Input() isClickable: boolean;
  @Input() expandIcon: string;
  @Input() collapseIcon: string;
  @Input() customClass?: string;
  @Input() contentTemplate!: any;
  @Input() set id(value: string) {
    this._id = "#" + value;
  }

  onAccordionClicked() {
    if (this.isClickable) {
      this.toggleContent();
    }
  }

  toggleContent(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
