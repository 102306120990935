import { Injector } from "@angular/core";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { SystemCurrenciesString } from "@bitwarden/web-vault/app/models/types/system-currencies.type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";

export type GlossErrorType = {
  message?: string;
  messageKey: string;
  type: "warning" | "fatal" | "info";
};

export class GlossError {
  private _message: string;
  private _messageKey: string;
  private _type: "warning" | "fatal" | "info" = "warning";

  constructor(private globalService: GlobalService, error?: GlossErrorType) {
    if (error) {
      this.message = error.message;
      this.messageKey = error.messageKey;
      this.type = error.type;
    }
  }

  get message() {
    return this._message;
  }

  set message(message: string) {
    this._message = message;
  }

  get messageKey() {
    return this._messageKey;
  }

  set messageKey(messageKey: string) {
    this._messageKey = messageKey;
  }

  get type() {
    return this._type;
  }

  set type(type: "warning" | "fatal" | "info") {
    this._type = type;
  }

  displayError() {
    if (this.type === "warning") {
      this.displayWarningError();
    }

    if (this.type === "fatal") {
      this.displayFatalError();
    }

    if (this.type === "info") {
      this.displayInfoError();
    }
  }

  displayWarningError() {
    this.globalService.showErrorMessage("errorOccurred", this.messageKey);
  }

  displayFatalError() {
    //todo show warning error like confirmation dialog
  }

  displayInfoError() {
    //todo show warning error like confirmation dialog
  }
}
export type BalanceActionType = keyof typeof BalanceActionEnum;
export enum BalanceActionEnum {
  add = "add",
  edit = "edit",
  require_user = "require_user",
  keep_existing = "keep_existing",
}
export class BookBalance {
  readonly accountView: AccountView;
  readonly balanceForm: BalanceForm;
  private alignmentOfDate: Transaction;
  private newBalance: GlossNumber;
  private newDate: GlossDate;
  private confirmationDialogService: ConfirmationDialogService;
  private transactionService: TransactionService;
  private globalService: GlobalService;
  private marketDataService: MarketDataService;
  action: BalanceActionType = null;
  hasABalanceForDate: boolean;
  isSameBalanceSameDate: boolean;
  private transactionStatus: TransactionStatusEnum;
  error: GlossError;

  constructor(accountView: AccountView, balanceForm: BalanceForm, private injector: Injector) {
    this.accountView = accountView;
    this.balanceForm = balanceForm;

    this.transactionService = this.injector.get(TransactionService);
    this.globalService = this.injector.get(GlobalService);
    this.marketDataService = this.injector.get(MarketDataService);
    this.confirmationDialogService = this.injector.get(ConfirmationDialogService);
    this.transactionStatus = this.balanceForm.transactionStatus;
  }

  async runPreProcess() {
    const isValidForm = this.isValidForm();

    if (isValidForm) {
      this.newBalance = new GlossNumber().setToGlossNumberObj({
        amount: Number(this.balanceForm.balance),
      });
      this.newDate = new GlossDate().setToDateObj(this.balanceForm.date);
      this.hasABalanceForDate = await this.hasBalanceForDate();
      this.isSameBalanceSameDate = this.isBalanceSameForDate();
      await this.setAutoAction();
    }
  }

  private isValidForm() {
    return this.isBalance() && this.isCurrency() && this.isDate() && this.isTransactionStatus();
  }

  private async setAutoAction() {
    if (!this.hasABalanceForDate) {
      this.action = BalanceActionEnum.add;
      return;
    }

    this.action = BalanceActionEnum.require_user;
  }

  private async setFinalAction() {
    if (this.action === BalanceActionEnum.require_user) {
      if (this.hasABalanceForDate) {
        if (!this.isSameBalanceSameDate) {
          const isEdit = await this.confirmUpdateBalance();
          if (isEdit) {
            this.action = BalanceActionEnum.edit;
          }
        } else {
          const isKeep = await this.confirmToKeepBalance();
          if (isKeep) {
            this.action = BalanceActionEnum.keep_existing;
          }
        }
      }
    }
  }

  isValidBalanceForm() {
    return !this.error;
  }

  isBalance() {
    const isEmpty =
      !this.balanceForm.balance || this.balanceForm.balance.replace(/\s+/g, "") === "";
    if (isEmpty) {
      this.error = new GlossError(this.globalService, {
        messageKey: "emptyBalance",
        type: "warning",
      });
      return false;
    }

    if (isNaN(Number(this.balanceForm.balance))) {
      this.error = new GlossError(this.globalService, {
        messageKey: "invalidBalance",
        type: "warning",
      });
      return false;
    }

    return true;
  }

  private isCurrency() {
    const isEmpty =
      !this.balanceForm.currency || this.balanceForm.currency.replace(/\s+/g, "") === "";
    if (isEmpty) {
      this.error = new GlossError(this.globalService, {
        messageKey: "emptyCurrency",
        type: "warning",
      });
      return false;
    }

    const isNotSupported = !SystemCurrenciesString.includes(this.balanceForm.currency);
    if (isNotSupported) {
      this.error = new GlossError(this.globalService, {
        messageKey: "invalidCurrency",
        type: "warning",
      });
      return false;
    }

    return true;
  }

  private isDate() {
    const isEmpty = this.balanceForm.date == "";
    if (isEmpty) {
      this.error = new GlossError(this.globalService, { messageKey: "emptyDate", type: "warning" });
      return false;
    }

    const isInvalid = isNaN(new Date(this.balanceForm.date).getTime());
    if (isInvalid) {
      this.error = new GlossError(this.globalService, {
        messageKey: "invalidDate",
        type: "warning",
      });
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const balanceDate = new Date(this.balanceForm.date);
    balanceDate.setHours(0, 0, 0, 0);
    const isFuture = balanceDate > today;
    if (isFuture) {
      this.error = new GlossError(this.globalService, {
        messageKey: "isFutureDate",
        type: "warning",
      });
      return false;
    }

    return true;
  }

  private isTransactionStatus() {
    const isEmpty =
      !this.balanceForm.transactionStatus ||
      this.balanceForm.transactionStatus.replace(/\s+/g, "") === "";
    if (isEmpty) {
      this.error = new GlossError(this.globalService, {
        messageKey: "emptyTransactionStatus",
        type: "warning",
      });
      return false;
    }

    const isNotSupported = !Object.values(TransactionStatusEnum).includes(
      this.balanceForm.transactionStatus
    );
    if (isNotSupported) {
      this.error = new GlossError(this.globalService, {
        messageKey: "invalidTransactionStatus",
        type: "warning",
      });
      return false;
    }

    return true;
  }

  async hasBalanceForDate(): Promise<boolean> {
    try {
      const accountTransactions = await this.transactionService.getAccountTransactions(
        this.accountView.originalBook
      );
      this.alignmentOfDate = accountTransactions.find((transaction) => {
        return (
          transaction.definition === this.transactionStatus &&
          transaction.transactionDate.date.getTime() === this.newDate.date.getTime()
        );
      });
      return !!this.alignmentOfDate;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  isBalanceSameForDate(): boolean {
    try {
      if (!this.alignmentOfDate) {
        return false;
      }
      return this.alignmentOfDate.bankImportedBalance === this.newBalance.amount;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  getUpdateBalanceMessageDetails() {
    return `Your existing balance is <span class="tw-text-accent">${this.alignmentOfDate.bankImportedBalance}</span> for <span class="tw-text-accent">${this.newDate.dateString}</span> and you are about to change it to <span class="tw-text-accent">${this.newBalance.amount}</span>.`;
  }

  async confirmUpdateBalance() {
    if (!this.isSameBalanceSameDate) {
      const balanceMessageDetails = this.getUpdateBalanceMessageDetails();
      const confirm = await this.confirmationDialogService.confirmFor(
        ConfirmationEnum.updatingBalance,
        { messageReplacement: balanceMessageDetails }
      );

      if (!confirm) {
        this.newBalance = new GlossNumber().setToGlossNumberObj({
          amount: this.alignmentOfDate.bankImportedBalance,
        });
        this.balanceForm.balance = this.alignmentOfDate.bankImportedBalance.toString();
      }

      return confirm;
    }

    return false;
  }

  async confirmToKeepBalance() {
    if (this.isSameBalanceSameDate) {
      const balanceMessageDetails = `<span class="tw-block xs:tw-text-xs">You already have the balance of ${this.alignmentOfDate.bankImportedBalance} for  ${this.newDate.dateString}.</span> <span class="xs:tw-text-xs">Would you like to keep the existing balance?</span>`;
      return await this.confirmationDialogService.confirmFor(ConfirmationEnum.havingNoChanges, {
        messageReplacement: balanceMessageDetails,
      });
    }

    return false;
  }

  async updateBalance() {
    try {
      this.alignmentOfDate.bankImportedBalance = this.newBalance.amount;
      await this.transactionService.update(this.alignmentOfDate);
      this.globalService.showSuccessMessage("success", "balanceUpdatedSuccessfully");
      return true;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
      return false;
    }
  }

  private async addBalance() {
    try {
      //TODO mo refresh market data to proper places... maybe in the createAccountAlignmentTransaction method
      const transaction = await this.transactionService.createAccountAlignmentTransaction(
        this.accountView.originalBook,
        this.balanceForm,
        true
      );
      if (transaction instanceof Transaction) {
        await this.marketDataService.refreshMarketData([transaction]);
      }
      this.globalService.showSuccessMessage("success", "balanceAddedSuccessfully");
      return true;
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
      return false;
    }
  }

  private showErrorMessage() {
    this.globalService.showInfoMessage("oops", "balanceExistsForDate");
  }

  async process() {
    if (!(await this.isReady())) {
      return false;
    }

    if (this.action === BalanceActionEnum.keep_existing) {
      return true;
    }

    if (this.action === BalanceActionEnum.add) {
      return await this.addBalance();
    }

    if (this.action === BalanceActionEnum.edit) {
      return await this.updateBalance();
    }

    if (this.action === null) {
      return false;
    }
  }

  async isReady() {
    await this.runPreProcess();

    if (this.error) {
      this.error.displayError();
      return false;
    }

    await this.setFinalAction();
    return true;
  }

  addOpeningBalance() {
    return this.addBalance();
  }

  addClosingBalance() {
    return this.addBalance();
  }

  updateConfirmedBalance() {
    return this.updateBalance();
  }
}
