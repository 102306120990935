<div [hidden]="!chooseAccountDialogue">
  <div
    class="modal-container"
    [ngClass]="{ 'tw-flex tw-h-full tw-flex-row-reverse tw-gap-4': selectedFile }"
  >
    <div
      #contentDiv
      class="tw-flex tw-flex-col tw-h-full tw-max-w-[100vw]
                          {{ selectedFile ? 'tw-w-[700px]' : 'tw-w-[800px]' }}"
    >
      <!--  header-->
      <modal-header
        [title]="(addTransaction ? 'addTransactions' : 'uploadNewFile') | i18n"
        [showBackButton]="false"
        [enableTooltip]="false"
      >
      </modal-header>
      <!--  body-->
      <div class="m-body tw-p-10 xs:tw-px-5">
        <form
          name="accountForm"
          class="tw-max-h-[700px] tw-overflow-y-auto tw-p-2 xs:tw-max-h-full xs:tw-overflow-y-hidden xs:tw-p-0"
        >
          <div class="tw-flex tw-flex-col tw-gap-12">
            <app-file-upload
              (selectedFile)="receivedSelectedFile($event)"
              (toggleFullPreview)="toggleFilePreview()"
            ></app-file-upload>

            <div *ngIf="addBalance">
              <app-add-balance
                [selectedFile]="selectedFile"
                (outputLinkedAccounts)="receivedLinkedAccountsData($event)"
                (outputFileData)="receivedFileData($event)"
              ></app-add-balance>
            </div>

            <div *ngIf="addTransaction && selectedAccount" class="tw-flex tw-flex-col tw-gap-3.5">
              <app-display-transactions
                [newTransactions]="transactions"
                (isEditTransaction)="receivedIsEditTransaction($event)"
                (isRemoveTransaction)="receivedIsRemoveTransaction($event)"
              ></app-display-transactions>
              <div class="tw-pb-5">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <p>
                    Add transaction to
                    <span class="tw-font-medium"
                      >{{
                        isEditExistingTransaction && inEditAccount
                          ? inEditAccount.name
                          : selectedAccount.name
                      }}
                      <button
                        (click)="changeSelectedAccount()"
                        [disabled]="noAccountCanChange"
                        class="tw-items-center tw-justify-center tw-p-3"
                        [ngClass]="{
                          'tw-opacity-30': noAccountCanChange,
                          'hover:tw-cursor-pointer': !noAccountCanChange
                        }"
                      >
                        <svg class="tw-h-4 tw-w-4">
                          <use xlink:href="images/sprite.svg#sync-all" />
                        </svg>
                      </button>
                    </span>
                  </p>
                </div>
                <div [hidden]="!isAddTransactionForm">
                  <div class="tw-flex tw-justify-between tw-pb-1">
                    <label class="autocomplete-label tw-font-medium">{{
                      "transactionDescription" | i18n
                    }}</label>
                    <app-gloss-button
                      *ngIf="isEditExistingTransaction"
                      [options]="updateTransaction"
                    ></app-gloss-button>
                  </div>
                  <app-add-transaction
                    (transactionData)="receivedTransactionData($event)"
                  ></app-add-transaction>
                </div>
              </div>
              <app-gloss-button [options]="addMoreTransaction"></app-gloss-button>
              <div class="tw-flex tw-justify-end tw-pt-5">
                <app-gloss-button
                  class="tw-w-1/2 xs:tw-w-full"
                  [options]="{
                    type: 'submit',
                    text: uploading ? ('uploading' | i18n) : ('saveAllTransactions' | i18n),
                    onClick: !noAccountCanChange
                      ? this.nextAccountAddTransaction.bind(this)
                      : this.submitFile.bind(this),
                    isEnabled: !uploading,
                    class: 'primary'
                  }"
                ></app-gloss-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <app-file-preview
      *ngIf="selectedFile && isMediumOrLarger && showFilePreview"
      [hidden]="!isMediumOrLarger"
      [data]="{ file: selectedFile }"
      [hasContainer]="true"
      [ngStyle]="{ height: previewContentHeight }"
      class="tw-h-full tw-w-[700px] tw-min-w-[300px] tw-resize-x tw-overflow-auto"
    >
    </app-file-preview>
  </div>
</div>
