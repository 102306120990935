import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BestScenarioStepper } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-stepper";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { StepperAction } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-move-asset",
  templateUrl: "./move-asset.component.html",
})
export class MoveAssetComponent extends BestScenarioStepper implements OnInit, OnDestroy {
  @Output() onAction = new EventEmitter<StepperAction>();
  private destroy$ = new Subject<void>();
  private dashboardService: DashboardService;

  earningsCard: EarningDataCard;

  nextBtnOptions = new ActionButton({
    text: this.i18n.t("next"),
    type: "primary",
    testId: "stepperNextButton",
  });

  prevBtnOptions = new ActionButton({
    text: this.i18n.t("previous"),
    type: "secondary",
    testId: "stepperPrevButton",
  });

  constructor(injector: Injector) {
    super(injector);
    this.dashboardService = injector.get(DashboardService);
  }

  ngOnInit() {
    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = scenarioData;
      if (this.scenarioData) {
        this.setUpStep().then(async () => {
          this.existingAccounts = await this.getExistingAccounts();
          this.updateData();
        });
      }
    });
  }

  updateData() {
    this.earningsCard = {
      header: this.i18n.t("move"),
      earningAmount: this.helpInfo.transferAmount,
      currency: this.helpInfo.symbol,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
