import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BalanceForm, TransactionForm } from "@bitwarden/web-vault/app/models/types/account.types";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

export const getClosingAlignmentTransaction = (account: any, balance?: number): Transaction => {
  const closingTransactionObject: any = {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: account.currency,
    currency: account.currency,
    description: "Generated Transaction",
    amount: "",
    accountId: account.id,
    classifications: account.defaultClassifications || [],
    categories: account.defaultCategories || [],
    balance: balance ? balance.toString() : account.balance.toString(),
    direction: Number(account.balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
    date: account.date
      ? account.date
      : DateFormat.getDateStringFromStampStatically(new Date().getTime()),
    definition: TransactionStatusEnum.closing,
  };

  return new Transaction(new TransactionResponse(closingTransactionObject));
};

export const getOpeningAlignmentTransaction = (
  account: any,
  balance: number,
  openingBalanceDate: string
): Transaction => {
  const closingTransactionObject: any = {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: account.currency,
    currency: account.currency,
    description: "Generated Transaction (Opening)",
    amount: "",
    accountId: account.id,
    classifications: account.defaultClassifications || [],
    categories: account.defaultCategories || [],
    balance: balance.toString(),
    direction: Number(account.balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
    date: openingBalanceDate,
    definition: TransactionStatusEnum.opening,
  };

  return new Transaction(new TransactionResponse(closingTransactionObject));
};

const getBookDetails = (
  book: Book,
  balanceForm: BalanceForm | TransactionForm,
  isUseFormCurrency = false
): object => {
  const { currency, defaultClassifications, defaultCategories, id } = book;
  return {
    id: crypto.randomUUID(),
    convrate: 1,
    symbol: isUseFormCurrency ? balanceForm.currency : currency,
    currency: isUseFormCurrency ? balanceForm.currency : currency,
    accountId: id,
    classifications: defaultClassifications || [],
    categories: defaultCategories || [],
  };
};

export const getBalanceAlignmentTransaction = (
  book: Book,
  form: BalanceForm,
  isUseFormCurrency = false
): Transaction => {
  const transaction: object = {
    ...getBookDetails(book, form, isUseFormCurrency),
    description: form.description
      ? form.description
      : form.type
      ? `${form.type} balance (${form.transactionStatus}) ${form.balance}`
      : `Fake Transaction (${form.transactionStatus}) ${form.balance}`,
    amount: "",
    classifications: book.defaultClassifications || [],
    categories: book.defaultCategories || [],
    balance: form.balance, // running balance
    date: form.date,
    time: form.time,
    definition: form.transactionStatus,
  };
  return new Transaction(new TransactionResponse(transaction));
};

export const getNormalTransaction = (book: Book, form: TransactionForm): Transaction => {
  const amount = Number(form.amount);
  const transaction: object = {
    ...getBookDetails(book, form, true),
    description: form.description || `Fake Transaction (${form.transactionStatus}) ${form.amount}`,
    classifications: book.defaultClassifications || [],
    categories: book.defaultCategories || [],
    balance: "", // running balance
    direction: form.type,
    date: form.date,
    time: form.time,
    definition: form.transactionStatus,
    In: form.type === "IN" ? amount : null,
    Out: form.type === "OUT" ? amount : null,
  };
  return new Transaction(new TransactionResponse(transaction));
};
