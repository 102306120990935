<best-scenario-earnings-card>
  <app-earnings-header
    header
    [header]="earningsData.header"
    [headerDescription]="earningsData.headerDescription"
  >
  </app-earnings-header>

  <app-earnings-content
    content
    [earningAmount]="earningsData.earningAmount"
    [currency]="earningsData.currency"
  >
  </app-earnings-content>

  <ng-container footer>
    <span
      class="tw-text-xs tw-font-normal tw-tracking-tight tw-text-neutral-900"
      [innerHTML]="'inTheNxtMonths' | i18n : earningsData.months"
    ></span>
  </ng-container>
</best-scenario-earnings-card>
