import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "toggle-switch",
  templateUrl: "toggle-switch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent {
  @Input() isEnabled = false;
  @Output() onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  onToggleClick() {
    this.toggle();
    this.onClick.emit(this.isEnabled);
  }

  toggle() {
    this.isEnabled = !this.isEnabled;
  }
}
