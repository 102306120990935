<app-spinner [showSpinner]="loading"></app-spinner>
<div class="container">
  <h1 mat-dialog-title class="title">{{ formTitle }}</h1>
  <form [formGroup]="form" *ngIf="form">
    <input matInput hidden="hidden" formControlName="_id" />
    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label>Account</mat-label>
      <mat-select id="accountId" formControlName="accountId">
        <mat-option *ngFor="let account of accounts" [value]="account.id">
          {{ account.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="_transactionDate" />
      <mat-datepicker-toggle matSuffix [for]="picker" class="flex-column"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label for="categories">Categories</mat-label>
      <mat-select
        id="categories"
        name="categories"
        (selectionChange)="selectCategory($event)"
        formControlName="categories"
      >
        <mat-option *ngFor="let category of filteredCategories" [value]="category">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="elem-app-split" *ngIf="selectedCategories.length > 0">
      <app-splitcategory
        *ngFor="let sc of selectedCategories; let i = index"
        [inputValue]="sc.weight"
        [inputName]="sc.name ? sc.name : ''"
        [splitName]="sc.name ? sc.name : ''"
        (inputChangeEvent)="addSelectedCategory($event, i)"
        (removeComponentEvent)="removeSelectedCategory(i)"
        [isDisabled]="isDisabled"
      >
      </app-splitcategory>
    </div>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label for="classifications">Classifications</mat-label>
      <mat-select
        id="classifications"
        name="classifications"
        (selectionChange)="selectClassification($event)"
        formControlName="classifications"
      >
        <mat-option *ngFor="let classification of filteredClassifications" [value]="classification">
          {{ classification.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="elem-app-split" *ngIf="selectedClassifications.length > 0">
      <app-splitclassification
        *ngFor="let sc of selectedClassifications; let i = index"
        [inputValue]="sc.weight"
        [inputName]="sc.name ? sc.name : ''"
        [splitName]="sc.name ? sc.name : ''"
        (inputChangeEvent)="addSelectedClasification($event, i)"
        (removeComponentEvent)="removeSelectedClassification(i)"
        [isDisabled]="isDisabled"
      >
      </app-splitclassification>
    </div>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label>Description</mat-label>
      <textarea matInput type="text" id="description" formControlName="description"> </textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label for="currency">Currency</mat-label>
      <input
        matInput
        id="currency"
        type="text"
        name="currency"
        formControlName="currency"
        #currencyInput
        [matAutocomplete]="autoCurrency"
        (keyup)="searchForCurrency()"
      />
      <mat-autocomplete autoActiveFirstOption #autoCurrency="matAutocomplete">
        <mat-option *ngFor="let option of filteredCurrencies" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.controls.currency.hasError('required')"> Field is required </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label for="direction">Direction</mat-label>
      <mat-select id="direction" name="direction" formControlName="direction">
        <mat-option *ngFor="let direction of transactionDirections" [value]="direction">
          {{ direction }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-group mb-4">
      <mat-label>{{ "amount" | i18n }}</mat-label>
      <input matInput type="number" id="quantity" formControlName="quantity" />
    </mat-form-field>

    <div class="modal-footer right-buttons-container">
      <button mat-raised-button mat-dialog-close (click)="handleCancel()">
        {{ "cancel" | i18n }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="handleSubmit()"
        *ngIf="!isDisabled"
      >
        {{ "save" | i18n }}
      </button>
    </div>
  </form>
</div>
