import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "nav-panel",
  templateUrl: "./navigation-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationPanelComponent {
  @Output() onClick = new EventEmitter<void>();
  @Input() title: string;
}
