<div class="autocomplete-container">
  <label class="autocomplete-label">{{ label }} *</label>
  <mat-form-field
    appearance="fill"
    class="autocomplete-form-field"
    [ngClass]="{ 'mat-form-field-error': isInvalid }"
    floatLabel="always"
  >
    <input
      type="text"
      matInput
      [matAutocomplete]="auto"
      [formControl]="itemControl"
      class="autocomplete-input"
      [placeholder]="placeholder"
      (input)="onKey($event)"
      (blur)="onBlur($event)"
      tabindex="-1"
      required
    />
    <svg
      matSuffix
      class="tw-h-3 tw-w-3 tw-text-primary"
      *ngIf="itemControl.value && !disabled"
      (click)="clearInput()"
    >
      <use xlink:href="images/sprite.svg#close" />
    </svg>
    <mat-error class="error-inline input-error" *ngIf="isInvalid">{{
      "inputRequired" | i18n
    }}</mat-error>
    <svg matSuffix class="tw-h-6 tw-w-6 tw-text-primary">
      <use xlink:href="images/sprite.svg#down-arrow" />
    </svg>
  </mat-form-field>
  <mat-autocomplete
    (opened)="onPanelOpen()"
    (closed)="onPanelClose()"
    #auto="matAutocomplete"
    [displayWith]="displayItem.bind(this)"
    (optionSelected)="itemSelected($event.option.value)"
    class="tw-mt-1 tw-max-h-[300px] !tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-bg-white"
  >
    <mat-option
      *ngFor="let item of filteredItems | async"
      [value]="item"
      class="tw-flex !tw-h-10 tw-flex-row tw-items-center tw-pl-5 tw-text-base tw-text-neutral-800"
      >{{ item.name ? item.name : item }}</mat-option
    >
  </mat-autocomplete>
</div>
