<!--todo: use Ronald's practice instead of ngif 2/9/2024-->
<div
  *ngIf="showHelpTooltip"
  class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[999] tw-h-screen tw-bg-black tw-opacity-60"
  (click)="closeHelpTooltip($event)"
></div>
<div *ngIf="showHelpTooltip" class="help-tooltip" #popupReference>
  <div class="help-inner-scroll">
    <div class="tooltip-pointer md:tw-hidden" #tooltipPointer></div>
    <div class="help-tooltip-outer" #tooltipOuter>
      <button
        class="tw-absolute tw-right-0 tw-top-0 tw-m-2 tw-cursor-pointer tw-p-2"
        (click)="closeHelpTooltip($event)"
      >
        <svg class="tw-h-4 tw-w-4 tw-text-black">
          <use [attr.xlink:href]="'images/sprite.svg#close'" />
        </svg>
      </button>
      <div>
        <div
          class="tooltip-information"
          *ngIf="!shouldRenderContent"
          [innerHtml]="tooltipInfo | safeHtml"
        ></div>
        <div class="tooltip-information" *ngIf="shouldRenderContent">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
