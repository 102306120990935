import { SETTINGS_PATH } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";

// todo remove this as this shouldn't have "/"
export enum GlossRouteEnum {
  dashboard = "/primary-dashboard",
  cabinet = "/cabinet",
  account = "/manage/account",
  categories = "/manage/categories",
  classifications = "/manage/classifications",
  institutions = "/manage/institutions",
  import = "/import-management",
  reference = "/reference-management",
  estimates = "/estimates",
  preferences = "/preferences",
  subscription = "/settings/subscription",
  sync = "/sync",
}

export enum GlossRoutes {
  Dashboard = "primary-dashboard",
  Cabinet = "cabinet",
  Account = "account",
  Categories = "categories",
  Classifications = "classifications",
  Institutions = "institutions",
  Import = "import-management",
  Reference = "reference-management",
  Estimates = "estimates",
  Preferences = "preferences",
  Subscription = "subscription",
  Sync = "sync",
  Views = "views",
}

export const NavItems = [
  {
    routerLink: ["/", GlossRoutes.Views],
    currentUrl: "/" + GlossRoutes.Views,
    icon: "views",
    selectedIcon: "views-selected",
    label: "sideMenuDashboard",
    roleScope: RoleScope.VIEW,
  },
  {
    routerLink: ["/manage", "account"],
    currentUrl: GlossRouteEnum.account,
    icon: "accounts",
    selectedIcon: "accounts-selected",
    label: "sideMenuAccount",
    roleScope: RoleScope.GLOSS,
  },
  {
    routerLink: ["/", "cabinet"],
    currentUrl: GlossRouteEnum.cabinet,
    icon: "cabinet",
    selectedIcon: "cabinet-selected",
    label: "sideMenuCabinet",
    roleScope: RoleScope.GLOSS,
  },
  {
    routerLink: ["/manage", "categories"],
    currentUrl: GlossRouteEnum.categories,
    icon: "categories",
    selectedIcon: "categories-selected",
    label: "categories",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/manage", "classifications"],
    currentUrl: GlossRouteEnum.classifications,
    icon: "classifications",
    selectedIcon: "classifications-selected",
    label: "classifications",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/manage", "institutions"],
    currentUrl: GlossRouteEnum.institutions,
    icon: "institutions",
    selectedIcon: "institutions-selected",
    label: "institutions",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/", "import-management"],
    currentUrl: GlossRouteEnum.import,
    icon: "import-data",
    selectedIcon: "import-data-selected",
    label: "importData",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    routerLink: ["/", "reference-management"],
    currentUrl: GlossRouteEnum.reference,
    icon: "reference",
    selectedIcon: "reference-selected",
    label: "sideMenuReferenceData",
    roleScope: RoleScope.EQUITY,
  },
  {
    routerLink: ["/", "estimates"],
    currentUrl: GlossRouteEnum.estimates,
    icon: "estimate",
    selectedIcon: "estimate-selected",
    label: "sideMenuEstimates",
    roleScope: RoleScope.FORECASTING,
  },
  {
    routerLink: ["/", "sync"],
    currentUrl: GlossRouteEnum.sync,
    icon: "sync",
    selectedIcon: "sync-selected",
    label: "sideMenuSync",
    roleScope: RoleScope.DATA_AGGREGATOR,
  },
  {
    routerLink: [SETTINGS_PATH],
    currentUrl: SETTINGS_PATH,
    icon: "settings",
    selectedIcon: "settings-selected",
    label: "settings",
    roleScope: RoleScope.GLOSS,
  },
];

export const NavItemsMobile = [
  {
    key: GlossRoutes.Views,
    routerLink: ["/", GlossRoutes.Views],
    icon: "views",
    selectedIcon: "views-selected",
    label: "sideMenuDashboard",
  },
  {
    key: "account",
    routerLink: ["/manage", "account"],
    icon: "accounts",
    selectedIcon: "accounts-selected",
    label: "sideMenuAccount",
  },
  {
    key: "cabinet",
    routerLink: ["/", "cabinet"],
    icon: "cabinet",
    selectedIcon: "cabinet-selected",
    label: "sideMenuCabinet",
    roleScope: RoleScope.BETA_ACCESS,
  },
  {
    key: "settings",
    routerLink: [SETTINGS_PATH],
    icon: "settings",
    selectedIcon: "settings-selected",
    label: "settings",
  },

  //TODO -  return this back once the new mobile nav is ready or when you are done with cabinet mobile
  { key: "help", routerLink: null, icon: "help", selectedIcon: "help-selected", label: "help" },
];

export enum MainSettingsRoute {
  Profile = "profile",
  Security = "security",
  AdvancedFeatures = "advanced-features",
  Preferences = "preferences",
  Access = "access",
}

export enum SecuritySettingsRoute {
  ChangePassword = "change-password",
  LoginDevices = "login-devices",
}
