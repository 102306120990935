import { Component, Input, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-file-preview",
  templateUrl: "./file-preview.component.html",
})
export class FilePreviewComponent implements OnInit {
  isAndroidMobile = false;

  @Input() data: { file: File };
  @Input() hasContainer = false;

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  //currently only support pdf and text preview, text is controlled by startWith('text/')
  // can add more types here in the future
  supportedFileTypes: string[] = [
    "application/pdf",
    // 'text/csv',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];

  ngOnInit() {
    // Android mobile devices check
    // Chrome for desktop views PDF through the Chrome PDF viewer plugin, while in mobile version the plugin is not supported
    // Android chrome currently redirects to google drive pdf viewer by default which requires an active url link accessible to the public. or users needs install the google pdf viewer app.
    // p.s. IOS chrome is just safari, different engine, and it supports pdf preview.
    // to be researched: chrome canery on android mobile seems to support pdf preview
    this.isAndroidMobile =
      this.deviceDetectorService.isMobile() && this.deviceDetectorService.os === "Android";
  }

  isSupportedFileType(fileType: string): boolean {
    return this.supportedFileTypes.includes(fileType) || fileType.startsWith("text/");
  }
}
