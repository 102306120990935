export abstract class WebWorker {
  protected type = "message";
  private worker: Worker;
  protected _workerType: string;
  protected _workerIndex: number;
  protected _parent: string;

  constructor(worker: Worker) {
    this.worker = worker;
  }

  protected addEventListener(type: string, listener: (evt: MessageEvent) => void): void {
    this.worker?.addEventListener(type, listener as any);
  }

  protected removeEventListener(type: string, listener: (evt: MessageEvent) => void): void {
    this.worker?.removeEventListener(type, listener as any);
  }

  onMessage<T>(observer: (value: T) => void): void {
    this.addEventListener(this.type, (message) => observer(message.data));
  }

  postMessage<T>(message: T, transfers?: Transferable[]): boolean {
    this.worker?.postMessage(message, transfers);
    return !!this.worker;
  }

  terminate(): void {
    this.worker?.terminate();
    this.worker = null;
  }

  get workerType(): string {
    return this._workerType;
  }

  set workerIndex(workerIndex: number) {
    this._workerIndex = workerIndex;
  }

  get workerIndex(): number {
    return this._workerIndex;
  }

  get parent(): string {
    return this._parent;
  }

  set parent(parent: string) {
    this._parent = parent;
  }
}
