import { Component, OnInit, ViewChild, OnDestroy, DoCheck } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AccountBalanceComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-balance/account-balance.component";
import { AccountRegionsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-regions/account-regions.component";
import { AccountAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/account-add-edit.component";
import { CreationOptionsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/creation-options/creation-options.component";
import { InstitutionSelectionComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/institution-selection/institution-selection.component";
import {
  BasiqImportState,
  TransactionBasiqImporter,
} from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { SubscriptionService } from "@bitwarden/web-vault/app/services/DataService/subscription/subscription.service";
import { Permission } from "@bitwarden/web-vault/app/services/permission/permission";

import { PreferenceService } from "../../services/DataService/preference/preference.service";
import { MainProgressBar } from "../../services/progress-bar/main-progress-bar";

import { StepModel } from "./step.model";
import { WizardService } from "./wizard-stepper-service";

import "./account-wizard-stepper.component.scss";
@Component({
  selector: "app-account-wizard-stepper",
  templateUrl: "./account-wizard-stepper.component.html",
  styles: ["account-wizard-stepper.component.scss"],
})
export class AccountWizardStepperComponent implements OnInit, OnDestroy, DoCheck {
  loading = false;
  isImporting = false;
  hasAccess = false;
  canAccessPremium: boolean;
  existingAccounts: Book[];
  booksView: AccountView[];
  actionPromise: Promise<any>;
  accountBalanceRef: MatDialogRef<AccountBalanceComponent>;
  accountAddRef: MatDialogRef<AccountAddEditComponent>;
  creationOptionsRef: MatDialogRef<CreationOptionsComponent>;
  regionOptionsRef: MatDialogRef<AccountRegionsComponent>;
  institutionsModalRef: MatDialogRef<InstitutionSelectionComponent>;
  steps$: Observable<StepModel[]>;
  addAccountButton = "addAccount";
  setBalanceButton = "enterAccountData";
  viewForecastButton = "viewForecast";
  setBalanceTitle = "addAccountBalanceTitle";
  setBalanceDescription = "addAccountBalanceDescription";
  accessLoader = true;
  setBalanceProgressTitle = "setBalance";
  dataSource: MatTableDataSource<AccountView>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "institution", "type", "source", "status", "actions"];

  protected readonly Origin = Origin;
  private destroy$ = new Subject<void>();

  constructor(
    private wizardService: WizardService,
    public dialog: MatDialog,
    private globalService: GlobalService,
    private bookService: BookService,
    private permission: Permission,
    private subscriptionService: SubscriptionService,
    protected i18nService: I18nService,
    private transactionBasiqImporter: TransactionBasiqImporter,
    private mainProgressBar: MainProgressBar,
    private institutionService: InstitutionService,
    protected preferenceService: PreferenceService
  ) {
    /** inWizard is used to hide the progress bar in account section if basiq is used */
    localStorage.setItem("inWizard", "true");
  }

  async ngOnInit() {
    await this.wizardService.getBlobbyWizardArray();
    await this.hasLocationSet();
    this.wizardService.resetSteps();
    await this.wizardService.startWizard();
    this.steps$ = this.wizardService.getSteps();

    const shouldCallBasiq = await this.transactionBasiqImporter.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      await this.setCurStepAsComplete();
    }

    this.transactionBasiqImporter.basiqImportState$.pipe(takeUntil(this.destroy$)).subscribe({
      next: async (state) => {
        await this.updatedWizardState(state);
      },
    });
  }

  private async hasLocationSet() {
    const location = (await this.preferenceService.get(PreferenceType.userLocation)) as string;
    const hasLocation = location !== undefined;

    if (hasLocation && !this.institutionService.isExistingUser) {
      this.preferenceService.setUserLocation(hasLocation);
    }
    return hasLocation;
  }

  async updatedWizardState(state: BasiqImportState) {
    if (state.started && !state.ended) {
      this.isImporting = true;
      this.setBalanceTitle = "syncingYourTransactions";
      this.setBalanceDescription = "syncingYourTransactionsDescription";
      this.setBalanceButton = "retrievingData";
      this.setBalanceProgressTitle = "retrievingTransactions";
    }

    if (state.ended && state.success) {
      this.wizardService.setSyncedAccount(true);
      this.setBalanceButton = "done";
      this.isImporting = false;
      await this.wizardService.setNewStepAsCurrentStep({ stepIndex: 2, isComplete: true });
      await this.setCurStepAsComplete();
      this.transactionBasiqImporter.completeBasiqImport();
      localStorage.removeItem("inWizard");
    }

    if (state.ended && !state.success) {
      this.setBalanceButton = "dataRetrievalFailed";
      // TODO : Update the wizard state to show the next step
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck() {
    const userSubscription = this.subscriptionService.getUserSubscription();
    if (!userSubscription) {
      this.subscriptionService.initSubscription();
    }

    if (this.accessLoader && userSubscription?.metadata.id) {
      this.hasAccess = this.permission.hasAccess(userSubscription);
      this.accessLoader = false;
    }
  }

  async setCurStepAsComplete() {
    const curStep = this.wizardService.getCurrentStep();
    await this.wizardService.currentStepComplete(curStep);
  }

  isButtonEnabled(number: number) {
    return this.wizardService.getCurrentStep()?.stepIndex !== number;
  }

  addProgressBarClass() {
    switch (this.wizardService.getCurrentStep()?.stepIndex) {
      case 2:
        return "step-2";
      case 3:
        return "step-3";
    }
  }

  stepAfterComplete(number: number) {
    const curStep = this.wizardService.getCurrentStep()?.stepIndex;
    const steps = this.wizardService.getStepsValue();

    const buttonText = [this.addAccountButton, this.setBalanceButton, this.viewForecastButton];

    const getButtonState = (step: any) =>
      step.isComplete ? "done" : buttonText[step.stepIndex - 1];
    const getIconClass = (step: any, isCurrentStep: boolean) => {
      if (step.isComplete) {
        return isCurrentStep ? "step-check-icon-complete-latest" : "step-check-icon-complete-2";
      } else {
        return isCurrentStep ? "step-check-icon-in-progress" : "step-check-icon-empty";
      }
    };

    switch (number) {
      case 1:
        this.addAccountButton = getButtonState(steps[0]);
        return steps[0].isComplete ? "step-check-icon-1-finish" : "step-check-icon";
      case 2:
        this.setBalanceButton = getButtonState(steps[1]);
        return getIconClass(steps[1], curStep === number);
      case 3:
        this.viewForecastButton = getButtonState(steps[2]);
        return getIconClass(steps[2], curStep === number);
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add new account"
  // -------------------------------------------------------------------------------------------------------------------
  openCreationOptionsModal() {
    const dialogRef = this.dialog.open(CreationOptionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        createManually: this.createManually.bind(this),
        createByLinking: this.createByLinking.bind(this),
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        isPremiumAccount: this.hasAccess,
      },
      disableClose: true,
    });
    this.creationOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeCreationOptionsDialogue() {
    this.globalService.showMessage("info", "createAccount", "pleaseCreateAnAccountFirst");
  }

  createManually() {
    this.creationOptionsRef.close();
    this.openInstitutionSelectionModal();
  }

  createByLinking() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(AccountRegionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeRegionOptionDialogue.bind(this),
        openCreationOptionModal: this.openCreationOptionsModal.bind(this),
        setLoading: this.setLoading.bind(this),
      },
      disableClose: true,
    });
    this.regionOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeRegionOptionDialogue() {
    this.regionOptionsRef.close();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  openInstitutionSelectionModal() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(InstitutionSelectionComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeInstitutionSelectionDialogue.bind(this),
        openInstitutionDialogue: this.openInstitutionSelectionModal.bind(this),
        openCreationOptionsDialogue: this.openCreationOptionsModal.bind(this),
        openAccountAddModal: this.openAddAccountModal.bind(this),
      },
      disableClose: true,
    });
    this.institutionsModalRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeInstitutionSelectionDialogue() {
    this.institutionsModalRef.close();
  }

  async openAddAccountModal(institution: Institution) {
    await this.addAccount(null, institution);
  }

  async addAccount(account: Book, selectedInstitution?: Institution) {
    const preference = this.preferenceService.getPreferenceInstance();
    const dialogRef = this.dialog.open(AccountAddEditComponent, {
      panelClass: "no-background-dialog",
      data: {
        actionSucceeded: this.openTransactionImportModal.bind(this),
        institution: selectedInstitution,
        accountCurrency: preference.baseCurrency,
        closeDialogue: this.closeAccountAddModal.bind(this),
        openInstitutionSelectionModal: this.openInstitutionSelectionModal.bind(this),
        isWizard: true,
      },
      disableClose: true,
    });
    this.accountAddRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  openTransactionImportModal() {
    this.accountAddRef.close();
  }

  async closeAccountAddModal() {
    this.accountAddRef.close();
    this.booksView = await this.bookService.getBooksView();
    if (this.booksView[0] !== undefined) {
      await this.setCurStepAsComplete();
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add balance at selected account"
  // -------------------------------------------------------------------------------------------------------------------

  async addBalanceSelected() {
    this.booksView = await this.bookService.getBooksView();
    const firstManuelAccount = this.booksView[0];
    if (firstManuelAccount) {
      this.addBalance(firstManuelAccount);
    } else {
      this.globalService.showMessage(
        "info",
        "addAccount",
        "pleaseCreateAnAccountManuallyToAddBalance"
      );
      await this.wizardService.setNewStepAsCurrentStep({ stepIndex: 1, isComplete: false });
    }
  }

  addBalance(account: AccountView) {
    if (account.origin !== Origin.manual) {
      this.globalService.showWarningMessage("warning", "balanceForManualAccount");
      return;
    }
    const dialogRef = this.dialog.open(AccountBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: this.closeBalanceModal.bind(this),
      },
      disableClose: true,
    });
    this.accountBalanceRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });
  }

  async actionSucceeded(actionKey: string) {
    this.existingAccounts = await this.bookService.getAll();
    this.booksView = await this.bookService.getBooksView();
    this.dataSource = new MatTableDataSource<AccountView>(this.booksView);
    this.dataSource.paginator = this.paginator;
    this.accountAddRef?.close(this.existingAccounts);
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }

  async closeBalanceModal() {
    this.accountBalanceRef.close();
    await this.setCurStepAsComplete();
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Jump to primary dashboard
  // -------------------------------------------------------------------------------------------------------------------

  async wizardExit() {
    await this.wizardService.endWizard();
  }
}
