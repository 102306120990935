<app-spinner [showSpinner]="loading"></app-spinner>
<form
  class="modal-content"
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  [formGroup]="estimateFormGroup"
>
  <div id="estimate-form-modal" class="modal-body" *ngIf="!loading">
    <div class="estimate-form-parts-container">
      <ng-container class="input-class" *ngIf="!isEditMode && existingEstimates?.length">
        <div class="form-group mb-4">
          <label
            >{{ "useEstimateAsBase" | i18n }}
            <span
              class="mat-form-field-required-marker"
              title="Use an existing estimate to fill the form"
              >?</span
            >
          </label>
          <div class="form-control">
            <mat-select (selectionChange)="handleUseEstimate($event)">
              <mat-option *ngFor="let item of existingEstimates" [value]="item">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </ng-container>

      <div id="regular-estimate" class="part">
        <ng-container class="input-class">
          <div class="form-group mb-4">
            <label class="input-label"
              >{{ "estimateName" | i18n }}
              <span class="mat-form-field-required-marker">*</span></label
            >
            <input
              formControlName="name"
              autocomplete="off"
              class="form-control"
              type="text"
              name="Account Name"
            />
          </div>
        </ng-container>

        <app-split-options
          [formGroup]="estimateFormGroup"
          [isPanelOpen]="isCategoryOpen"
          (toggleOptionsPanel)="handleCategories()"
          [mainFormControlName]="'categories'"
          [optionControlNamePrefix]="'category'"
          [options]="categories"
          [title]="'category'"
          addNewButtonText="addNewCategory"
          [selectedOptions]="selectedCategories"
        >
        </app-split-options>

        <app-split-options
          [formGroup]="estimateFormGroup"
          [isPanelOpen]="isClassificationOpen"
          (toggleOptionsPanel)="handleClassification()"
          [mainFormControlName]="'classifications'"
          [optionControlNamePrefix]="'classification'"
          [options]="classifications"
          [title]="'classification'"
          addNewButtonText="addNewClassification"
          [selectedOptions]="selectedClassifications"
        >
        </app-split-options>

        <ng-container class="input-class">
          <div class="form-group mb-4">
            <label
              >{{ "account" | i18n }}
              <span class="mat-form-field-required-marker">*</span>
              <span
                class="mat-form-field-required-marker"
                title="Account that your estimate is going to be made to or from"
                >?</span
              >
            </label>
            <input
              formControlName="accountName"
              autocomplete="off"
              class="form-control"
              type="text"
              name="account"
              (click)="openAccountSelection()"
            />
          </div>
          <div class="categories-container" *ngIf="isAccountsOpen">
            <div class="categories-items-container">
              <div
                class="one-category-container account-for-estimate"
                *ngFor="let account of existingAccounts; let i = index"
                (click)="handleAccountSelection(account)"
              >
                <span class="cat-name">{{ account.name }} </span>
              </div>
            </div>
            <div class="section-button-container">
              <button
                (click)="openAccountCreationModal($event)"
                buttonType="primary"
                bitButton
                class="estimate-account-button"
              >
                {{ "addNewAccount" | i18n }}
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="form-group mb-4">
            <label
              >{{ "direction" | i18n }}
              <span class="mat-form-field-required-marker">*</span>
              <span
                class="mat-form-field-required-marker"
                title="Weather estimate is making money or loosing money"
                >?</span
              >
            </label>
            <div class="form-control">
              <mat-select formControlName="direction" id="direction">
                <mat-option *ngFor="let item of estimateDirections" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="value-amount-container">
            <div class="form-group mb-4">
              <label
                >{{ "value" | i18n }}
                <span class="mat-form-field-required-marker">*</span>
                <span
                  class="mat-form-field-required-marker"
                  title="If the amount changes for each transaction of the estimate"
                  >?</span
                >
              </label>
              <div class="form-control">
                <mat-select
                  formControlName="valueOption"
                  id="value"
                  (selectionChange)="handleValueOptionChange()"
                >
                  <mat-option *ngFor="let item of valueOptions" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="amount-type-container">
              <div class="amount-symbol-container">
                <div class="form-group mb-4" id="amount-container">
                  <label class="input-label"
                    >{{ amountTitle | i18n }}
                    <span
                      class="mat-form-field-required-marker"
                      title="What is the amount/starting amount to generate transactions off of"
                      >?</span
                    ></label
                  >
                  <input
                    formControlName="amount"
                    autocomplete="off"
                    class="form-control"
                    type="number"
                    name="Amount"
                  />
                </div>

                <div class="form-group mb-4 symbol-container">
                  <label
                    >{{ "symbol" | i18n }}
                    <span class="mat-form-field-required-marker">*</span></label
                  >
                  <div class="form-control">
                    <mat-select formControlName="symbol" id="symbol">
                      <mat-option *ngFor="let item of symbols" [value]="item">
                        {{ item }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div
                id="recurring-variable-amount"
                class="estimate-form-right-bottom"
                *ngIf="selectedValueOption === 'variable'"
              >
                <div class="form-group mb-4 symbol-container">
                  <label
                    >{{ "calculateAs" | i18n }}
                    <span class="mat-form-field-required-marker">*</span>
                    <span
                      class="mat-form-field-required-marker"
                      title="If you choose a variable amount, then you have to choose a method to generate amount"
                      >?</span
                    >
                  </label>
                  <div class="form-control">
                    <mat-select formControlName="calculateAs" id="calculateAs">
                      <mat-option *ngFor="let item of estimateTypes" [value]="item">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="form-group mb-4 symbol-container">
          <label
            >{{ "isItRecurring" | i18n }}
            <span class="mat-form-field-required-marker">*</span>
            <span
              class="mat-form-field-required-marker"
              title="If your estimate is going to happen multiple times then you should choose 'Yes'"
              >?</span
            >
          </label>
          <div class="form-control">
            <mat-select
              formControlName="isRecurring"
              id="isRecurring"
              (valueChange)="handleEstimateRecurringChange($event)"
            >
              <mat-option *ngFor="let item of recurringValues" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>

      <div [ngClass]="{ 'recurring-part part': activeRecurringFix }">
        <div [class.recurring-part-item]="activeRecurringFix">
          <ng-container>
            <div class="form-group mb-4">
              <label
                >{{ startDateLabel | i18n }}
                <span class="mat-form-field-required-marker">*</span>
                <span
                  class="mat-form-field-required-marker"
                  title="When is the first transaction going to happen"
                  >?</span
                >
              </label>
              <input
                (click)="handleDatePicker()"
                autocomplete="off"
                class="form-control"
                type="text"
                matInput
                formControlName="startDate"
                id="startDate"
                [matDatepicker]="picker"
              />

              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </ng-container>
        </div>

        <div class="recurring-part-item" *ngIf="activeRecurringFix">
          <ng-container>
            <div class="form-group mb-4">
              <label
                >{{ "endDate" | i18n }}
                <span
                  class="mat-form-field-required-marker"
                  title="Until when should it repeat this estimate"
                  >?</span
                >
              </label>
              <input
                (click)="handleEndDatePicker()"
                autocomplete="off"
                class="form-control"
                type="text"
                matInput
                formControlName="endDate"
                id="endDate"
                [matDatepicker]="endDatePicker"
              />

              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </div>
          </ng-container>
        </div>

        <div class="recurring-part-item" *ngIf="activeRecurringFix">
          <div class="form-group mb-4">
            <label class="input-label"
              >{{ "frequency" | i18n }}
              <span class="mat-form-field-required-marker">*</span>
              <span
                class="mat-form-field-required-marker"
                title="How often does it happen giving a period ?"
                >?</span
              >
            </label>
            <input
              formControlName="frequency"
              autocomplete="off"
              class="form-control"
              type="number"
              name="Frequency"
            />
          </div>
        </div>

        <div class="recurring-part-item" *ngIf="activeRecurringFix">
          <div class="form-group mb-4">
            <label
              >{{ "period" | i18n }}
              <span class="mat-form-field-required-marker">*</span>
              <span class="mat-form-field-required-marker" title="Over what period should it repeat"
                >?</span
              >
            </label>
            <div class="form-control">
              <mat-select
                formControlName="period"
                id="period"
                (valueChange)="handlePeriodChange($event)"
              >
                <mat-option *ngFor="let item of periods" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>

        <div
          class="form-group mb-4 period-options-container"
          *ngIf="
            activeRecurringFix &&
            estimateFormGroup.controls['period'].value === EstimatePeriods.weekly
          "
        >
          <label
            >{{ "every" | i18n }}
            <span class="mat-form-field-required-marker">*</span>
            <span class="mat-form-field-required-marker" title="Which date should it repeat"
              >?</span
            >
          </label>
          <div class="form-control">
            <mat-select
              formControlName="periodDateOption"
              (valueChange)="handlePeriodOptionChange($event)"
            >
              <mat-option *ngFor="let item of periodWeeklyOptions; let i = index" [value]="i + 1">
                {{ item | i18n }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div
          class="form-group mb-4 period-options-container"
          *ngIf="
            activeRecurringFix &&
            estimateFormGroup.controls['period'].value === EstimatePeriods.monthly
          "
        >
          <label
            >{{ "every" | i18n }}
            <span class="mat-form-field-required-marker">*</span>
            <span class="mat-form-field-required-marker" title="Which date should it repeat"
              >?</span
            >
          </label>
          <div class="form-control">
            <mat-select
              formControlName="periodDateOption"
              (valueChange)="handlePeriodOptionChange($event)"
            >
              <mat-option *ngFor="let item of periodMonthlyOptions; let i = index" [value]="item">
                {{ item | estimatePipe : "getOptionForMonth" | async }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div
          class="form-group mb-4 period-options-container"
          *ngIf="
            activeRecurringFix &&
            estimateFormGroup.controls['period'].value === EstimatePeriods.yearly
          "
        >
          <label
            >{{ "every" | i18n }}
            <span class="mat-form-field-required-marker">*</span>
            <span class="mat-form-field-required-marker" title="Which date should it repeat"
              >?</span
            >
          </label>
          <div class="form-control">
            <mat-select
              formControlName="periodDateOption"
              (valueChange)="handlePeriodOptionChange($event)"
            >
              <mat-option *ngFor="let item of periodYearlyOptions; let i = index" [value]="item">
                {{ item | estimatePipe : "getOptionForYear" | async }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer right-buttons-container">
    <button mat-raised-button mat-dialog-close (click)="handleCloseDialogue()">
      {{ "cancel" | i18n }}
    </button>
    <button mat-raised-button color="primary" type="submit">
      {{ "save" | i18n }}
    </button>
  </div>
</form>
