import { Component, Input } from "@angular/core";

import { HelperTooltip } from "@bitwarden/web-vault/app/shared/utils/helper-tooltip";

@Component({
  selector: "app-tooltip-card",
  templateUrl: "./tooltip-card.component.html",
})
export class TooltipCardComponent {
  helperTooltip: HelperTooltip = new HelperTooltip();
  protected readonly event = MouseEvent;
  @Input() backgroundClass?: string;
  @Input() contentTemplate!: any; // Input to accept the ng-template
}
