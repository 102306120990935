import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/gloss/customer-support-box/customer-support-box.component";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { MainSettingsRoute } from "@bitwarden/web-vault/app/models/enum/routes.enum";
import { scopeEnum } from "@bitwarden/web-vault/app/models/scope/typeDef";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";

@Component({
  selector: "app-settings",
  templateUrl: "settings.component.html",
})
export class SettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("theSetting") theSetting: ElementRef;
  @ViewChild("theContent") theContent: ElementRef;

  private currentView: "mobile" | "web";
  protected readonly RoleScope = RoleScope;
  protected readonly MainSettingsRoute = MainSettingsRoute;
  private destroy$ = new Subject<void>();

  protected isPremiumUser: boolean;

  hasScope = false;
  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;

  constructor(
    private router: Router,
    private helperCommon: HelperCommon,
    public dialog: MatDialog,
    private messagingService: MessagingService,
    private blobbyService: BlobbyService,
    private userAccessTier: RoleAccessService
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.checkScreenSize();
  }

  async ngOnInit() {
    this.isPremiumUser = this.userAccessTier.isBetaUser();
    this.hasScope = await this.helperCommon.hasScope(scopeEnum.EARLY_ACCESS);
  }

  ngAfterViewInit() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    const width = window.innerWidth;
    const mdValue = 767; // tailwind config

    if (width <= mdValue) {
      this.currentView = "mobile";
    } else if (width > mdValue) {
      this.currentView = "web";
    }
  }

  protected handleNavClick(currentView: string, destination: string) {
    if (currentView == this.currentView) {
      return this.getSettingsRoute(destination);
    }
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }

  lock() {
    this.messagingService.send("lockVault");
  }

  logOut() {
    this.blobbyService.setInitialised(false);
    this.messagingService.send("logout");

    sessionStorage.removeItem("basiqUserAccessToken"); // So that if another user logs in, the previous user's access token is not used
  }

  protected getSettingsRoute(destination = "") {
    return destination === "access"
      ? [`/settings/${destination}`, { queryParams: { isPremiumUser: this.isPremiumUser } }]
      : [`/settings/${destination}`];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
