import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { GlossRoutes } from "../../../../apps/ironfly-web/src/app/models/enum/routes.enum";

@Injectable({
  providedIn: "root",
})
export class InitialRedirectGuard {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const initialRoute = "/" + GlossRoutes.Views;

    if (state.url !== initialRoute && !state.url.includes("jobId")) {
      this.router.navigate([initialRoute]).then((r) => r);
      return false;
    }
    return true;
  }
}
