import { Component, Input } from "@angular/core";

import { BestScenarioCarouselItem } from "@bitwarden/web-vault/app/models/types/carousel.types";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
})
export class CarouselComponent {
  /** @Sinan update the type of items from any to something useful */
  @Input() items: BestScenarioCarouselItem[] = [];

  activeIndex = 0;
  startX = 0;
  currentX = 0;

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
    this.currentX = this.startX;
  }

  onTouchMove(event: TouchEvent) {
    this.currentX = event.touches[0].clientX;
  }

  onTouchEnd() {
    const deltaX = this.currentX - this.startX;

    if (deltaX > 0) {
      this.onSwipeRight();
    }

    if (deltaX < 0) {
      this.onSwipeLeft();
    }
  }

  onSwipeLeft() {
    if (this.activeIndex === this.items.length - 1) {
      return;
    }
    this.setActiveIndex(this.activeIndex + 1);
  }

  onSwipeRight() {
    if (this.activeIndex === 0) {
      return;
    }
    this.setActiveIndex(this.activeIndex - 1);
  }
}
