import { Injector } from "@angular/core";

import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { DateStartPreferences } from "@bitwarden/web-vault/app/models/types/balance.types";
import { dayGranularity } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";

export class BalanceGroupingTools {
  /**
   * getAccountCurrencyBalanceByDate - Given an accountID, the required currency and date,
   *                                   return the balance number for that currency from this balance grouping.
   * @param balanceGrouping
   * @param accountID
   * @param currency
   * @param balanceDate
   *
   **/
  getAccountCurrencyBalanceByDate(
    balanceGrouping: BalanceGrouping,
    accountID: string,
    currency: string,
    balanceDate: Date
  ): number {
    if (!balanceGrouping) {
      return 0;
    }

    if (balanceGrouping.dateEnd && balanceDate > balanceGrouping.dateEnd) {
      balanceDate = balanceGrouping.dateEnd;
    }

    const dayKey = balanceGrouping.getDateGroupingKey(balanceDate, dayGranularity);

    if (!balanceGrouping.granularity?.[dayGranularity]?.[dayKey]) {
      return 0;
    }

    const groupingNode = balanceGrouping.granularity[dayGranularity][dayKey];

    if (!groupingNode?.children?.account?.[accountID]?.balance) {
      return 0;
    }

    const accountBalance = groupingNode.children.account[accountID].balance;

    const symbolBalance = accountBalance.getRunningTotalSymbol(currency);

    if (!symbolBalance) {
      return 0;
    }

    return symbolBalance.symbolAmount.amount;
  }

  /**
   * getCurrencyBalanceByDate - Given required currency and date,
   *                            return the balance number for that currency from this balance grouping.
   * @param balanceGrouping
   * @param currency
   * @param balanceDate
   *
   **/
  getCurrencyBalanceByDate(
    balanceGrouping: BalanceGrouping,
    currency: string,
    balanceDate: Date
  ): number {
    if (!balanceGrouping) {
      return 0;
    }

    if (balanceGrouping.dateEnd && balanceDate > balanceGrouping.dateEnd) {
      balanceDate = balanceGrouping.dateEnd;
    }

    const dayKey = balanceGrouping.getDateGroupingKey(balanceDate, dayGranularity);

    if (!balanceGrouping.granularity?.[dayGranularity]?.[dayKey]) {
      return 0;
    }

    const groupingNode = balanceGrouping.granularity[dayGranularity][dayKey];

    const symbolBalance = groupingNode.balance.getActualAmountOfSymbol(currency);

    if (!symbolBalance) {
      return 0;
    }

    return symbolBalance;
  }

  /**
   * getDatePreferences - Get the date preferences from the preference service to be used
   *                     by the balance grouping web worker.
   * @param injector
   */
  async getDatePreferences(injector: Injector): Promise<DateStartPreferences> {
    const dateStartPreference: DateStartPreferences = {};
    const preferenceService = injector.get(PreferenceService);
    if (preferenceService) {
      const weekStartDay = await preferenceService.getWeekDayStartAsNumber();
      if (weekStartDay) {
        dateStartPreference.weekStartDay = weekStartDay;
      }
      const yearMonthStart = <number>await preferenceService.getYearMonthStartAsNumber();
      if (yearMonthStart) {
        dateStartPreference.yearMonthStart = yearMonthStart;
      }
      const monthStartDay = <number>await preferenceService.get(PreferenceType.monthDayStart);
      if (monthStartDay) {
        dateStartPreference.monthStartDay = monthStartDay;
      }
    }
    return dateStartPreference;
  }
}
