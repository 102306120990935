import { Component, Input } from "@angular/core";

import { Step } from "@bitwarden/web-vault/app/models/types/stepper.types";

@Component({
  selector: "app-step-indicator",
  templateUrl: "./step-indicator.component.html",
})
export class StepIndicatorComponent {
  @Input() steps: Step[] = [];
  @Input() currentIndex = 1;
}
