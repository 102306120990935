import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BestScenarioStepper } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-stepper";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { StepperActionsEnum } from "@bitwarden/web-vault/app/models/enum/stepper.enum";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { StepperAction } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-fulfill-conditions",
  templateUrl: "./fulfill-conditions.component.html",
})
export class FulfillConditionsComponent extends BestScenarioStepper implements OnInit, OnDestroy {
  @Output() onAction = new EventEmitter<StepperAction>();
  private destroy$ = new Subject<void>();
  private dashboardService: DashboardService;

  earningsCard: EarningDataCard;
  action = StepperActionsEnum.viewOtherAccounts;

  viewOtherAccounts = new ActionButton({
    text: this.i18n.t("viewOtherAccounts"),
    type: "secondary",
    class: "tw-bg-neutral-50",
    testId: "viewOtherAccountsInPath3Stepper",
    onClick: () => this.onViewOtherAccounts(),
  });
  nextBtnOptions = new ActionButton({
    text: this.i18n.t("complete"),
    class: "primary",
    testId: "stepperNextButton",
  });

  prevBtnOptions = new ActionButton({
    text: this.i18n.t("continueToNextStep"),
    class: "secondary",
    testId: "stepperPrevButton",
  });

  constructor(injector: Injector) {
    super(injector);
    this.dashboardService = injector.get(DashboardService);
  }

  ngOnInit() {
    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = scenarioData;
      if (this.scenarioData) {
        this.setUpStep().then(() => {
          this.updateData();
        });
      }
    });
  }

  updateData() {
    this.earningsCard = {
      header: this.i18n.t("toEarn"),
      earningAmount: this.getEarningAmount(),
      headerDescription: `${this.rateInUse.rate}% P.A. interest`,
      currency: this.helpInfo.symbol,
      footerDescription: this.getDescription(),
    };
  }

  getDescription(): string {
    /** @Sinan check out for how to get this message: from message.json?  */
    return "In the next 12 months";
  }

  onViewOtherAccounts() {
    this.onAction.emit(StepperActionsEnum.viewOtherAccounts);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
