<div
  class="tw-relative tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-lg tw-p-3"
  [ngClass]="backgroundClass ? backgroundClass : 'tw-bg-neutral-50'"
>
  <ng-content select="[main-icon]"></ng-content>

  <div class="tw-w-[90%]">
    <ng-content select="[title]"></ng-content>
  </div>

  <svg
    class="tw-right-2 tw-h-7 tw-w-7 tw-text-neutral-600 xs:tw-h-5 xs:tw-w-5"
    (click)="helperTooltip.toggle($event)"
  >
    <use [attr.xlink:href]="'images/sprite.svg#info-tooltip'"></use>
  </svg>

  <div *ngIf="helperTooltip.showHelpTooltip" class="tool-tip-component-container">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
