import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatSelect, MatSelectChange } from "@angular/material/select";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { removeSpace } from "@bitwarden/web-vault/app/shared/utils/helper-string";
import {
  BalanceActionEnum,
  BookBalance,
} from "@bitwarden/web-vault/app/shared/utils/helper.book/balance";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

export type statementLinkedAccountWithBalances = {
  account: AccountView;
  baseCurrency: string;
  openingBalance: string;
  closingBalance: string;
};

@Component({
  selector: "app-add-balance",
  templateUrl: "./add-balance.component.html",
})
export class AddBalanceComponent implements OnInit {
  openingBalanceErrorText: string;
  closingBalanceErrorText: string;
  fromDateText = "";
  toDateText = "";
  accountViews: AccountView[] = [];
  accountInAction: AccountView;
  linkedAccounts: AccountView[] = [];
  linkedAccountsDisplay: {
    label: string;
    templateData: AccountView;
  }[] = [];
  _mOpeningBalances = new Map<string, BookBalance>();
  _mClosingBalances = new Map<string, BookBalance>();
  fileContent: string | ArrayBuffer | null = null;
  openingBalance: string;
  closingBalance: string;
  isResetOpeningForm = false;
  isResetClosingForm = false;
  baseCurrency: string;
  linkedAccountsWithBalances: statementLinkedAccountWithBalances[] = [];
  globalService: GlobalService;
  preferenceService: PreferenceService;
  bookService: BookService;
  formGroup: FormGroup;
  formBuilder: FormBuilder;
  dateFormat = new DateFormat();

  @ViewChild("accountSelect") accountSelect: MatSelect;
  @ViewChild("fromPicker") fromPicker: MatDatepicker<Date>;
  @ViewChild("toPicker") toPicker: MatDatepicker<Date>;
  @ViewChild("openingBalanceInput") openingBalanceInput: ElementRef;
  @ViewChild("closingBalanceInput") closingBalanceInput: ElementRef;
  @ViewChild("accountDisplayTemplate", { static: true }) accountDisplayTemplate: TemplateRef<any>;

  @Output() outputLinkedAccounts = new EventEmitter<statementLinkedAccountWithBalances[]>();
  @Output() outputFileData = new EventEmitter<CabinetFileUploadedData>();
  @Input() selectedFile: File;

  // todo @Brian separate form logic from component
  constructor(private injector: Injector) {
    this.bookService = this.injector.get(BookService);
    this.preferenceService = this.injector.get(PreferenceService);
    this.globalService = this.injector.get(GlobalService);
    this.formBuilder = injector.get(FormBuilder);
    const preference = this.preferenceService.getPreferenceInstance();
    this.baseCurrency = preference.currency || GLOBAL_BASE_CURRENCY;
    this.formGroup = this.formBuilder.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.accountViews = await this.bookService.getBooksView();
  }

  private isFromDateAfterToDate(): boolean {
    return new Date(this.toDateText) < new Date(this.fromDateText);
  }

  isAccountLinked(account: AccountView): boolean {
    return this.linkedAccounts.some((linkedAccount) => linkedAccount.id === account.id);
  }

  onDateChange(date: string, event: string) {
    this.formGroup.get(event).setValue(date);
    this.formGroup.get(event).markAsTouched();
    if (event === "fromDate") {
      this.fromDateText = date;
    } else {
      this.toDateText = date;
    }
  }

  accountSelected(event: MatSelectChange) {
    this.accountInAction = event.value as AccountView;
    this.resetBalanceInputs();
  }

  resetBalanceInputs() {
    this.openingBalance = "";
    this.closingBalance = "";
    this.openingBalanceInput.nativeElement.value = "";
    this.closingBalanceInput.nativeElement.value = "";
  }

  resetAccountSelection() {
    this.accountInAction = null;
    this.accountSelect.value = null;
    this.resetBalanceInputs();
    this.closingBalanceErrorText = "";
    this.openingBalanceErrorText = "";
  }

  async linkSelectedAccount() {
    this.formGroup.markAllAsTouched();
    if (!this.accountInAction) {
      this.globalService.showMessage("warning", "emptyAccount", "selectAccount");
      return;
    }
    const isLinked = this.linkedAccounts.some((account) => account.id === this.accountInAction.id);

    if (!this.openingBalance || !this.closingBalance) {
      this.closingBalanceErrorText = "inputRequired";
      this.openingBalanceErrorText = "inputRequired";
      return;
    }

    if (this.fromDateText && this.toDateText) {
      if (!isLinked) {
        this.linkedAccounts.push(this.accountInAction);
        this.linkedAccountsDisplay.push({
          label: `${this.accountInAction.name}`,
          templateData: this.accountInAction,
        });
      }

      await this.setBalanceForm();

      this.addDataIntoLinkedAccountsWithBalances(this.accountInAction);

      if (this.isResetOpeningForm && this.isResetClosingForm) {
        this.resetAccountSelection();
      }
    } else {
      this.globalService.showMessage("warning", "emptyDate", "selectDates");
    }
  }

  addDataIntoLinkedAccountsWithBalances(account: AccountView) {
    const itemIndex = this.linkedAccountsWithBalances.findIndex(
      (acc) => acc.account.id === account.id,
    );
    const linkedAccountData: statementLinkedAccountWithBalances = {
      account: this.accountInAction,
      baseCurrency: this.baseCurrency,
      openingBalance:
        this._mOpeningBalances.get(this.accountInAction.id)?.balanceForm?.balance ?? "0",
      closingBalance:
        this._mClosingBalances.get(this.accountInAction.id)?.balanceForm?.balance ?? "0",
    };
    if (itemIndex >= 0) {
      this.linkedAccountsWithBalances[itemIndex] = linkedAccountData;
    } else if (itemIndex < 0) {
      this.linkedAccountsWithBalances.push(linkedAccountData);
    }
  }

  async setBalanceForm() {
    const openingBalanceForm: BalanceForm = {
      balance: this.openingBalance,
      currency: this.baseCurrency,
      date: this.fromDateText,
      transactionStatus: TransactionStatusEnum.manual_balance,
      type: "Opening",
    };

    const closingBalanceForm: BalanceForm = {
      balance: this.closingBalance,
      currency: this.baseCurrency,
      date: this.toDateText,
      transactionStatus: TransactionStatusEnum.manual_balance,
      type: "Closing",
    };

    if (!removeSpace(this.openingBalance) || !removeSpace(this.fromDateText)) {
      this._mOpeningBalances.set(this.accountInAction.id, null);
    } else {
      const openingBalance = new BookBalance(
        this.accountInAction,
        openingBalanceForm,
        this.injector,
      );
      const isOpeningReady = await openingBalance.isReady();
      this.isResetOpeningForm = !(
        openingBalance.isSameBalanceSameDate &&
        openingBalance.action !== BalanceActionEnum.keep_existing
      );
      if (isOpeningReady) {
        this._mOpeningBalances.set(this.accountInAction.id, openingBalance);
      }
    }

    if (!removeSpace(this.closingBalance) || !removeSpace(this.toDateText)) {
      this._mClosingBalances.set(this.accountInAction.id, null);
    } else {
      const closingBalance = new BookBalance(
        this.accountInAction,
        closingBalanceForm,
        this.injector,
      );
      const isClosingReady = await closingBalance.isReady();
      this.isResetClosingForm = !(
        closingBalance.isSameBalanceSameDate &&
        closingBalance.action !== BalanceActionEnum.keep_existing
      );
      if (isClosingReady) {
        this._mClosingBalances.set(this.accountInAction.id, closingBalance);
      }
    }
  }

  receivedEditAccount(account: AccountView) {
    this.accountSelect.value = account;
    this.accountInAction = account;
    this.openingBalance = this._mOpeningBalances.get(account.id)?.balanceForm?.balance || "";
    this.closingBalance = this._mClosingBalances.get(account.id)?.balanceForm?.balance || "";
    this.openingBalanceInput.nativeElement.value = this.openingBalance;
    this.closingBalanceInput.nativeElement.value = this.closingBalance;
  }

  receivedRemoveAccount(account: AccountView) {
    if (this.accountInAction?.id === account.id) {
      this.resetAccountSelection();
    }
    this.linkedAccounts = this.linkedAccounts.filter((acc) => acc.id !== account.id);
    this.linkedAccountsDisplay = this.linkedAccountsDisplay.filter(
      (displayItem) => displayItem.templateData.id !== account.id,
    );
  }

  openingBalanceChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/e/gi, "");
    this.openingBalance = inputElement.value;
  }

  closingBalanceChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.closingBalance = inputElement.value;
  }

  async sendBalanceData() {
    const data: CabinetFileUploadedData = {
      name: this.selectedFile?.name,
      mimeType: this.selectedFile?.type,
      statementToDate: this.toDateText,
      statementFromDate: this.fromDateText,
      statementAccounts: this.linkedAccounts,
      openingBalances: this._mOpeningBalances,
      closingBalances: this._mClosingBalances,
      file: this.selectedFile,
    };

    if (this.accountInAction || this.linkedAccounts.length === 0) {
      await this.linkSelectedAccount();
    }
    if (!this.openingBalanceErrorText && !this.closingBalanceErrorText) {
      this.outputLinkedAccounts.emit(this.linkedAccountsWithBalances);
      this.outputFileData.emit(data);
    }
  }
}
