import { Component, Input } from "@angular/core";

@Component({
  selector: "app-best-account-tooltip",
  templateUrl: "./best-account-tooltip.component.html",
})
export class BestAccountTooltipComponent {
  @Input() lastViewDate: string;
  @Input() title: string;
  @Input() backgroundClass?: string;
}
