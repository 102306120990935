import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "logo-header",
  templateUrl: "./logo-header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoHeaderComponent {
  @Input() text: string;
}
