import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import {
  accountCreation,
  InstitutionAccountGroup,
  interestCreation,
  transactionTransfer,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  EstimateActionProperties,
  ScenarioHelpInfo,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { InterestRateScenarioOptionUtils } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option.utils";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

export class BestNewInterestRate extends InterestRateScenarioOptionUtils {
  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    this.permutations = [];

    if (!this.hasPositiveAccounts) {
      return;
    }

    const symbol = this.scenarioGroup.symbol;
    if (this.startingAccountBalances) {
      const dataRepositoryService = this.injector.get(DataRepositoryService);
      const possibleAccounts = await dataRepositoryService.getAllScenarioAccounts();

      // get all the existing institutionalAccountIDs
      const existingInstitutionalAccounts = await this.getExistingInstitutionalAccounts(
        possibleAccounts
      );
      const { newInstitutionalAccounts, institutionsMap } = await this.getNewInstitutionalAccounts(
        existingInstitutionalAccounts
      );
      for (const newInstitutionalAccount of newInstitutionalAccounts) {
        const scenarioPermutation: ScenarioPermutation = { estimateActions: [] };
        const helpInfo: ScenarioHelpInfo = { symbol };

        // create a mock account of the new institutionalAccount
        const accountCreationParameters = this.createAccountCreationParameters(
          institutionsMap.get(newInstitutionalAccount.id),
          newInstitutionalAccount,
          symbol
        );

        const permutation: EstimateActionProperties = {
          parameters: accountCreationParameters,
          estimateActionType: accountCreation,
        };
        scenarioPermutation.estimateActions.push(permutation);

        // check for the account type being one with interest rates
        const interestRates = newInstitutionalAccount.interestRates;
        const toAccountUrl: string = newInstitutionalAccount.link;
        const toInstitutionName: string = newInstitutionalAccount.institutionName;
        if (interestRates && interestRates.length > 0) {
          for (const oldAccountID in this.startingAccountBalances) {
            const oldAccount = await dataRepositoryService.getBookById(oldAccountID);
            const oldAccountSymbolValue = this.getSymbolValue(oldAccountID, symbol);
            const creditAccount = this.isCredit(oldAccount);
            const fromInterestRate = await this.getInterestRates(oldAccount, symbol);
            const fromAccountUrl: string = await this.getAccountUrl(oldAccount);
            const fromInstitutionName: string = await this.getInstitutionName(oldAccount);

            // create a transfer estimate action to move the symbol to the new account
            if (oldAccount && !creditAccount && oldAccountSymbolValue > 0) {
              const transferParameters = this.createTransferParameters(
                oldAccount,
                null, // account hasn't been created it. Need to get it from createdRecords
                oldAccountSymbolValue,
                symbol
              );
              await this.addToHelpFromTransferParameters(
                helpInfo,
                transferParameters,
                interestRates,
                fromInterestRate,
                toAccountUrl,
                fromAccountUrl,
                toInstitutionName,
                fromInstitutionName,
                newInstitutionalAccount.name
              );
              const permutation: EstimateActionProperties = {
                parameters: transferParameters,
                estimateActionType: transactionTransfer,
              };
              scenarioPermutation.estimateActions.push(permutation);
            }
          }

          // create an interest estimate action to create interest transactions
          const interestParameters = this.createInterestParameters(null, interestRates);
          const permutation: EstimateActionProperties = {
            parameters: interestParameters,
            estimateActionType: interestCreation,
          };
          scenarioPermutation.estimateActions.push(permutation);
        }
        if (scenarioPermutation.estimateActions.length > 0) {
          scenarioPermutation.scenarioHelpInfo = helpInfo;
          this.permutations.push(scenarioPermutation);
        }
      }
    }
  }

  private async getExistingInstitutionalAccounts(
    existingAccounts: Array<Book>
  ): Promise<Array<string>> {
    const institutionalAccountNames: Array<string> = [];
    for (const account of existingAccounts) {
      const institutionService = this.injector.get(InstitutionService);
      if (account?.institutionLink?.institutionId) {
        const institutionalAccount = await institutionService.getAccountType(account);
        if (institutionalAccount) {
          institutionalAccountNames.push(institutionalAccount.name);
        }
      }
    }
    return institutionalAccountNames;
  }

  private async getNewInstitutionalAccounts(existingAccounts: Array<string>) {
    const institutionAccountGroup: InstitutionAccountGroup = {
      institutionsMap: new Map(),
      newInstitutionalAccounts: [],
    };
    const institutionService = this.injector.get(InstitutionService);
    const institutions = await institutionService.getInstitutionsMasterList();

    if (institutions !== null && Array.isArray(institutions)) {
      for (const institution of institutions) {
        const institutionName = institution.name;
        for (const institutionalAccount of institution.availableAccounts) {
          if (institutionalAccount.useForScenarioBestRate) {
            // TODO: not sure that this works yet because insitution API isn't working and all the ones returned are created
            // at run time. Double check logic and uncomment this line when institution API is working.
            // TODO : @Sinan@Michelle previously it was ruling out the existing accounts . But weather user have it or not we should get the best rate.
            const institutionalAccountAndName = { institutionName, ...institutionalAccount };
            institutionAccountGroup.newInstitutionalAccounts.push(institutionalAccountAndName);
            institutionAccountGroup.institutionsMap.set(institutionalAccount.id, institution);
          }
        }
      }
    }
    return institutionAccountGroup;
  }
}
