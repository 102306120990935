import { EstimateActionWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/estimate-actions/estimate-action.worker.message";
import { WebWorkerImplementation } from "@bitwarden/web-vault/web-worker/web.worker.implementation";

export class EstimateActionWorker extends WebWorkerImplementation<EstimateActionWorkerMessage> {
  _callback: (data: any) => void;
  _workerType = "EstimateActionWorker";

  protected constructor() {
    // also this can be thread_worker in Node.js environment
    const worker = new Worker(new URL("./estimate-action.job.ts", import.meta.url), {
      type: "module",
    });
    super(worker);

    this.addEventListener("message", this.onMessageFromWorker.bind(this));
  }

  get callback(): (data: any) => void {
    return this._callback;
  }

  set callback(callback: (data: any) => void) {
    this._callback = callback;
  }

  static getInstance(): EstimateActionWorker {
    return new EstimateActionWorker();
  }

  async runEstimateAction(message: EstimateActionWorkerMessage, callback: (data: any) => void) {
    this.callback = callback;
    this.do(message);
  }

  /**
   * function to handle the message back from the worker service
   * @param message
   */
  onMessageFromWorker(message: MessageEvent): void {
    if (message?.data?.data?.complete && this.callback) {
      this.callback(message.data);
    }
  }
}
