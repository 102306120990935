<div
  class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full md:tw-w-full md:tw-p-4"
>
  <div class="tw-flex tw-flex-col tw-gap-6">
    <app-gloss-callout title="dangerZone" body="dangerZoneDesc"></app-gloss-callout>

    <ng-container *ngFor="let item of contextList">
      <ng-container
        *ngTemplateOutlet="
          advancedFeaturesButtons;
          context: {
            roleScope: item.roleScope,
            buttonText: item.buttonText,
            buttonOptions: item.buttonOptions
          }
        "
      >
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template
  #advancedFeaturesButtons
  let-roleScope="roleScope"
  let-buttonText="buttonText"
  let-buttonOptions="buttonOptions"
>
  <div *appRoleScope="roleScope" class="tw-w-[190px] md:tw-w-full">
    <div class="tw-mb-2.5 tw-whitespace-nowrap tw-font-medium">{{ buttonText | i18n }}</div>
    <app-gloss-button [options]="buttonOptions"></app-gloss-button>
  </div>
</ng-template>
