import { Injectable, signal, WritableSignal } from "@angular/core";

import { BaseProgressBar } from "../../components/progress-bar/base-progress-bar";
import { IMainProgressBar } from "../../models/interfaces/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class MainProgressBar extends BaseProgressBar implements IMainProgressBar {
  static PROGRESS_LABEL_LOADING = "Loading...";
  private progressLabel: WritableSignal<string> = signal(MainProgressBar.PROGRESS_LABEL_LOADING);
  private loadingPercentage: WritableSignal<number> = signal(0);
  private isLoadingComplete: WritableSignal<boolean> = signal(false);
  private scenarioIndex: WritableSignal<number> = signal(0);

  constructor() {
    super();
  }

  setProgressLabel(label: string) {
    this.progressLabel.set(label);
  }

  getProgressLabel() {
    return this.progressLabel();
  }

  setLoadingPercentage(percentage: number) {
    this.loadingPercentage.set(percentage);
  }

  getLoadingPercentage() {
    return this.loadingPercentage();
  }

  setIsLoadingCompleted(isLoadingCompleted: boolean) {
    this.isLoadingComplete.set(isLoadingCompleted);
  }

  setScenarioIndex(index: number) {
    this.scenarioIndex.set(index + 1);
  }

  getScenarioIndex() {
    return this.scenarioIndex();
  }

  isLoadingCompleted() {
    try {
      return this.isLoadingComplete();
    } catch (error) {
      this.setIsLoadingCompleted(true);
    }
  }

  reset() {
    this.setIsLoadingCompleted(false);
    this.setProgressLabel(MainProgressBar.PROGRESS_LABEL_LOADING);
    this.setLoadingPercentage(0);
    this.setScenarioIndex(0);
  }
}
