<div class="carousel-item-container tw-h-full tw-overflow-y-auto">
  <app-best-account-tooltip
    title
    [backgroundClass]="'tw-bg-white'"
    [lastViewDate]="rateInUse.last_updated"
  >
    <app-best-scenario-dialog-title>
      {{ accountName }}
    </app-best-scenario-dialog-title>

    <app-best-scenario-dialog-title [isPrimary]="false">
      <span class="tw-text-sm">{{ rateInUse.rate }}% P.A.</span>
    </app-best-scenario-dialog-title>
  </app-best-account-tooltip>

  <ng-container *ngIf="earningsCard">
    <best-scenario-carousel-earnings-card [earningsData]="earningsCard">
    </best-scenario-carousel-earnings-card>
  </ng-container>

  <div *ngIf="rateInUse" class="section-gap">
    <span class="section-header">{{ "conditions" | i18n }}</span>
    <div class="accounts-container">
      <app-carousel-rate-condition
        [condition]="rateInUse.condition_intrinsic_desc"
      ></app-carousel-rate-condition>

      <app-carousel-rate-condition
        [condition]="rateInUse.condition_action_desc"
      ></app-carousel-rate-condition>
    </div>
  </div>
</div>
