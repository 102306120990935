<div *ngIf="!isStripeLoaded" class="mt-5 d-flex justify-content-center">
  <div>
    <img class="logo tw-mb-4" alt="Gloss" />
    <p class="tw-text-center">
      <i
        class="bwi bwi-spinner bwi-spin bwi-2x tw-text-muted"
        title="Loading"
        aria-hidden="true"
      ></i>
    </p>
  </div>
</div>
<ng-container *ngIf="isStripeLoaded">
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-px-8 tw-pt-8 xs:tw-px-2"
  >
    <logo-header [text]="'createAccount' | i18n"></logo-header>
    <div
      class="tw-min-w-xl tw-border-secondary-300 tw-mx-auto tw-max-w-xl tw-rounded-3xl tw-bg-background tw-p-8"
    >
      <form
        #form
        [appApiAction]="formPromise"
        class="tw-container tw-mx-auto"
        [formGroup]="formGroup"
      >
        <div class="tw-flex tw-flex-col">
          <div class="">
            <bit-form-field>
              <bit-label>{{ "emailAddress" | i18n }}</bit-label>
              <input
                id="register-form_input_email"
                bitInput
                type="email"
                formControlName="email"
                placeholder="{{ 'enterEmailAddress' | i18n }}"
              />
              <bit-hint>{{ "emailAddressDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div class="">
            <bit-form-field>
              <bit-label>{{ "name" | i18n }}</bit-label>
              <input
                id="register-form_input_name"
                bitInput
                type="text"
                formControlName="name"
                placeholder="{{ 'enterPreferredName' | i18n }}"
              />
              <bit-hint>{{ "yourNameDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div class="tw-mb-6">
            <app-callout
              type="info"
              [enforcedPolicyOptions]="enforcedPolicyOptions"
              *ngIf="enforcedPolicyOptions"
            >
            </app-callout>
            <bit-form-field class="!tw-mb-1">
              <bit-label>{{ "masterPass" | i18n }}</bit-label>
              <input
                id="register-form_input_master-password"
                bitInput
                type="password"
                formControlName="masterPassword"
                placeholder="{{ 'enterPassword' | i18n }}"
                class=""
              />
              <button
                type="button"
                bitSuffix
                bitPasswordInputToggle
                [(toggled)]="showPassword"
                class="tw-h-full tw-bg-transparent"
              >
                <svg class="tw-h-6 tw-w-6 tw-opacity-60">
                  <use
                    [attr.xlink:href]="'images/sprite.svg#' + (showPassword ? 'eye' : 'eye-off')"
                  ></use>
                </svg>
              </button>
              <bit-hint>
                <span class="tw-font-semibold">Important:</span>
                {{ "masterPassImportant" | i18n }}
                <span (click)="tooltip.show($event)" class="tw-cursor-pointer tw-text-accent">{{
                  "learnMore" | i18n
                }}</span>
              </bit-hint>
            </bit-form-field>
            <app-help-tooltip
              [showHelpTooltip]="tooltip.showHelpTooltip"
              [tooltipInfo]="'content'"
              [shouldRenderContent]="true"
              [pointer]="'right'"
              (onTooltipClose)="tooltip.close()"
            >
              <ng-container *ngTemplateOutlet="importantNotePassword"></ng-container>
            </app-help-tooltip>
            <app-password-strength
              [password]="formGroup.get('masterPassword')?.value"
              [email]="formGroup.get('email')?.value"
              [name]="formGroup.get('name')?.value"
              [showText]="true"
              (passwordStrengthResult)="getStrengthResult($event)"
            >
            </app-password-strength>
          </div>
          <div class="">
            <bit-form-field>
              <bit-label>{{ "reTypeMasterPass" | i18n }}</bit-label>
              <input
                id="register-form_input_confirm-master-password"
                bitInput
                type="password"
                formControlName="confirmMasterPassword"
                placeholder="{{ 'retypePassword' | i18n }}"
              />
              <button
                type="button"
                bitSuffix
                bitPasswordInputToggle
                [(toggled)]="showPassword"
                class="tw-h-full tw-bg-transparent"
              >
                <svg class="tw-h-6 tw-w-6 tw-opacity-60">
                  <use
                    [attr.xlink:href]="'images/sprite.svg#' + (showPassword ? 'eye' : 'eye-off')"
                  ></use>
                </svg>
              </button>
            </bit-form-field>
          </div>

          <div class="">
            <bit-form-field>
              <bit-label>{{ "masterPassHint" | i18n }}</bit-label>
              <input
                id="register-form_input_hint"
                bitInput
                type="text"
                formControlName="hint"
                placeholder="{{ 'enterPasswordHint' | i18n }}"
              />
              <bit-hint>{{ "masterPassHintDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>

          <div [hidden]="!showCaptcha()">
            <iframe id="hcaptcha_iframe" height="80"></iframe>
          </div>

          <ng-container *ngIf="!accountCreated">
            <app-gloss-button
              class="tw-w-full"
              [options]="{
                type: 'submit',
                class: 'primary',
                text: 'createAccount' | i18n,
                onClick: submit.bind(this),
                isEnabled: !form.loading
              }"
            >
            </app-gloss-button>
          </ng-container>
          <bit-error-summary *ngIf="showErrorSummary" [formGroup]="formGroup"></bit-error-summary>
        </div>
      </form>
    </div>

    <div class="tw-min-w-xl tw-mx-auto tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-4">
      <div class="tw-px-4 tw-py-2 tw-text-center sm:tw-flex-col">
        {{ "policyConfirmation" | i18n }}
        <a
          href="https://www.gloss-vault.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          class="tw-underline tw-underline-offset-2"
        >
          {{ "privacyPolicyLink" | i18n }}
        </a>
      </div>
    </div>

    <ng-template #notificationPassword>
      <div class="tw-rounded-lg tw-bg-white">
        <div class="tw-flex tw-justify-end">
          <button mat-icon-button mat-dialog-close aria-label="Close dialog" tabindex="-1">
            <svg class="tw-h-4 tw-w-4">
              <use [attr.xlink:href]="'images/sprite.svg#close'" />
            </svg>
          </button>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-5 tw-px-8 tw-pb-8">
          <ng-container *ngTemplateOutlet="importantNotePassword"></ng-container>
          <div class="tw-flex tw-gap-2 xs:tw-flex-col">
            <app-gloss-button
              class="button-resize"
              [options]="{
                type: 'submit',
                class: 'primary',
                text: 'confirmSignUp' | i18n,
                onClick: submitStart.bind(this),
                isEnabled: true
              }"
            >
            </app-gloss-button>
            <app-gloss-button
              class="button-resize"
              [options]="{
                class: 'neutral',
                text: 'setDifferentPassword' | i18n,
                onClick: resetPassword.bind(this),
                isEnabled: true
              }"
            >
            </app-gloss-button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #importantNotePassword>
  <div
    class="tw-flex tw-items-start tw-justify-center tw-gap-2 xs:tw-items-end sm:tw-justify-start sm:tw-gap-0"
  >
    <svg class="tw-mr-3 tw-h-7 tw-w-7">
      <use xlink:href="images/sprite.svg#info" />
    </svg>
    <span class="tw-text-xl tw-font-extrabold xs:tw-text-base">
      {{ "importantNote" | i18n }}
    </span>
  </div>
  <p>
    {{ "importantNotePasswordDesc" | i18n
    }}<span class="tw-text-accent"> {{ "cannotResetAccount" | i18n }} </span><br /><br />
    {{ "importantNotePasswordDesc2" | i18n }}<br /><br />
    {{ "importantNotePasswordDesc3" | i18n }}
  </p>
</ng-template>
