import { Injector } from "@angular/core";

import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { DateStartPreferences } from "@bitwarden/web-vault/app/models/types/balance.types";
import { BalanceByAccounts } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import {
  CreatedRecords,
  EstimateActionProperties,
  ScenarioOptionWinner,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BalanceGroupingTools } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGroupingTools";
import { TransactionBalanceHelpers } from "@bitwarden/web-vault/app/services/DataCalculationService/transactionBalances/transactionBalanceHelpers";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { EstimateActionWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/estimate-actions/estimate-action.worker.message";

export class ScenarioOption {
  injector: Injector;
  permutations: Array<ScenarioPermutation>;
  winner: ScenarioOptionWinner;

  userEstimateTransactions: Array<Transaction>;
  startingTransactions: Array<Transaction>;
  startingAccountBalances: BalanceByAccounts;
  runningAccountBalances: BalanceByAccounts;
  groupedBalance: BalanceGrouping;

  defaultSplitClassification: SplitClassificationType[];
  defaultSplitCategory: SplitCategoryType[];
  baseCurrency: string;
  referenceData: ReferenceData[];
  datePreferences: DateStartPreferences;

  scenarioGroup: ScenarioGroup;
  startDate: Date;
  endDate: Date;
  userSymbols: string[];
  completedPemutations: number;
  totalPermutations: number;

  webWorkerQueue: WebWorkerQueue;
  callback: (winner: ScenarioOptionWinner) => void;
  completePermutation: () => void;

  constructor(
    scenarioGroup: ScenarioGroup,
    injector: Injector,
    webWorkerQueue: WebWorkerQueue,
    startingTransactions?: Array<Transaction>
  ) {
    this.scenarioGroup = scenarioGroup;
    this.startDate = new Date(scenarioGroup.anchorPoint.date);
    this.endDate = new Date(scenarioGroup.endDate.date);
    this.injector = injector;
    this.webWorkerQueue = webWorkerQueue;

    if (startingTransactions) {
      this.startingTransactions = startingTransactions;
    }
    if (this.scenarioGroup?.startingBalances) {
      this.startingAccountBalances = this.scenarioGroup.startingBalances;
      this.runningAccountBalances = this.scenarioGroup.startingBalances;
    }
  }

  async beforeRunningPermutationsHook() {
    if (!this.startingTransactions) {
      const transactionBalanceHelpers = new TransactionBalanceHelpers();
      this.startingTransactions =
        await transactionBalanceHelpers.createStartingTransactionsFromBalance(
          this.startDate,
          this.startingAccountBalances,
          "Starting Transactions at Anchor Point",
          this.injector
        );
    }
    await this.setDefaultSplitClassification();
    await this.setDefaultSplitCategory();
    await this.setBaseCurrency();
    await this.setReferenceData();
    await this.setDatePreference();
    this.userEstimateTransactions = await this.getUserGeneratedEstimateTransactions();
    await this.createPermutations();
    return;
  }

  async afterRunningPermutationsHook() {
    this.winner = await this.calculateWinner();
    this.callback(this.winner);
    return;
  }

  async getUserSymbols() {
    if (this.userSymbols) {
      return this.userSymbols;
    }

    const marketService = this.injector.get(MarketDataService);
    this.userSymbols = await marketService.getUserSymbols();
    return this.userSymbols;
  }

  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    // TODO: Update this to create a single permutation that runs the estimate actions that are saved to Blobby
    this.permutations = [];
  }

  /**
   * calculateWinner - choose the winning permutation from the set
   */
  async calculateWinner(): Promise<ScenarioOptionWinner> {
    // if only 1 permuatation that that is always the winner
    if (this.permutations.length === 1) {
      const winningPermutation = this.permutations[0];
      const finalBalance = await this.getBalanceForPermutation(winningPermutation);
      const createdRecords = this.combineCreatedRecords(winningPermutation);
      const fullTransactionList = this.getFullTransactionList(createdRecords.transactions);
      const scenarioHelp = winningPermutation.scenarioHelpInfo;
      return {
        createdRecords: createdRecords,
        fullTransactionList: fullTransactionList,
        finalBalanceAmount: finalBalance,
        helpInfo: scenarioHelp,
      };
    }
  }

  /**
   * combineCreatedRecords - Combines all the created records from each estimate action that was run for a
   *                        permutation
   * @param permutation
   */
  combineCreatedRecords(permutation: ScenarioPermutation): CreatedRecords {
    const createdRecords: CreatedRecords = {
      transactions: [],
      accounts: [],
    };
    for (const estimateAction of permutation.estimateActions) {
      if (estimateAction?.createdRecords?.transactions) {
        createdRecords.transactions = createdRecords.transactions.concat(
          estimateAction.createdRecords.transactions
        );
      }
      if (estimateAction?.createdRecords?.accounts) {
        createdRecords.accounts = createdRecords.accounts.concat(
          estimateAction.createdRecords.accounts
        );
      }
    }
    return createdRecords;
  }

  getFullTransactionList(transactions: Array<Transaction>): Array<Transaction> {
    return [...this.startingTransactions, ...this.userEstimateTransactions, ...transactions];
  }

  /**
   *
   */
  async getBalanceForPermutation(permutation: ScenarioPermutation): Promise<number> {
    if (permutation.estimateActions.length > 0) {
      const finalBalanceArray =
        permutation.estimateActions[permutation.estimateActions.length - 1].runningAccountBalances;

      const transactionBalanceHelpers = new TransactionBalanceHelpers();
      return this.roundBalance(
        transactionBalanceHelpers.sumAccountBalances(finalBalanceArray, this.scenarioGroup.symbol)
      );
    }
  }

  async run(
    callback: (winner: ScenarioOptionWinner) => void,
    completePermutation: () => void
  ): Promise<void> {
    this.callback = callback;
    this.completePermutation = completePermutation;

    if (this.permutations.length === 0) {
      await this.completedRunningAllPermutations();
      return;
    }

    // run through each possible permutation of the scenario
    this.totalPermutations = this.permutations.length;
    this.completedPemutations = 0;
    let i = 0;
    for (const permutation of this.permutations) {
      const createdRecords: CreatedRecords = {
        runningAccountBalances: this.runningAccountBalances,
      };

      //TODO @Michelle@Sinan : I need 0 transactions if the balance is negative or 0 . Is this the right way ?
      // Moved this to run in the
      // const hasPositiveAccount = await this.hasPositiveBalanceAccounts(groupedBalance);
      // run all the estimate actions for each permutation
      if (permutation.estimateActions.length > 0) {
        await this.runNextEstimateAction(
          i,
          0,
          permutation.estimateActions,
          createdRecords,
          this.returnFromEstimateAction.bind(this)
        );
      } else {
        this.completedPemutations++;
        this.completePermutation();

        if (this.totalPermutations === this.completedPemutations) {
          await this.completedRunningAllPermutations();
        }
      }
      i++;
    }
  }

  /**
   * runNextEstimateAction - Queue the next estimate action in the permutation for a webwoker to take care of
   * @param permutationIndex
   * @param estimateIndex
   * @param estimateActions
   * @param createdRecords
   * @param callback
   */
  async runNextEstimateAction(
    permutationIndex: number,
    estimateIndex: number,
    estimateActions: EstimateActionProperties[],
    createdRecords: CreatedRecords,
    callback: (data: any) => void
  ) {
    const estimateActionParams = estimateActions[estimateIndex];
    const estimateActionData = {
      estimateActionParams: estimateActionParams,
      estimateActions: estimateActions,
      createdRecords: createdRecords,
      permutationIndex: permutationIndex,
      estimateIndex: estimateIndex,
    };
    const webWorkerParams = new EstimateActionWorkerMessage(
      estimateActionParams.estimateActionType,
      estimateActionData
    );
    await this.webWorkerQueue.postMessage(webWorkerParams, callback);
  }

  async returnFromEstimateAction(message: any) {
    const data = message?.data ? message.data : {};
    const createdRecords = data?.createdRecords ? data.createdRecords : {};
    const permutationIndex = data?.permutationIndex ? data.permutationIndex : 0;
    const estimateIndex = data?.estimateIndex ? data.estimateIndex : 0;
    const estimateActions = data?.estimateActions ? data.estimateActions : [];

    const estimateActionParams = this.permutations[permutationIndex].estimateActions[estimateIndex];
    // set up any records that were created from the estimate action
    if (data?.results?.createdRecords) {
      estimateActionParams.createdRecords = data.results.createdRecords;
      if (data?.results?.createdRecords?.transactions) {
        estimateActionParams.createdRecords.transactions = DataTransformer.castToTransactionArray(
          data.results.createdRecords.transactions
        );
      }
      if (data?.results?.createdRecords?.accounts) {
        estimateActionParams.createdRecords.accounts = DataTransformer.castToBookArray(
          data.results.createdRecords.accounts
        );
      }
      if (data?.results?.createdRecords?.runningAccountBalances) {
        // save and pass the groupedBalance to the next estimateAction run
        createdRecords.runningAccountBalances = data.results.createdRecords.runningAccountBalances;
        estimateActionParams.runningAccountBalances =
          data.results.createdRecords.runningAccountBalances;
      }
      // save and pass any created accounts to the next estimateAction run
      if (data?.results?.createdRecords?.accounts) {
        createdRecords.accounts = data.results.createdRecords.accounts;
      }
    }

    // check if there are more estimate actions to run for this permutation
    if (estimateActions.length > estimateIndex + 1) {
      // run the next estimate action in the permutation
      await this.runNextEstimateAction(
        permutationIndex,
        estimateIndex + 1,
        estimateActions,
        createdRecords,
        this.returnFromEstimateAction.bind(this)
      );
      return;
    } else {
      this.completedPemutations++;
      this.completePermutation();

      // If all the permutations have been completed
      if (this.totalPermutations === this.completedPemutations) {
        await this.completedRunningAllPermutations();
      }
    }
  }

  async completedRunningAllPermutations() {
    await this.afterRunningPermutationsHook();
  }

  async getUserGeneratedEstimateTransactions(): Promise<Array<Transaction>> {
    // TODO:
    // get list of only user generated estimate actions
    // run each estimate action with the required parameters
    // add the created transactions to the transactions array

    return [];
  }

  async setDefaultSplitClassification() {
    if (!this.defaultSplitClassification) {
      const classificationService = this.injector.get(ClassificationService);
      this.defaultSplitClassification =
        await classificationService.createDefaultSplitClassification();
    }
  }

  async setDefaultSplitCategory() {
    if (!this.defaultSplitCategory) {
      const categoryService = this.injector.get(CategoryService);
      this.defaultSplitCategory = await categoryService.createDefaultSplitCategory();
    }
  }

  async setBaseCurrency() {
    const preferenceService = this.injector.get(PreferenceService);
    const prefBaseCurrency = await preferenceService.get("baseCurrency");
    if (prefBaseCurrency) {
      if (typeof prefBaseCurrency === "string" && this.baseCurrency !== prefBaseCurrency) {
        this.baseCurrency = prefBaseCurrency;
      }
    }
  }

  async setReferenceData() {
    const dataRepositoryService = this.injector.get(DataRepositoryService);
    const referenceData = await dataRepositoryService.getAllReferenceData();
    if (referenceData) {
      this.referenceData = referenceData;
    }
  }

  async setDatePreference() {
    const balanceGroupingTools = new BalanceGroupingTools();
    this.datePreferences = await balanceGroupingTools.getDatePreferences(this.injector);
  }

  roundBalance(balance: number): number {
    return Math.round(balance * 1000000) / 1000000;
  }
}
