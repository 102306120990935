import { Injector } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { IStepButton } from "@bitwarden/web-vault/app/models/interfaces/stepper.interface";

/**
 * BaseStep class implements the IStepButtonOptions interface.
 * It provides the base functionality for step components, including
 * the next and previous button options.
 */
export abstract class BaseStep implements IStepButton {
  nextBtnOptions: ActionButton;
  prevBtnOptions: ActionButton;
  protected i18n: I18nService;

  protected constructor(protected injector: Injector) {
    this.i18n = this.injector.get(I18nService);
  }

  /**
   * Generates the next button options and sets the onClick event.
   * @param {CallableFunction} next - The function to call on button click.
   * @returns {ActionButton} The next button options with the onClick event set.
   */
  generateNextBtn(next: CallableFunction): ActionButton {
    this.nextBtnOptions.onClick = () => next();
    return this.nextBtnOptions;
  }

  /**
   * Generates the previous button options and sets the onClick event.
   * @param {CallableFunction} next - The function to call on button click.
   * @returns {ActionButton} The previous button options with the onClick event set.
   */
  generatePrevBtn(next: CallableFunction): ActionButton {
    this.prevBtnOptions.onClick = () => next();
    return this.prevBtnOptions;
  }
}
