<div class="tw-flex tw-flex-col">
  <!--  todo: slowly remove app-callout-->
  <app-gloss-callout title="dangerZone" body="loggedOutWarning"></app-gloss-callout>
  <form
    #form
    [appApiAction]="formPromise"
    autocomplete="off"
    class="tw-mt-4 tw-flex tw-max-w-[500px] tw-flex-col tw-gap-6 xs:tw-items-center xs:tw-justify-center md:tw-w-full"
  >
    <app-password-input
      class="xs:tw-w-full"
      [options]="currentPasswordInputOptions"
    ></app-password-input>

    <div class="tw-flex tw-w-full tw-gap-6 xs:tw-flex-col">
      <div class="tw-w-[50%] tw-flex-col xs:tw-w-full">
        <app-password-input [options]="newPasswordInputOptions"></app-password-input>
        <app-password-strength
          [password]="password"
          [email]="email"
          [showText]="true"
          (passwordStrengthResult)="getStrengthResult($event)"
        >
        </app-password-strength>
      </div>
      <div class="tw-w-[50%] xs:tw-w-full">
        <app-password-input [options]="confirmPasswordInputOptions"></app-password-input>
      </div>
    </div>

    <!--    todo: password hint design is a bit different https://www.figma.com/design/cCdcAGMB3MR1Sd6l3L8SvS/Gloss-%7C-User-Access-page?node-id=105-14279&node-type=frame&t=wHY8v8w8GxQzMvhr-0-->
    <div class="tw-flex tw-w-full tw-flex-col">
      <label for="masterPasswordHint">{{ "masterPassHintLabel" | i18n }}</label>
      <input
        id="masterPasswordHint"
        class="input-field"
        maxlength="50"
        type="text"
        name="MasterPasswordHint"
        [(ngModel)]="passwordHint"
      />
    </div>

    <app-gloss-button
      class="tw-w-full"
      [options]="{
        text: 'save' | i18n,
        class: 'primary',
        onClick: submitChange.bind(this),
        isEnabled: !form.loading
      }"
    ></app-gloss-button>
  </form>
</div>
