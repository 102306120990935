import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "icon",
  templateUrl: "./icon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() name: string;
  @Input() size: number;
  @Input() currentColor: string;
}
