import { BaseImporter } from "@bitwarden/common/importers/base-importer";
import { MappingEngine } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine";
import { MappingConfigurationItem } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import { removeSpace } from "@bitwarden/web-vault/app/shared/utils/helper-string";

import DateFormat from "./helper.date/date-format";

export default class HelperCsvImporter extends BaseImporter {
  getFileContents(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, "utf-8");
      reader.onload = (evt) => {
        resolve((evt.target as any).result);
      };
      reader.onerror = () => {
        reject();
      };
    });
  }

  /** remove rows that are fully empty. Rename empty columns as Undetermined-${#UndeterminedIndex}*/
  cleanedFromEmptyData(csvResultsRows: any[]) {
    return csvResultsRows.filter((row) => {
      let tmp: any = {};
      let undeterminedIndex = 1;

      for (let key in row) {
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          if (removeSpace(key) === "") {
            row[`Undetermined-${undeterminedIndex}`] = row[key];
            delete row[key];
            key = `Undetermined-${undeterminedIndex}`;
            undeterminedIndex++;
          }
          const value = row[key];
          if (removeSpace(value) !== "") {
            tmp = row;
          }
        }
      }

      if (Object.keys(tmp).length > 0) {
        return tmp;
      }
    });
  }

  getInfoFromCsv(
    csvData: string,
    csvMapper: MappingConfigurationItem = null,
    mapper?: MappingEngine
  ) {
    if (!mapper) {
      mapper = new MappingEngine();
    }

    /* cleanup csv row and parse data */
    const cleanCsvData = csvData.replace(/\t/g, " ");
    const csvRaw = this.cleanedFromEmptyData(this.parseCsv(cleanCsvData, true));

    const csvResults = mapper.process(csvRaw);

    /* DATE PARSING - Get the date sample for date parsing */
    const dateFormatSample: string[] = [];
    for (const row of csvResults) {
      if (row?.date) {
        dateFormatSample.push(row.date);
      }
    }

    const possibleDateFormatsFromRegEx =
      DateFormat.getPossibleDateFormatsFromRegEx(dateFormatSample);

    if (possibleDateFormatsFromRegEx.highestMatchFormats.length === 1) {
      const detectedFormat = possibleDateFormatsFromRegEx.highestMatchFormats[0];
      const dateFormatIndex: DateFormatIndex =
        possibleDateFormatsFromRegEx.dateFormatIndexMap[detectedFormat];
      return {
        csvResults,
        dateFormatIndex,
      };
    } else {
      return {
        csvResults,
        possibleDateFormats: possibleDateFormatsFromRegEx.highestMatchFormats,
      };
    }
  }

  getCsvHeaders(csvData: string) {
    const csvResults = this.parseCsv(csvData, true);
    return csvResults[0];
  }
}
