import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

import { ReferenceData } from "../../../models/data/blobby/reference-data.data";
import { DateFormatPipe } from "../../../pipes/date-format.pipe";
import { ReferenceDataService } from "../../DataService/reference-data/reference-data.service";

export class ReferenceDataCalculationClass {
  DAY = 1000 * 60 * 60 * 24;
  refresh = false;
  private logger: LogService;
  private allReferenceData: Array<ReferenceData>;
  private readonly referenceDataService: ReferenceDataService;
  private globalReserveCurrency = "USD";

  constructor(
    logger: LogService,
    referenceDataService?: ReferenceDataService,
    referenceData?: ReferenceData[]
  ) {
    this.logger = logger;
    if (referenceData) {
      this.setAllReferenceData(referenceData);
    }
    if (referenceDataService) {
      this.referenceDataService = referenceDataService;
    }
  }

  async getAllReferenceData(): Promise<void> {
    // Alex note, removed the cache here, the getAllReferenceData should always base itself on this.referenceDataService.getAll
    // todo review that. I would think of moving the sort in the referenceDataService function.
    if (
      this.referenceDataService &&
      (this.allReferenceData?.length === 0 || this.refresh || !this.allReferenceData)
    ) {
      this.allReferenceData = await this.referenceDataService.getAll();
    }
  }

  setAllReferenceData(data: any[]) {
    if (Array.isArray(data) && data.every((item) => item instanceof ReferenceData)) {
      this.allReferenceData = data;
    } else {
      this.allReferenceData = data.map(
        (item) => new ReferenceData(new ReferenceDataResponse(item))
      );
    }
  }

  triggerRefresh() {
    this.refresh = true;
  }

  /**
   * Given a symbol, base and date, retrieve the reference data for that record
   */
  async getReferenceData(
    symbol: string,
    base: string,
    startDate: number
  ): Promise<Array<ReferenceData>> {
    this.logger.debug("ReferenceDataCalculationService: getReferenceData");
    const referenceData = [];

    await this.getAllReferenceData();
    const filteredReferenceData = this.allReferenceData.filter((symbolData) => {
      const baseMatch = base === symbolData.base;
      const symbols = Object.keys(symbolData.symbols);
      const symbolMatch = symbols.includes(symbol);

      return symbolMatch && baseMatch;
    });

    const closestReference = this.filterLastPriceDate(startDate, filteredReferenceData);
    if (closestReference) {
      referenceData.push(closestReference);
    }

    return referenceData;
  }

  /**@Sinan@Michelle : is this method supposed to get the reverse currency rate? It looks that way when checking the called places  */
  getAlternativeRefData(symbolData: ReferenceData, symbol: string, base: string): ReferenceData {
    const newSymbolMetaData = {
      base: base,
      date: symbolData.date,
      symbols: {
        [symbol]: symbolData.symbols[symbol] / symbolData.symbols[base],
        [base]: 1,
      },
    };
    return new ReferenceData(new ReferenceDataResponse(newSymbolMetaData));
  }

  isDateMatch(symbolData: ReferenceData, startDate: number, endDate: number): boolean {
    let symbolDataDate;
    if (symbolData?.date?.date) {
      symbolDataDate = new Date(new DateFormatPipe().transform(symbolData.date.date)).getTime();
    }
    const dayDiffOfDates = Math.floor((endDate - symbolDataDate) / this.DAY);
    let dateMatch;
    if (endDate) {
      dateMatch = symbolDataDate >= startDate && dayDiffOfDates >= 0 && symbolDataDate < endDate;
    } else {
      dateMatch = symbolDataDate >= startDate;
    }

    return dateMatch;
  }

  getCurrencyMarketData(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number
  ): ReferenceData[] {
    const referenceData: ReferenceData[] = [];
    for (const symbolData of this.allReferenceData) {
      const dateMatch = this.isDateMatch(symbolData, startDate, endDate);
      const symbols = Object.keys(symbolData.symbols);
      const symbolMatch = symbols.includes(symbol);
      const baseMatch = symbols.includes(base);

      if (dateMatch && symbolMatch && baseMatch) {
        if (base === symbolData.base) {
          referenceData.push(symbolData);
        } else {
          const alteredRefData: ReferenceData = this.getAlternativeRefData(
            symbolData,
            symbol,
            base
          );
          if (alteredRefData) {
            referenceData.push(alteredRefData);
          }
        }
      }
    }

    return referenceData;
  }

  getClosestReference(closestRef: ReferenceData, symbolData: ReferenceData, startDate: number) {
    const symbolStamp = new Date(symbolData.date.date).getTime();
    if (closestRef) {
      const closestStamp = new Date(closestRef.date.date).getTime();
      if (symbolStamp < startDate && symbolStamp > closestStamp && closestStamp < startDate) {
        return symbolData;
      } else {
        return closestRef;
      }
    } else {
      return symbolData;
    }
  }
  getSingleClosestCurrencyData(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number
  ): ReferenceData[] {
    const referenceData: ReferenceData[] = [];
    let closestRef: ReferenceData = null;
    for (const symbolData of this.allReferenceData) {
      const dateMatch = this.isDateMatch(symbolData, startDate, endDate);
      const symbols = Object.keys(symbolData.symbols);
      const symbolMatch = symbols.includes(symbol);
      const baseMatch = symbols.includes(base);

      if (dateMatch && symbolMatch && baseMatch) {
        if (base === symbolData.base) {
          referenceData.push(symbolData);
        } else {
          const alteredRefData: ReferenceData = this.getAlternativeRefData(
            symbolData,
            symbol,
            base
          );
          if (alteredRefData) {
            referenceData.push(alteredRefData);
          }
        }
      } else if (symbolMatch && baseMatch) {
        if (base === symbolData.base) {
          closestRef = this.getClosestReference(closestRef, symbolData, startDate);
        } else {
          const alteredRefData: ReferenceData = this.getAlternativeRefData(
            symbolData,
            symbol,
            base
          );
          if (alteredRefData) {
            closestRef = this.getClosestReference(closestRef, symbolData, startDate);
          }
        }
      }
    }

    return referenceData.length > 0 ? referenceData : [closestRef];
  }

  /*  /!** When the base and preference base are not the same re-write the market data for calculation purposes.
   * todo : check with Alex-Kevin-Michelle about the market data format. - is data going to have all the bases or just one base and contain all other currencies as a prop ?
   * *!/
  flipMarketDate(marketData: ReferenceData, symbol: string, base: string): ReferenceData {
    const value = marketData.symbols[base];
    marketData.symbols[symbol] = 1 / value;
    marketData.base = base;
    return marketData;
  }*/

  async getCurrencyReferenceData(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number
  ): Promise<Array<ReferenceData>> {
    await this.getAllReferenceData();

    /** Grab the currency market data */
    return this.getCurrencyMarketData(symbol, base, startDate, endDate);
  }

  /**
   * Given a symbol, base and date, retrieve the reference data for that record
   * between the startDate and the endDate.
   * Also fill in the days in between that we don't have reference data for
   */
  async getReferenceAfterDate(
    symbol: string,
    base: string,
    startDate: number,
    endDate?: number,
    baseCurrency = "USD"
  ): Promise<Array<ReferenceData>> {
    const referenceDataArray: Array<ReferenceData> = [];

    await this.getAllReferenceData();
    const referenceData = this.allReferenceData.filter((symbolData) => {
      let symbolDataDate;
      if (symbolData?.date?.date) {
        symbolDataDate = new Date(new DateFormatPipe().transform(symbolData.date.date)).getTime();
      }
      let dateMatch;
      if (endDate) {
        const dayDiffOfDates = Math.floor((endDate - symbolDataDate) / this.DAY);
        dateMatch = symbolDataDate >= startDate && dayDiffOfDates >= 0 && symbolDataDate < endDate;
      } else {
        dateMatch = symbolDataDate >= startDate;
      }

      const symbols = Object.keys(symbolData.symbols);
      const symbolMatch = symbols.includes(symbol);
      const baseMatch = symbols.includes(base);

      return dateMatch && symbolMatch && baseMatch;
    });

    // copied code from original reference data service to fill in the blank dates for reference data
    // may want to rework this to use date-fns library
    let pointerDate = startDate;

    let previousSymbolData = referenceData[0];
    for (const referenceDatum of referenceData) {
      const referenceDataDate = new Date(referenceDatum.date.date).getTime();

      const dayDiffOfDates = Math.floor(Math.abs(referenceDataDate - pointerDate) / this.DAY);
      for (let i = 0; i < dayDiffOfDates + 1; i++) {
        if (i === dayDiffOfDates) {
          referenceDataArray.push(referenceDatum);
        } else {
          const currencyMarketDate = this.getCurrencyMarketData(
            base,
            baseCurrency,
            pointerDate,
            pointerDate + 1
          );

          if (currencyMarketDate.length) {
            const currencyStamp = new Date(currencyMarketDate[0].date.date).getTime();
            if (currencyStamp > referenceDataDate) {
              referenceDataArray.push(
                this.copyReferenceDataToNewDate(pointerDate, previousSymbolData)
              );
            }
          }
        }
        pointerDate = pointerDate + this.DAY;
      }

      previousSymbolData = referenceDatum;
    }

    const marketDataForTransactionCurrency = this.getCurrencyMarketData(
      base,
      baseCurrency,
      startDate,
      endDate
    );
    const requestedNumberOfMarketData = Math.floor(Math.abs(endDate - startDate) / this.DAY);
    let runningStamp = startDate;
    let runningDate = new DateFormat().getDateStringFromStamp(runningStamp);
    let previousMarketData = new ReferenceData(
      new ReferenceDataResponse({
        base: base,
        date: runningDate,
        symbols: {
          [symbol]: -1,
        },
      })
    );
    const refDataToReturn: ReferenceData[] = [];
    for (let i = 0; i < requestedNumberOfMarketData; i++) {
      runningDate = new DateFormat().getDateStringFromStamp(runningStamp);
      const existingMarketData = referenceDataArray.find(
        (ref: ReferenceData) => ref.date.date.toUTCString() === runningDate
      );

      if (!existingMarketData) {
        // generate fake data for the day and put it in array to return
        const existingCurrencyData = marketDataForTransactionCurrency.some(
          (ccyData) => ccyData.date.date.toUTCString() === runningDate
        );
        if (existingCurrencyData) {
          const copyRef = this.copyReferenceDataToNewDate(runningStamp, previousMarketData);
          refDataToReturn.push(copyRef);
        }
      } else {
        previousMarketData = existingMarketData;
        refDataToReturn.push(existingMarketData);
      }

      runningStamp = runningStamp + this.DAY;
    }
    return refDataToReturn;
  }

  /**
   * Given a symbol, base retrieve all the reference data that matches
   */
  async getReferenceForSymbolDate(symbol: string, startDate: number): Promise<ReferenceData> {
    await this.getAllReferenceData();
    const filteredReferenceData = this.allReferenceData.filter((symbolData) => {
      const symbols = Object.keys(symbolData.symbols);
      return symbols.includes(symbol);
    });

    return this.filterLastPriceDate(startDate, filteredReferenceData);
  }

  /**
   * Given a symbol retrieve all the reference data that matches this as the base
   */
  async getReferenceForBaseDate(symbol: string, startDate: number): Promise<ReferenceData> {
    await this.getAllReferenceData();
    const filteredReferenceData = this.allReferenceData.filter((symbolData) => {
      return symbol === symbolData.base;
    });

    return this.filterLastPriceDate(startDate, filteredReferenceData);
  }

  /**
   * Given the symbol and the base, try to find each of these relative to the global reserve currency.
   * This should be used when the symbol and base can reference data does not exist to link each other
   * @param symbol
   * @param base
   * @param startDate
   */
  async getReferenceDataFromReserveCurrency(
    symbol: string,
    base: string,
    startDate: number
  ): Promise<Array<ReferenceData>> {
    const referenceData: Array<ReferenceData> = [];

    let reverseBase = false;
    let reverseSymbol = false;

    let baseData = await this.getReferenceData(base, this.globalReserveCurrency, startDate);
    if (baseData.length === 0) {
      baseData = await this.getReferenceData(this.globalReserveCurrency, base, startDate);
      reverseBase = true;
    }
    let symbolData = await this.getReferenceData(symbol, this.globalReserveCurrency, startDate);
    if (symbolData.length === 0) {
      symbolData = await this.getReferenceData(this.globalReserveCurrency, symbol, startDate);
      reverseSymbol = true;
    }

    if (baseData.length > 0 && symbolData.length > 0) {
      let baseRate = baseData[0].symbols[base];
      let symbolRate = symbolData[0].symbols[symbol];

      if (reverseBase) {
        baseRate = 1 / baseRate;
      }
      if (reverseSymbol) {
        symbolRate = 1 / symbolRate;
      }
      const rate = baseRate / symbolRate;
      // const rate = symbolRate / baseRate
      const inferredRate: { [key: string]: number } = {};
      inferredRate[symbol] = rate;
      const refDataResponse = new ReferenceDataResponse({
        date: startDate,
        base: base,
        symbols: inferredRate,
      });
      const newReference = new ReferenceData(refDataResponse);
      referenceData.push(newReference);
    }

    return referenceData;
  }

  /**
   * Function copied across from old market data service. Might need to be reworked to use date-fns
   * library instead of hardcoding times to add
   *
   * @param date
   * @param symbolData
   * @private
   */
  private copyReferenceDataToNewDate(date: number, symbolData: ReferenceData): ReferenceData {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");

    return new ReferenceData(
      new ReferenceDataResponse({
        base: symbolData.base,

        date: {
          date: year + "-" + month + "-" + day,
          time: null,
          tz: null,
        },
        symbols: symbolData.symbols,
      })
    );
  }

  /**
   * Note that we currently assume that the most recent reference data to the date is the most accurate.
   * However, in the future the most accurate one might be the one that produces the greatest base value
   * when normalized unique on currency symbol.
   *
   * @param date
   * @param data - Assumes that the data array is already sorted be date
   * @private
   */
  private filterLastPriceDate(date: number, data: Array<ReferenceData>): ReferenceData {
    data.sort((a, b) => new Date(a.date.date).getTime() - new Date(b.date.date).getTime());

    // go through and find the matching reference that is closest to the date
    let closestReference: ReferenceData;
    for (const symbolData of data) {
      let symbolDataDate;
      if (symbolData?.date?.date) {
        const isoDate = new DateFormatPipe().transform(symbolData.date.date);
        symbolDataDate = new Date(isoDate).getTime();
      }
      if (symbolDataDate !== null && symbolDataDate <= date) {
        closestReference = symbolData;
      } else {
        if (!closestReference) {
          closestReference = symbolData;
        }
        break;
      }
    }

    if (closestReference) {
      return closestReference;
    }
    return;
  }
}
