<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="2faU2fTitle">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <modal-header
        [title]="'enableBiometricVerification' | i18n"
        [showBackButton]="false"
      ></modal-header>
      <div class="tw-rounded-xl tw-bg-white tw-p-10 xs:tw-px-5">
        <app-two-factor-verify
          [organizationId]="organizationId"
          [type]="type"
          (onAuthed)="auth($any($event))"
        >
        </app-two-factor-verify>
      </div>
    </div>
  </div>
</div>
