export function getTransactionViewValue(params: any) {
  if (params.data?.revalTransaction) {
    return params.data.aggregateAmount;
  }

  return params.data.quantity.actualQuantity.amount;
}

export function getTransactionViewSymbol(params: any) {
  if (params.data?.revalTransaction) {
    return params.data.valuation.normalizedValue.symbol;
  } else {
    return params.data.quantity.actualQuantity.symbol;
  }
}
