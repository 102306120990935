import { Component, EventEmitter, Input, Output } from "@angular/core";

import { WidgetMenuType, WidgetType } from "@bitwarden/web-vault/app/models/types/widget.types";

@Component({
  selector: "app-widget",
  templateUrl: "widget.component.html",
})
export class WidgetComponent {
  @Input() title: string;
  @Input() iconName: string;
  @Input() type: WidgetType = "static";
  @Input() menu: {
    type: WidgetMenuType;
    options: { displayValue?: string; value: string; isSelected?: boolean }[];
  };
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();

  onOptionClick(value: string) {
    this.menu.options.forEach((option) => {
      option.isSelected = value == option.value;
    });
    this.onClick.emit(value);
  }
}
