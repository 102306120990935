import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { SETTINGS_PATH } from "@bitwarden/web-vault/app/models/constants/global.constants";

@Component({
  selector: "app-settings-route",
  templateUrl: "settings-route.component.html",
})
export class SettingsRouteComponent {
  protected title = "";
  protected backRoutePath = SETTINGS_PATH;

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  onActivate() {
    this.title = this.route.snapshot.firstChild?.data["titleId"];
    const parentRoutePath = this.route.snapshot.parent?.routeConfig.path;
    if (parentRoutePath !== "") {
      this.backRoutePath = `/${parentRoutePath}`;
    }
    this.cdr.detectChanges();
  }
}
