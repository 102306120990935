import { Component, Input } from "@angular/core";

import { EarningDataCard } from "../../../../models/types/component.types";

@Component({
  selector: "best-scenario-stepper-earnings-card",
  templateUrl: "./stepper-earnings-card.component.html",
})
export class StepperEarningsCardComponent {
  @Input() earningsData: EarningDataCard;
}
