import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { Verification } from "@bitwarden/common/types/verification";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

@Component({
  selector: "app-remove-basiq-consent",
  templateUrl: "./remove-basiq-consent.component.html",
})
export class RemoveBasiqConsentComponent {
  @Input() organizationId?: string = null;

  masterPassword: Verification;
  keepDataIn = true;
  deleting = false;

  constructor(
    private i18nService: I18nService,
    private platformUtilsService: PlatformUtilsService,
    private userVerificationService: UserVerificationService,
    private logService: LogService,
    private blobbyService: BlobbyService,
    private dashboardService: DashboardService,
    private basiqAuthService: BasiqAuth,
    private dialogRef: MatDialogRef<RemoveBasiqConsentComponent>
  ) {}

  async submit() {
    try {
      this.deleting = true;
      const isRemoved = await this.basiqAuthService.removeConsent(
        this.keepDataIn,
        this.masterPassword
      );
      await this.refreshAppService();

      this.deleting = false;
      if (isRemoved) {
        this.dialogRef.close();
        this.platformUtilsService.showToast(
          "success",
          null,
          this.i18nService.t("basiqConsentRemovedSuccessfully")
        );
      }
    } catch (e) {
      this.platformUtilsService.showToast(
        "error",
        "Error Occurred",
        this.i18nService.t("somethingWentWrong")
      );
      this.logService.error(e);
    } finally {
      this.deleting = false;
    }
  }

  setKeepDataIn(value: boolean) {
    this.keepDataIn = value;
  }

  async refreshAppService() {
    await this.blobbyService.refresh();
    await this.dashboardService.refresh(true);
  }

  closeForm() {
    this.dialogRef.close();
  }
}
