<div class="tw-flex tw-flex-col tw-gap-1 tw-rounded-lg">
  <div class="tw-flex tw-h-auto tw-flex-col tw-gap-1">
    <nav-panel
      [title]="'passwordAndHint' | i18n"
      [routerLink]="getRouterLink(SecuritySettingsRoute.ChangePassword)"
    >
      <span>{{ "passwordAndHint" | i18n }}</span>
    </nav-panel>
    <nav-panel
      [title]="'loginDevices' | i18n"
      [routerLink]="getRouterLink(SecuritySettingsRoute.LoginDevices)"
      *ngIf="isDevEnv"
    >
      <span>{{ "enableBiometricVerification" | i18n }}</span>
    </nav-panel>
  </div>
</div>
