import { Injectable } from "@angular/core";

import { BaseImporter } from "@bitwarden/common/importers/base-importer";
import { ImportTransactionResult } from "@bitwarden/common/models/domain/import-transaction-result";
import { currencies } from "@bitwarden/web-vault/app/gloss/manage/manage-preferences/currencies";
import {
  AlignmentMappingItem,
  HeadersMapping,
  MappingConfigurationItem,
  Row,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionPropertyPlaceHolders } from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import {
  AmountTypeEnum,
  TransactionStatusEnum,
} from "@bitwarden/web-vault/app/models/enum/transactionType";
import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import { AmountType } from "@bitwarden/web-vault/app/models/types/import.types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";

import { Transaction } from "../models/data/blobby/transaction.data";

import { MappingEngine } from "./data-mapper/mapping-engine";
import { TransactionImporter } from "./transaction-importer";

@Injectable({
  providedIn: "root",
})
export class TransactionCsvImporter extends BaseImporter implements TransactionImporter {
  readonly currencies: string[] = currencies;
  account: string = null;
  private balanceAlignmentMapper: AlignmentMappingItem[] = [];
  constructor() {
    super();
  }

  isProcessableByDate(records: any): boolean {
    for (const [propertyPlaceholders, value] of Object.entries(TransactionPropertyPlaceHolders)) {
      if (
        Object.prototype.hasOwnProperty.call(records, propertyPlaceholders) &&
        records[value].replace(/\s/g, "") !== ""
      ) {
        return true;
      }
    }
    return false;
  }

  isTransactionRowProcessable(records: any): boolean {
    return this.isProcessableByDate(records);
  }

  setBalanceAlignmentMapper(balanceAlignmentMapper: AlignmentMappingItem[]) {
    this.balanceAlignmentMapper = balanceAlignmentMapper;
  }

  getHeadersMapping(institutionCsvMap: MappingConfigurationItem[]): HeadersMapping {
    const headersMapping: HeadersMapping = {};
    for (const mappingConfigurationItem of institutionCsvMap) {
      headersMapping[mappingConfigurationItem.key] = mappingConfigurationItem;
    }

    return headersMapping;
  }

  /*TODO ... Since we are not using quantity anymore ... we need to set it on the flow to make sure it gets a quantity value @Alex should check*/
  private getRecordsQuantity(records: Row) {
    if (records.amount) {
      return records.amount;
    } else if (records.in) {
      return records.in;
    } else if (records.out) {
      return records.out;
    } else if (records.price) {
      return records.price;
    } else {
      return null;
    }
  }
  /*TODO this hack for credit cards should appear on tables as well ... @Sinan*/
  setRecordsCreditCardInsAndOuts(records: Row, amountType: AmountType) {
    if (amountType === AmountTypeEnum.reverse) {
      if (records.in && !records.out) {
        if (records.in < 0) {
          records.in = -1 * records.in;
          delete records.out;
        } else {
          records.out = records.in;
          delete records.in;
        }
      } else if (!records.in && records.out) {
        if (records.out < 0) {
          records.out = -1 * records.out;
          delete records.in;
        } else {
          records.in = records.out;
          delete records.out;
        }
      }

      if (records.amount) {
        records.amount = -1 * records.amount;
      }

      if (records.quantity) {
        records.quantity = -1 * records.quantity;
      }
    } else {
      if (records.in && !records.out) {
        if (records.in < 0) {
          records.out = -1 * records.in;
          delete records.in;
        }
      }

      if (!records.in && records.out) {
        if (records.out < 0) {
          records.in = -1 * records.out;
          delete records.out;
        }
      }
    }
  }
  async parse(
    csvResults: any[],
    userDateFormatIndex: DateFormatIndex = null,
    institutionCsvMap: MappingConfigurationItem[] = null,
    bookService: BookService = null,
    helperPreference: HelperPreference = null,
    amountType: AmountType = AmountTypeEnum.regular
  ): Promise<ImportTransactionResult> {
    const result = new ImportTransactionResult();
    result.classifications = [];
    result.categories = [];
    result.noCurrencyTransactions = [];
    result.noSymbolTransactions = [];

    const mapper = new MappingEngine();
    // todo @sinan need to pass the institutionCsvMap to the mapper when it's created there
    /*const headerMapping:HeadersMapping = this.getHeadersMapping(institutionCsvMap)
    mapper.setMapper(headerMapping)*/
    const mappedResults = mapper.process(csvResults);

    for (const records of mappedResults) {
      /** Return a mapped of csv records */

      records.classifications = records.classifications ? [{ name: records.classifications }] : [];
      records.categories = records.categories ? [{ name: records.categories }] : [];
      records.dateFormatIndex = userDateFormatIndex;
      /** check if the currency exists on transaction record . So later on we can add the transaction to noCurrencyTransactions array*/
      let isNoCurrency = false;
      let isNoSymbol = false;
      /** add currency to transaction record if there is none on it */
      if (!records.currency) {
        records.currency = await this.getImportTransactionRecordCurrency(
          records,
          bookService,
          helperPreference
        );
        isNoCurrency = true;
      }

      /** add symbol to transaction record if there is none on it */
      if (!records.symbol) {
        isNoSymbol = true;
      }
      const isTransactionRowProcessable = this.isTransactionRowProcessable(records);

      if (!isTransactionRowProcessable) {
        continue;
      }
      if (!records.quantity) {
        records.quantity = this.getRecordsQuantity(records);
      }
      this.setRecordsCreditCardInsAndOuts(records, amountType);

      const transactionResponse = new TransactionResponse(records);
      const transaction = new Transaction(transactionResponse);
      const actualQuantity = transaction.quantity.actualQuantity.amount;
      /** add the transaction to noCurrencyTransactions if its record did not have a currency .
       * bear in mind that one way or the other transaction is gonna have a currency . But if the transaction record does not have a currency value ,
       * and it belongs to a new account we have to let user select the currency for those transactions . example : if a accountA is new and there are
       * three transaction belonging to this account . When we create these transactions above we will set their currency values to either preferenceBaseCurrency if there is one
       * or to GLOBAL_BASE_CURRENCY which is hard coded at the moment . But when user is provided with the account link-rename window if user link this new account to an existing one
       * then we will change belonging transactions' currency values to this linked account currency . or if the user creates (renames) this account then we will set those transactions'
       * currency values the selected currency value for the account
       * */
      if (isNoCurrency) {
        result.noCurrencyTransactions.push(transaction);
      }

      if (isNoSymbol) {
        result.noSymbolTransactions.push(transaction);
      }

      /** Set this flag to check whether the valuationPrice can be use in setValuation
       *  if the symbol is not a currencies value that means use the valuationPrice
       */
      transaction.isUsingValuationPrice = !this.currencies.includes(
        transaction.quantity.actualQuantity.symbol
      );

      if (actualQuantity && !isNaN(actualQuantity)) {
        // await this.transactionNormalizeService.normalizeImportedTransaction(transaction);
        result.transactions.push(transaction);
      } else {
        /** check if this is an opening balance
         If line item is a balance then we need to work out what is the current amount in this account
         and then work out how much we need to minus or add to get to the balance amount and enter
         a transaction with the description 'balance alignment' */
        if (records.balance !== undefined && records.balance !== null) {
          const mapper = new MappingEngine();
          const transactionStatus = mapper.transactionStatus(
            transaction,
            this.balanceAlignmentMapper
          );
          const openingBal = transactionStatus.keyOnTransaction === TransactionStatusEnum.opening;
          const closeBal = transactionStatus.keyOnTransaction === TransactionStatusEnum.closing;
          transaction.definition = transactionStatus.keyOnTransaction;
          if (openingBal) {
            transaction.bankImportedBalance = records.balance;
            transaction.description = "Opening Balance Alignment Transaction";
          } else if (closeBal) {
            transaction.bankImportedBalance = records.balance;
            transaction.description = "Closing Balance Alignment Transaction";
          }
          result.transactions.push(transaction);
        }
      }
    }

    return result;
  }
}
