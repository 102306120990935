import { Component, Input } from "@angular/core";

import { EarningDataCard } from "../../../../models/types/component.types";

@Component({
  selector: "best-scenario-carousel-earnings-card",
  templateUrl: "./carousel-earnings-card.component.html",
})
export class CarouselEarningsCardComponent {
  @Input() earningsData: EarningDataCard;
}
