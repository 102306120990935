<div class="tw-rounded-lg tw-bg-white tw-p-10">
  <div
    class="tw-mb-2 tw-text-xl tw-font-medium"
    [ngClass]="{
      'tw-text-accent': data.type === 'warning',
      'tw-text-primary': data.type === 'info'
    }"
  >
    {{ data.title | i18n }}
  </div>
  <div>
    <ng-container *ngIf="data && data.message">
      <p *ngIf="isMessageKey">{{ data.message | i18n }}</p>
      <p *ngIf="!isMessageKey" [innerHTML]="data.message"></p>
    </ng-container>
  </div>
  <div class="tw-mt-5 tw-flex tw-w-full tw-justify-between tw-gap-6">
    <app-gloss-button
      class="tw-w-full"
      mat-dialog-close
      aria-label="Close dialog"
      [options]="closeBtnOptions"
    ></app-gloss-button>
    <app-gloss-button
      class="tw-w-full"
      [mat-dialog-close]="true"
      [options]="confirmBtnOptions"
    ></app-gloss-button>
  </div>
</div>
