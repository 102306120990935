<ng-container mat-dialog-content>
  <div
    class="tw-relative tw-h-[650px] tw-w-[350px] tw-rounded-lg tw-bg-white tw-p-6 xs:tw-h-full xs:tw-w-full"
  >
    <svg
      mat-dialog-close
      class="tw-absolute tw-right-0 tw-top-0 tw-m-5 tw-h-4 tw-w-4 tw-text-black"
    >
      <use [attr.xlink:href]="'images/sprite.svg#close'" />
    </svg>
    <app-stepper *ngIf="isStepper" [steps]="steps" (onMove)="move($event)"></app-stepper>
    <app-carousel *ngIf="isCarousel" [items]="bestTenAccounts">
      <div
        *ngFor="let item of bestTenAccounts"
        class="ng-star-inserted tw-box-border tw-min-w-full tw-rounded-lg tw-bg-neutral-50 tw-p-4"
      >
        <best-scenario-carousel-item
          [accountName]="item.accountName"
          [earningsCard]="item.earningsCard"
          [rateInUse]="item.rateInUse"
        ></best-scenario-carousel-item>
      </div>
    </app-carousel>
  </div>
</ng-container>
