export enum ConfirmationEnum {
  deletingVaultFile = "deletingVaultFile",
  usingWeakPassword = "usingWeakPassword",
  deletingAccount = "deletingAccount",
  deletingAccountWithGeneratedMessage = "deletingAccountWithGeneratedMessage",
  deletingTransaction = "deletingTransaction",
  usingDefaultInstitution = "usingDefaultInstitution",
  givingFirstConsent = "givingFirstConsent",
  havingNoChanges = "havingNoChanges",
  updatingBalance = "updatingBalance",
  deletingEstimate = "deletingEstimate",
  deletingCategory = "deletingCategory",
  deletingClassification = "deletingClassification",
  deletingInstitution = "deletingInstitution",
  askingForEarlyAccess = "askingForEarlyAccess",
  askingForReConsentOfExpired = "askingForReConsentOfExpired",
  askingForReConsentOfRevoked = "askingForReConsentOfRevoked",
  removingBasiqDataOfRevokedConsent = "removingBasiqDataOfRevokedConsent",
  removingBasiqDataOfExpiredConsent = "removingBasiqDataOfExpiredConsent",
  importError = "importError",
  logOutConfirmation = "logOutConfirmation",
  turnOffBiometrics = "turnOffBiometrics",
}
