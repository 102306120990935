<form #form [appApiAction]="formPromise" ngNativeValidate>
  <div class="tw-mb-6 tw-rounded-xl tw-bg-white">
    <app-user-verification [(ngModel)]="secret" ngDefaultControl name="secret">
    </app-user-verification>
  </div>
  <app-gloss-button [options]="continueButtonOptions" class="tw-w-full"></app-gloss-button>

  <!--  <div class="tw-mt-6 tw-flex tw-gap-2 xs:tw-flex-col">-->
  <!--    <app-gloss-button [options]="continueButtonOptions" class="button-resize"></app-gloss-button>-->
  <!--    <app-gloss-button-->
  <!--      [options]="cancelButtonOptions"-->
  <!--      data-dismiss="modal"-->
  <!--      class="button-resize sm:tw-hidden"-->
  <!--    ></app-gloss-button>-->
  <!--  </div>-->
</form>
