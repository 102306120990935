<div class="content">
  <ng-container> Past Present Future </ng-container>

  <div class="tw-shadow-form tw-rounded-xl">
    <ng-container> New Date Picker Filter </ng-container>
    <div class="tw-flex tw-w-full tw-gap-4 sm:tw-flex-col">
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-4">
        <app-widget
          [title]="'balance' | i18n"
          [iconName]="'dollar'"
          [menu]="balanceWidgetMenu"
          (onClick)="handleCurrencyWidget($event)"
        >
          $12000 (you don't need to create another component here)
        </app-widget>
        <app-widget
          [title]="'analysis' | i18n"
          [iconName]="'file-analysis'"
          [menu]="chartWidgetMenu"
          (onClick)="handleChartWidget($event)"
        >
          Chart component
        </app-widget>
      </div>
      <div class="tw-w-full">
        <app-widget [title]="'accounts' | i18n" [iconName]="'wallet'">
          Account Filter component
        </app-widget>
      </div>
    </div>
  </div>
</div>
