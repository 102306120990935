<span
  class="tw-flex
  {{
    !isPrimary &&
      'tw-text-xs tw-font-medium tw-leading-[125%] tw-tracking-[0.005em] tw-text-primary'
  }}
  {{ isPrimary && 'tw-flex-col tw-tracking-[0.0125em] tw-text-neutral-800 xs:tw-text-sm' }}
  "
>
  <ng-content></ng-content>
</span>
