import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { statementLinkedAccountWithBalances } from "@bitwarden/web-vault/app/components/add-balance/add-balance.component";
import { AddTransactionComponent } from "@bitwarden/web-vault/app/components/add-transaction/add-transaction.component";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { FileUploadComponent } from "@bitwarden/web-vault/app/components/file-upload/file-upload.component";
import { AccountSelectorComponent } from "@bitwarden/web-vault/app/gloss/cabinet/account-selector/account-selector.component";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { transactionDataType } from "@bitwarden/web-vault/app/models/types/transactionData.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { CabinetService } from "@bitwarden/web-vault/app/services/DataService/vault-file/cabinet.service";
import { SelectAccountService } from "@bitwarden/web-vault/app/services/cabinet/selectAccount.service";

const mediumOrLargerBreakpoint = 768;

@Component({
  selector: "app-cabinet-file-upload",
  templateUrl: "./cabinet-file-upload.component.html",
})
export class CabinetFileUploadComponent implements OnDestroy, AfterViewInit {
  selectedFile: File | null = null;
  addTransaction = false;
  addBalance = false;
  addBalanceCompleted = false;
  chooseAccountAddTransactionRef: MatDialogRef<AccountSelectorComponent>;
  chooseAccountDialogue = true;
  linkedAccountsData: statementLinkedAccountWithBalances[];
  uploadFileData: CabinetFileUploadedData;
  uploading = false;
  transactions: transactionDataType[] = [];
  isAddTransactionForm = true;
  isEditExistingTransaction = false;
  ineditableLinkedAccounts: AccountView[] = [];
  noAccountCanChange = false;
  isMediumOrLarger = false;
  showFilePreview = false;
  previewContentHeight: string;
  accountName: string;
  resizeObserver: ResizeObserver;
  selectAccountService: SelectAccountService;
  selectedAccount: AccountView;
  inEditAccount: AccountView;
  dialog: MatDialog;
  transactionService: TransactionService;
  globalService: GlobalService;
  cabinetComponentService: CabinetService;
  cabinetService: CabinetService;
  private destroy$ = new Subject<void>();
  private isGoingToNextStep = true;

  @ViewChild(AddTransactionComponent) addTransactionComponent!: AddTransactionComponent;
  @ViewChild(FileUploadComponent) fileUploadComponent!: FileUploadComponent;
  @ViewChild("contentDiv") contentDiv: ElementRef;
  @ViewChild("filePreview") filePreview: ElementRef;
  @HostListener("window:resize")
  onResize(): void {
    this.checkWindowSize();
    this.updateFilePreviewHeight();
  }

  constructor(
    public dialogRef: MatDialogRef<CabinetFileUploadComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeForm: CallableFunction;
    },
    private injector: Injector,
    private i18nService: I18nService
  ) {
    this.addBalance = true;
    this.selectAccountService = injector.get(SelectAccountService);
    this.dialog = injector.get(MatDialog);
    this.transactionService = injector.get(TransactionService);
    this.globalService = injector.get(GlobalService);
    this.cabinetComponentService = injector.get(CabinetService);
    this.cabinetService = injector.get(CabinetService);
  }

  updateTransaction = new ActionButton({
    text: this.i18nService.t("update"),
    icon: "save",
    class: "neutral",
    fullClass:
      "btn-container tw-mr-3 tw-flex tw-h-[30px] tw-cursor-pointer tw-items-center tw-justify-between tw-gap-[6px] tw-rounded-[8px] tw-border tw-border-solid tw-border-primary tw-bg-[#FCFCFC] tw-px-3 tw-py-2 sm:tw-mb-1 sm:tw-mr-0 sm:tw-block sm:tw-w-auto",
    onClick: () => this.saveNewTransaction(),
  });

  addMoreTransaction = new ActionButton({
    text: this.i18nService.t("addMore"),
    icon: "add",
    class: "neutral",
    fullClass:
      "tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button hover:tw-cursor-pointer",
    onClick: () => this.addOneMoreTransaction(),
  });

  ngAfterViewInit(): void {
    this.checkWindowSize();
    this.resizeObserver = new ResizeObserver(() => {
      this.updateFilePreviewHeight();
    });
    if (this.contentDiv && this.contentDiv.nativeElement) {
      this.resizeObserver.observe(this.contentDiv.nativeElement);
    }
  }

  addBalanceComplete(isComplete: boolean) {
    this.addBalanceCompleted = isComplete;
    if (this.addBalanceCompleted) {
      this.chooseAccountDialogue = false;
      this.addBalance = false;
      this.openDialog();
    }
  }

  receivedLinkedAccountsData($event: statementLinkedAccountWithBalances[]) {
    this.linkedAccountsData = $event;
  }

  receivedSelectedFile(event: File) {
    this.selectedFile = event;
    this.updateFilePreviewHeight();
  }

  receivedFileData($event: CabinetFileUploadedData) {
    const verifiedResult = this.cabinetService.validateFile($event);

    if (verifiedResult.isValid) {
      this.uploadFileData = $event;
      this.addBalanceComplete(true);
    } else {
      if (!$event.file) {
        this.fileUploadComponent.errorMessage = "inputRequired";
      }
      this.globalService.showWarningMessage("warning", verifiedResult.messageKey);
    }
  }

  getDisplayAccountName(): string {
    return this.isEditExistingTransaction && this.inEditAccount
      ? this.inEditAccount.name
      : this.selectedAccount.name;
  }

  openDialog() {
    this.chooseAccountAddTransactionRef = this.dialog.open(AccountSelectorComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        finishUploadForm: this.finishUploadForm.bind(this),
        accountBalanceData: this.linkedAccountsData,
        unavailableAccountOptions: this.ineditableLinkedAccounts,
      },
      disableClose: true,
    });
  }

  closeCreationOptionsDialogue() {
    this.chooseAccountAddTransactionRef.close();
    this.addTransaction = true;
    this.chooseAccountDialogue = true;
    this.chooseAccountAddTransactionRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.selectAccountService.setSelectedAccount(result.data);
          this.selectedAccount = result.data;
          this.ineditableLinkedAccounts.push(result.data);
          this.isAddTransactionForm = true;
          if (this.ineditableLinkedAccounts.length === this.linkedAccountsData.length) {
            this.noAccountCanChange = true;
          }
        }
      });
  }

  async finishUploadForm() {
    await this.saveUploadFile(this.selectedFile, this.uploadFileData);
    this.saveAllTransactions();
    this.chooseAccountAddTransactionRef.close();
    this.dialogRef.close(this.uploadFileData);
  }

  addOneMoreTransaction() {
    if (this.isAddTransactionForm) {
      this.saveNewTransaction();
    }
    this.isAddTransactionForm = true;
  }

  saveNewTransaction() {
    this.addTransactionComponent.completeForm();
    this.inEditAccount = null;
    this.isEditExistingTransaction = false;
  }

  //received transaction data from saveNewTransaction /\
  receivedTransactionData($event: transactionDataType | null | "touched") {
    if ($event && $event !== "touched") {
      this.isGoingToNextStep = true;
      this.transactions.push($event);
      this.transactions = this.transactions.sort((a, b) =>
        a.account.name.localeCompare(b.account.name)
      );
      this.transactions = [...this.transactions];
      this.isAddTransactionForm = false;
    } else if ($event === "touched") {
      this.isGoingToNextStep = false;
      this.isAddTransactionForm = true;
    }
  }

  changeSelectedAccount() {
    this.chooseAccountDialogue = false;
    this.openDialog();
  }

  receivedIsEditTransaction($event: transactionDataType) {
    if ($event) {
      this.isAddTransactionForm = true;
      this.addTransactionComponent.editTransaction($event);
      this.transactions = this.transactions.filter((transaction) => transaction !== $event);
      this.isEditExistingTransaction = true;
      this.inEditAccount = $event.account;
    }
  }

  receivedIsRemoveTransaction($event: transactionDataType) {
    if ($event) {
      this.transactions = this.transactions.filter((transaction) => transaction !== $event);
      if (this.transactions.length === 0) {
        this.isAddTransactionForm = true;
      }
    }
  }

  nextAccountAddTransaction() {
    this.addOneMoreTransaction();
    if (this.isGoingToNextStep) {
      this.addTransactionComponent.resetValue();
      this.changeSelectedAccount();
    }
    this.isGoingToNextStep = true;
  }

  async submitFile() {
    this.saveNewTransaction();
    if (this.isGoingToNextStep) {
      this.isAddTransactionForm = false;
      this.saveAllTransactions();
      await this.saveUploadFile(this.selectedFile, this.uploadFileData);
      this.selectAccountService.setSelectedAccount(null);
      this.dialogRef.close(this.uploadFileData);
    }
    this.isGoingToNextStep = true;
  }

  saveAllTransactions() {
    try {
      this.transactions.forEach((transaction) => {
        this.transactionService.createNormalTransaction(
          transaction.account.originalBook,
          transaction.formData
        );
      });
      this.globalService.showSuccessMessage("success", "allTransactionsAddedSuccessfully");
      this.selectAccountService.setSelectedAccount(null);
    } catch (e) {
      this.globalService.showErrorMessage("error", "transactionFailed");
    }
  }

  async saveUploadFile(selectedFile: File, newBlance: CabinetFileUploadedData) {
    try {
      this.uploading = true;
      const data: CabinetFileUploadedData = {
        name: selectedFile?.name,
        mimeType: selectedFile?.type,
        statementToDate: newBlance.statementToDate,
        statementFromDate: newBlance.statementFromDate,
        statementAccounts: newBlance.statementAccounts,
        openingBalances: newBlance.openingBalances,
        closingBalances: newBlance.closingBalances,
        file: selectedFile,
      };

      const isSaved = await this.cabinetComponentService.saveFile(data);
      if (isSaved) {
        this.data.closeForm(isSaved);
      }
    } catch (error) {
      this.cabinetComponentService.displayCatchError(error);
    } finally {
      this.uploading = false;
    }
  }

  private updateFilePreviewHeight(): void {
    this.previewContentHeight = this.contentDiv.nativeElement.offsetHeight + "px";
  }

  private checkWindowSize(): void {
    this.isMediumOrLarger = window.innerWidth >= mediumOrLargerBreakpoint;
  }

  toggleFilePreview(): void {
    this.showFilePreview = !this.showFilePreview;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
