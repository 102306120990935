import { EstimateActionWorker } from "@bitwarden/web-vault/app/services/web-worker/estimate-actions/estimate-action.worker";
import { TransactionBalancesWorker } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker";
import { TransactionBalancesWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.message";
import { WebWorker } from "@bitwarden/web-vault/web-worker/web.worker";
import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

export class WebWorkerQueue {
  private maxWorkers = 4;
  private workers: Array<WebWorker> = [];
  private freeWorkers: Record<string, Array<WebWorker>> = {};
  private workersBusy: Array<boolean> = [];
  private queue: Array<{
    message: WorkerMessage;
    callback?: (data: any) => void;
    resolve?: (value?: any) => void;
    reject?: (reason?: any) => void;
  }> = [];

  createNewWorker(workerType?: string): number {
    if (this.workers.length >= this.maxWorkers) {
      return;
    }
    const worker = this.getCorrectWorkerType(workerType);
    worker.workerIndex = this.workers.length;
    this.workers.push(worker);
    this.workersBusy.push(false);

    return worker.workerIndex;
  }

  getCorrectWorkerType(workerType: string): WebWorker {
    let worker: WebWorker;
    if (this.freeWorkers?.[workerType] && this.freeWorkers[workerType].length > 0) {
      worker = this.freeWorkers[workerType].pop();
    }
    if (worker == undefined) {
      if (workerType === "EstimateActionWorker") {
        worker = EstimateActionWorker.getInstance();
      } else if (workerType === "TransactionBalancesWorker") {
        worker = TransactionBalancesWorker.getInstance();
      }
    }
    return worker;
  }

  freeWorker(worker: WebWorker) {
    worker.workerIndex = null;
    if (this.freeWorkers?.[worker.workerType]) {
      this.freeWorkers[worker.workerType].push(worker);
    } else {
      this.freeWorkers[worker.workerType] = [worker];
    }
  }

  async runJob(workerIndex: number) {
    this.workersBusy[workerIndex] = true;
    let worker = this.workers[workerIndex];
    if (this.queue.length > 0) {
      const job = this.queue.shift();
      if (job && job?.message?.id && job?.message?.workerType) {
        // check that the worker is the correct type for the job
        if (worker.workerType !== job.message.workerType) {
          this.freeWorker(worker);
          worker = this.getCorrectWorkerType(job.message.workerType);
          this.workers[workerIndex] = worker;
        }
        if (worker instanceof EstimateActionWorker) {
          job.message.data.workerIndex = workerIndex;
          await worker.runEstimateAction(job.message, (data: any) => {
            const returnedWorkerIndex = data?.data?.workerIndex;
            this.workersBusy[returnedWorkerIndex] = false;
            this.processQueue();
            job.callback(data);
          });
        } else if (worker instanceof TransactionBalancesWorker) {
          worker.resolve = null;
          worker.reject = null;
          if (job?.resolve) {
            worker.resolve = (data: any) => {
              this.workersBusy[workerIndex] = false;
              job.resolve(data);
              this.processQueue();
            };
          }
          if (job?.reject) {
            worker.reject = (data: any) => {
              this.workersBusy[workerIndex] = false;
              job.reject(data);
              this.processQueue();
            };
          }
          try {
            if (job.message instanceof TransactionBalancesWorkerMessage) {
              job.message.workerIndex = workerIndex;
              await worker.getBalanceForTransactions(job.message);
            }
          } catch (error) {
            job.reject(error);
          }
        }
      }
    }
  }

  getFreeWorker(workerType: string): number {
    for (let i = 0; i < this.workersBusy.length; i++) {
      if (!this.workersBusy[i]) {
        return i;
      }
    }
    if (this.workers.length < this.maxWorkers) {
      return this.createNewWorker(workerType);
    }
  }

  async processQueue() {
    if (this.queue.length > 0) {
      const workerType = this.queue[0].message.workerType;
      const workerIndex = this.getFreeWorker(workerType);
      if (workerIndex !== undefined) {
        await this.runJob(workerIndex);
      }
    }
  }

  async postMessage(message: WorkerMessage, callback?: (data: any) => void) {
    this.queue.push({ message: message, callback: callback });

    const workerIndex = this.getFreeWorker(message.workerType);
    if (workerIndex !== undefined) {
      await this.runJob(workerIndex);
    }
  }

  async postMessagePromise(message: WorkerMessage): Promise<WorkerMessage> {
    const promise: Promise<WorkerMessage> = new Promise((resolve, reject) => {
      this.queue.push({ message: message, resolve: resolve, reject: reject });
    });

    const workerIndex = this.getFreeWorker(message.workerType);
    if (workerIndex !== undefined) {
      await this.runJob(workerIndex);
    }
    return promise;
  }

  async purge(parent: string) {
    for (let i = 0; i < this.workers.length; i++) {
      if (this.workers[i].parent === parent) {
        this.workers[i].terminate();
        this.workers.splice(i, 1);
        this.workersBusy.splice(i, 1);
        i--;
      }
    }
  }
}
