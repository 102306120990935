<div class="tw-flex tw-flex-col tw-gap-2">
  <!--        select file-->
  <div class="tw-flex tw-w-full tw-flex-col tw-gap-2">
    <span class="tw-font-medium">{{ "selectedFile" | i18n }} *</span>

    <!--    temporary, design subject to change, test functionality-->
    <div class="tw-flex tw-w-full tw-gap-4" *ngIf="fileName">
      <div
        class="tw-my-2 tw-w-fit tw-cursor-pointer tw-flex-nowrap tw-rounded-full tw-text-neutral-300 tw-underline"
        (click)="fileSelector.click()"
      >
        {{ fileName }}
      </div>
      <div
        class="tw-w-fit tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-p-2 tw-text-neutral-300 tw-shadow-button md:tw-hidden"
        (click)="onFullPreviewClick()"
      >
        full preview
      </div>
    </div>

    <!--          outer-->
    <div
      class="tw-h-24 tw-w-full tw-cursor-pointer tw-rounded-xl tw-bg-neutral-50 tw-p-2 tw-shadow-inner"
      [ngClass]="{
        'tw-border-solid tw-border-danger ': errorMessage,
        'tw-h-48 tw-resize-y tw-overflow-auto': currentFile
      }"
      (click)="!currentFile && fileSelector.click()"
      (dragover)="!currentFile && onDragOver($event)"
      (dragleave)="!currentFile && onDragLeave($event)"
      (drop)="!currentFile && onDrop($event)"
    >
      <input
        #fileSelector
        type="file"
        id="file"
        class="form-control-file"
        name="file"
        (change)="fileSelected($event)"
        [accept]="fileTypeAllowed"
        hidden
      />
      <!--            dot line -->
      <div
        class="tw-inset-0 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-neutral-300 tw-opacity-60"
        [ngClass]="{
          'tw-border tw-border-dashed tw-border-neutral-300': !currentFile
        }"
      >
        <div *ngIf="fileName; else noFile" class="tw-h-full tw-w-full tw-rounded-xl tw-bg-white">
          <app-file-preview [data]="{ file: currentFile }" class="tw-h-full tw-w-full">
          </app-file-preview>
        </div>

        <ng-template #noFile>
          <span class="tw-pointer-events-none tw-font-medium tw-underline tw-opacity-60">
            {{ "clickOrDragFile" | i18n }}
          </span>
          <span class="tw-pointer-events-none tw-opacity-60">
            {{ "fileUploadLimit" | i18n }}
          </span>
          <span class="tw-pointer-events-none tw-opacity-60">
            {{ "cabinetfileTypes" | i18n }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="mat-error">{{ errorMessage | i18n }}</div>
</div>
