import { Component, EventEmitter, Input, Output } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";

@Component({
  selector: "app-item-display",
  templateUrl: "./item-display.component.html",
})
export class ItemDisplayComponent {
  @Input() item: any;
  @Output() editFunction: EventEmitter<any> = new EventEmitter();
  @Output() removeFunction: EventEmitter<any> = new EventEmitter();
  private buttonStyle =
    "btn-container tw-mr-3 tw-flex tw-h-[30px] tw-cursor-pointer tw-items-center tw-justify-between tw-gap-[6px] tw-rounded-[8px] tw-border tw-border-blue-200 tw-bg-[#FCFCFC] tw-px-3 tw-py-2 sm:tw-mb-1 sm:tw-mr-0 sm:tw-block sm:tw-w-auto";

  constructor(private i18nService: I18nService) {}

  editTransaction = new ActionButton({
    text: this.i18nService.t("edit"),
    icon: "add-entry",
    class: "neutral",
    fullClass: this.buttonStyle,
    onClick: () => this.editFunctionEmitter(this.item),
  });

  removeTransaction = new ActionButton({
    text: this.i18nService.t("delete"),
    icon: "trash",
    class: "neutral",
    fullClass: this.buttonStyle,
    onClick: () => this.removeFunctionEmitter(this.item),
  });

  editFunctionEmitter(item: any) {
    this.editFunction.emit(item.templateData);
  }

  removeFunctionEmitter(item: any) {
    this.removeFunction.emit(item.templateData);
  }
}
