import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";
import { ScenarioSource } from "../../enum/scenario-and-groups.enum";
import { GlossDateType } from "../../types/gloss-date-types";
import { Estimate } from "../blobby/estimate.data";
import { Scenario } from "../blobby/scenario.data";
import { TransactionFilter } from "../scenario-transaction-filter.data";

export class ScenarioGroupResponse extends BaseResponse {
  _id: string;
  name: string;
  symbol: string;
  source: string;
  anchorPoint: GlossDateType;
  scenarios: Scenario[];
  userDefinedEstimates: Estimate[];
  transactionFilters: TransactionFilter;

  get id(): string {
    return this._id;
  }

  constructor(response: any) {
    super(response);
    this._id = this.getResponseProperty("id");
    this.name = this.getResponseProperty("name");
    this.symbol = this.getResponseProperty("symbol");
    this.anchorPoint = this.getResponseProperty("anchorPoint");
    this.scenarios = this.getResponseProperty("scenarios");
    this.userDefinedEstimates = this.getResponseProperty("userDefinedEstimates");
    this.transactionFilters = this.getResponseProperty("transactionFilters");
    this.source = this.getResponseProperty("source")
      ? this.getResponseProperty("source")
      : ScenarioSource.system;
  }
}
