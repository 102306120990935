import {
  buildDataString,
  parseWebauthnJson,
} from "../../../../../apps/ironfly-web/src/connectors/common-webauthn";
import { ApiService } from "../../abstractions/api.service";
import { AppIdService } from "../../abstractions/appId.service";
import { AuthService } from "../../abstractions/auth.service";
import { CryptoService } from "../../abstractions/crypto.service";
import { LogService } from "../../abstractions/log.service";
import { MessagingService } from "../../abstractions/messaging.service";
import { PlatformUtilsService } from "../../abstractions/platformUtils.service";
import { StateService } from "../../abstractions/state.service";
import { TokenService } from "../../abstractions/token.service";
import { TwoFactorService } from "../../abstractions/twoFactor.service";
import { TwoFactorProviderType } from "../../enums/twoFactorProviderType";
import { BiometricsLogInCredentials } from "../../models/domain/log-in-credentials";
import { SymmetricCryptoKey } from "../../models/domain/symmetric-crypto-key";
import { BiometricsTokenRequest } from "../../models/request/identity-token/biometrics-token.request";

import { LogInStrategy } from "./logIn.strategy";

export class BiometricsLoginStrategy extends LogInStrategy {
  get email() {
    return this.tokenRequest.email;
  }

  tokenRequest: BiometricsTokenRequest;

  private localHashedPassword: string;
  private key: SymmetricCryptoKey;

  constructor(
    cryptoService: CryptoService,
    apiService: ApiService,
    tokenService: TokenService,
    appIdService: AppIdService,
    platformUtilsService: PlatformUtilsService,
    messagingService: MessagingService,
    logService: LogService,
    stateService: StateService,
    twoFactorService: TwoFactorService,
    private authService: AuthService
  ) {
    super(
      cryptoService,
      apiService,
      tokenService,
      appIdService,
      platformUtilsService,
      messagingService,
      logService,
      stateService,
      twoFactorService
    );
  }

  async onSuccessfulLogin() {
    await this.cryptoService.setKey(this.key);
    // TODO: also set the key hash
    // await this.cryptoService.setKeyHash(this.localHashedPassword);
  }

  async logIn(credentials: BiometricsLogInCredentials) {
    const { email } = credentials;
    const type = TwoFactorProviderType.WebAuthn;

    const eml = email.trim().toLowerCase();
    try {
      const preloginResponse = await this.apiService.postPrelogin({ email: eml });
      if (!preloginResponse || !preloginResponse.twoFactorProviders[type]) {
        throw new Error("Webauthn not available");
      }
      await this.stateService.setBiometricFingerprintValidated(true);
      const cred = preloginResponse.twoFactorProviders[type];
      const assertion = (await navigator.credentials.get({
        publicKey: parseWebauthnJson(JSON.stringify(cred)),
      })) as PublicKeyCredential;
      await this.stateService.setBiometricFingerprintValidated(true);

      this.key = await this.cryptoService.getBiometricsKey(
        // only use rpidHash of the authenticator data, windows hello does not like the whole authenticator data
        (assertion.response as AuthenticatorAssertionResponse).authenticatorData.slice(0, 32),
        email
      );

      if (this.key == null) {
        throw new Error("biometics key not found in local storage");
      }

      const token = buildDataString(assertion);
      this.tokenRequest = new BiometricsTokenRequest(
        email,
        { token, provider: 7, remember: false },
        await this.buildDeviceRequest()
      );
    } catch (e) {
      if (e == null || e.statusCode !== 404) {
        throw e;
      }
    }

    return this.startLogIn();
  }
}
