<div
  class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-b-lg tw-bg-white tw-p-6 md:tw-p-4"
  (click)="onClick.emit()"
>
  <div class="tw-flex tw-items-center md:tw-ml-2">
    <div class="tw-w-[180px]">
      <div
        class="tw-w-24 tw-text-lg tw-font-medium md:tw-flex md:tw-w-full md:tw-whitespace-nowrap"
      >
        {{ title }}
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-gap-1 md:tw-hidden">
      <ng-content></ng-content>
    </div>
  </div>
  <div>
    <icon [name]="'right-arrow'" [size]="7"></icon>
  </div>
</div>
