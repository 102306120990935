<div
  class="tw-flex tw-h-auto tw-w-full tw-min-w-[350px] tw-flex-col tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-px-6 tw-py-4 md:tw-w-full md:tw-min-w-[300px] md:tw-py-4"
>
  <div class="tw-flex tw-gap-2">
    <span>
      <icon [name]="'info-danger'" [size]="5"></icon>
    </span>
    <div class="tw-flex tw-flex-col tw-gap-2.5">
      <span class="tw-whitespace-nowrap tw-font-semibold tw-text-danger">
        {{ title | i18n }}
      </span>
      <div>
        <p class="tw-my-0">{{ body | i18n }}</p>
      </div>
    </div>
  </div>
</div>
