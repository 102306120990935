<app-spinner [showSpinner]="accessLoader"></app-spinner>
<app-spinner [showSpinner]="spinnerLoader"></app-spinner>

<app-basiq-progress-bar *ngIf="loading && !inWizard"></app-basiq-progress-bar>
<div class="content">
  <div class="tw-flex" id="account-table-actions-container" *ngIf="!isMobile()">
    <div class="tw-ml-auto tw-flex" *appRoleScope="RoleScope.GLOSS">
      <!--      <button-->
      <!--        [disabled]="!isEntry()"-->
      <!--        [ngClass]="{ 'tw-opacity-60': !isEntry() }"-->
      <!--        class="account-table-action-button"-->
      <!--        type="button"-->
      <!--        (click)="addBalanceSelected()"-->
      <!--      >-->
      <!--        <svg class="tw-h-6 tw-w-6">-->
      <!--          <use [attr.xlink:href]="'images/sprite.svg#add-entry'" />-->
      <!--        </svg>-->
      <!--        {{ "addBalance" | i18n }}-->
      <!--      </button>-->

      <!--      <button-->
      <!--        [disabled]="!isEdit()"-->
      <!--        [ngClass]="{ 'tw-opacity-60': !isEdit() }"-->
      <!--        class="account-table-action-button"-->
      <!--        type="button"-->
      <!--        (click)="editSelected()"-->
      <!--      >-->
      <!--        <svg class="tw-h-6 tw-w-6">-->
      <!--          <use [attr.xlink:href]="'images/sprite.svg#edit'" />-->
      <!--        </svg>-->
      <!--        {{ "edit" | i18n }}-->
      <!--      </button>-->

      <!--      <button-->
      <!--        [disabled]="!isDelete()"-->
      <!--        [ngClass]="{ 'tw-opacity-60': !isDelete() }"-->
      <!--        class="account-table-action-button"-->
      <!--        type="button"-->
      <!--        (click)="deleteSelected()"-->
      <!--      >-->
      <!--        <svg class="tw-h-6 tw-w-6">-->
      <!--          <use [attr.xlink:href]="'images/sprite.svg#trash'" />-->
      <!--        </svg>-->
      <!--        {{ "delete" | i18n }}-->
      <!--      </button>-->
      <!-- todo this is the old sync way, removing as the Auto Sync is temporary moved on the side -->
      <!--      <button-->
      <!--        *appRoleScope="RoleScope.DATA_AGGREGATOR"-->
      <!--        class="account-table-action-button"-->
      <!--        type="button"-->
      <!--        (click)="syncAllAccounts()"-->
      <!--      >-->
      <!--        <mat-icon class="transaction-action-button-icon" aria-hidden="true">sync</mat-icon>-->
      <!--        Sync All -->
      <!--      </button>-->

      <button
        class="account-table-action-button"
        type="button"
        (click)="openCreationOptionsModal()"
      >
        <svg class="tw-h-6 tw-w-6">
          <use [attr.xlink:href]="'images/sprite.svg#add'" />
        </svg>
        {{ "addNew" | i18n }}
      </button>
    </div>
  </div>
  <div class="tw-mb-5 tw-flex tw-justify-around" *ngIf="isMobile()">
    <app-gloss-button
      [options]="syncAllButton"
      *appRoleScope="RoleScope.DATA_AGGREGATOR"
    ></app-gloss-button>
    <app-gloss-button [options]="addNewButton"></app-gloss-button>
  </div>
  <div *ngIf="booksView" class="table-container tw-mb-[100px] tw-flex tw-h-[500px] tw-flex-col">
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [columnDefs]="colDefs"
      [rowModelType]="rowModelType"
      [rowSelection]="rowSelection"
      [columnMenu]="columnMenu"
      [gridOptions]="gridOptions"
      [context]="context"
      [autoSizeStrategy]="autoSizeStrategy"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      [getContextMenuItems]="getContextMenuItems"
    ></ag-grid-angular>
  </div>
</div>
