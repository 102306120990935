import { Component, Input, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

import { IMainProgressBar } from "../../../models/interfaces/main-progress-bar";
import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

@Component({
  selector: "main-progress-bar",
  templateUrl: "main-progress-bar.component.html",
})
export class MainProgressBarComponent implements OnDestroy {
  progressbar: IMainProgressBar;
  private destroy$ = new Subject<void>();
  @Input() isFirstTimeUser: boolean;

  constructor(protected mainProgressBar: MainProgressBar) {
    this.progressbar = mainProgressBar;
  }

  getBackgroundStyle() {
    const percentage = this.mainProgressBar.getLoadingPercentage();
    return {
      background: `conic-gradient(#00C4C4 ${percentage * 3.6}deg, #F5F5F5 ${percentage}deg)`,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
