export const removeSpace = (string: string): string => {
  return string?.toLowerCase().replace(/\s/g, "");
};

export const addAsterisk = (label: string, isRequired: boolean): string => {
  return isRequired ? label + " *" : label;
};

export const extractFileType = (file: File) => {
  return file.name.split(".").pop();
};
