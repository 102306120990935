<div class="step-indicator-container">
  <span
    class="step-text-indicator"
    [innerHTML]="'stepOfSteps' | i18n : currentIndex + 1 : steps.length"
  ></span>
  <div class="tw-mt-4 tw-h-0.5 tw-w-full tw-bg-neutral-50">
    <div
      class="tw-h-0.5 tw-bg-primary"
      [style.width.%]="((currentIndex + 1) / steps.length) * 100"
    ></div>
  </div>
</div>
