import { Component, Input } from "@angular/core";

import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { InstitutionInterest } from "@bitwarden/web-vault/app/models/types/institution.type";

@Component({
  selector: "best-scenario-carousel-item",
  templateUrl: "./best-scenario-carousel-item.component.html",
})
export class BestScenarioCarouselItemComponent {
  @Input() accountName: string;
  @Input() earningsCard: EarningDataCard;
  @Input() rateInUse: InstitutionInterest;
}
