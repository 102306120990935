<div class="tw-flex">
  <div
    (click)="toggle()"
    class="tw-mr-2 tw-h-4 tw-w-4 tw-rounded tw-shadow-inner
    {{ checked && 'tw-bg-' + background }}"
  >
    <ng-container *ngIf="checked">
      <icon [size]="4" [name]="'check-only'"></icon>
    </ng-container>
  </div>
  <span>{{ title }}</span>
</div>
