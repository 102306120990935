<ng-container>
  <div>
    <span
      class="tw-flex tw-text-xs tw-font-normal tw-leading-[125%] tw-tracking-[0.005em] tw-text-neutral-800"
    >
      {{ "highestInterestBankAccount" | i18n }}
    </span>
    <span class="tw-flex tw-gap-2 tw-text-xs tw-tracking-[0.005em] tw-text-neutral-800">
      {{ "lastReviewed" | i18n }} :
      <span class="tw-text-neutral-600 tw-underline">{{ date }}</span>
    </span>
  </div>
</ng-container>
