<div
  class="tw-relative tw-h-6 tw-w-[52px] tw-cursor-pointer tw-rounded-xl tw-p-1 tw-shadow-inner
    {{ isEnabled ? 'tw-bg-primary' : 'tw-bg-neutral-300' }}"
  (click)="onToggleClick()"
>
  <div
    class="tw-absolute tw-z-10 tw-h-4 tw-w-4 tw-rounded-full tw-bg-light {{
      isEnabled && 'tw-left-[34px]'
    }}"
  ></div>
</div>
