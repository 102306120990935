import { Injectable } from "@angular/core";

import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";

import { RoleAccessData } from "../../models/data/role-access.data";

@Injectable({
  providedIn: "root",
})
export class RoleAccessService {
  private roleAccess: RoleAccessData = new RoleAccessData();

  setRoleScope(roleScope: string) {
    const scope = roleScope.split(" ");
    this.roleAccess.setScope(scope);
  }

  setRoleClaim(roleClaims: Record<string, any>) {
    this.roleAccess.setClaims(roleClaims);
  }

  getRoleAccess() {
    return this.roleAccess;
  }

  isBetaUser() {
    this.roleAccess = this.getRoleAccess();
    const scope = this.roleAccess.getScope();
    return scope.includes(RoleScope.BETA_ACCESS);
  }
}
