import { CurrencyPipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { statementLinkedAccountWithBalances } from "@bitwarden/web-vault/app/components/add-balance/add-balance.component";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { RadioOption } from "@bitwarden/web-vault/app/components/buttons/radio-button/radio-button.component";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";

@Component({
  selector: "app-account-selector",
  templateUrl: "./account-selector.component.html",
  providers: [CurrencyPipe],
})
export class AccountSelectorComponent implements OnInit {
  accountViews: AccountView[] = [];
  accountOptions: RadioOption[] = [];
  selectedAccountId: string;
  selectedAccount: AccountView;
  linkedAccountData: statementLinkedAccountWithBalances[] = this.data.accountBalanceData;

  constructor(
    public dialogRef: MatDialogRef<AccountSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeDialogue: CallableFunction;
      finishUploadForm: CallableFunction;
      accountBalanceData: statementLinkedAccountWithBalances[];
      unavailableAccountOptions: AccountView[];
    },
    private globalService: GlobalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.accountViews = this.linkedAccountData.map((item) => item.account);
    this.accountOptions = this.setUpViewsOptions(this.accountViews);
  }

  viewToOption(view: AccountView) {
    const accountData = this.linkedAccountData.find((item) => item.account.id === view.id);
    return {
      templateData: accountData,
      value: view.id,
      selected: false,
      disabled: this.data.unavailableAccountOptions.some((option) => option.id === view.id),
      labelType: "template",
    };
  }

  setUpViewsOptions(views: AccountView[]): RadioOption[] {
    return views.map((view) => this.viewToOption(view));
  }

  skipAddTransaction = new ActionButton({
    class: "secondary",
    text: "Not for now",
    type: "skip",
    onClick: this.skip.bind(this),
  });

  addTransaction = new ActionButton({
    text: "Add Transaction",
    type: "submit",
    onClick: this.submit.bind(this),
  });

  submit() {
    if (this.selectedAccount) {
      this.data.closeDialogue();
      this.dialogRef.close({ data: this.selectedAccount });
    } else {
      this.globalService.showErrorMessage("error", "selectAccountToAddTransaction");
      this.addTransaction.enableButton(true);
    }
  }

  skip() {
    this.skipAddTransaction.enableButton(false);
    this.addTransaction.enableButton(false);
    this.data.finishUploadForm();
  }

  selectAccount(value: string) {
    this.selectedAccountId = value;
    this.accountOptions.filter((option) => (option.selected = option.value === value));
    this.selectedAccount = this.accountViews.find((view) => view.id === value);
  }
}
