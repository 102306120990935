import { ClientType } from "../../../enums/clientType";
import { Utils } from "../../../misc/utils";
import { DeviceRequest } from "../device.request";

import { TokenTwoFactorRequest } from "./token-two-factor.request";
import { TokenRequest } from "./token.request";

export class BiometricsTokenRequest extends TokenRequest {
  constructor(
    public email: string,
    protected twoFactor: TokenTwoFactorRequest,
    device?: DeviceRequest
  ) {
    super(twoFactor, device);
  }

  toIdentityToken(clientId: ClientType) {
    const identityToken = super.toIdentityToken(clientId);

    identityToken.username = this.email;
    identityToken.grant_type = "biometrics";

    return identityToken;
  }

  alterIdentityTokenHeaders(headers: Headers) {
    headers.set("Auth-Email", Utils.fromUtf8ToUrlB64(this.email));
  }
}
