import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { format } from "date-fns";
import _ from "lodash";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { listTimeZones } from "timezone-support";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { currencies } from "@bitwarden/web-vault/app/gloss/manage/manage-preferences/currencies";
import "./reference-add-edit.scss";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";

@Component({
  selector: "app-reference-add-edit",
  templateUrl: "./reference-add-edit.component.html",
  styles: ["reference-add-edit.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReferenceAddEditComponent implements OnInit {
  preferredCurrency = "";
  currencies: string[] = currencies;
  filteredCurrencies$: Observable<string[]>;
  timeZones: string[] = [];
  selectedTimeZone = "";
  form: FormGroup;
  loading = true;
  editMode = false;
  title: string;
  referenceId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reference: ReferenceData;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
    },
    private referenceService: ReferenceDataService,
    private dialogRef: MatDialogRef<ReferenceAddEditComponent>,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    if (data.reference) {
      if (data.reference.id) {
        this.referenceId = data.reference.id;
      }
    }
  }

  ngOnInit(): void {
    this.initializeForm();
    this.editMode = this.referenceId != null;
    if (this.editMode) {
      this.title = this.globalService.getLocaleValue("editReferenceData");
    } else {
      this.title = this.globalService.getLocaleValue("createNewReferenceData");
    }
    this.timeZones = this.getAllTimeZones();
    this.setupCurrencyFilter();
    this.loading = false;
  }

  initializeForm() {
    // Get the user's timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.form = this.formBuilder.group({
      date: [new Date(), Validators.required],
      time: [null],
      timeZone: [userTimezone],
      baseName: [null, Validators.required],
      rate: [0.0],
      preferredCurrency: ["", Validators.required],
    });
  }

  getAllTimeZones(): string[] {
    const allTimeZones = listTimeZones();
    return allTimeZones;
  }

  setupCurrencyFilter() {
    this.filteredCurrencies$ = this.form.get("preferredCurrency").valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(this.currencies, value))
    );
  }

  private filter(filterable: string[], toFilterWith: string): string[] {
    return filterable.filter((inArrayValue) =>
      inArrayValue.toLowerCase().includes(toFilterWith.toString().toLowerCase())
    );
  }

  async submit() {
    this.loading = true;

    try {
      if (this.form.valid) {
        if (this.editMode) {
          await this.updateReference();
        } else {
          await this.createReference();
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
    this.loading = false;
  }

  async createReference() {
    const newReferenceDate = format(this.form.get("date").value, "yyyy-MM-dd");
    const newReferenceBase = this.form.get("baseName").value;
    const prefCurrency = this.form.get("preferredCurrency").value;
    const rate = this.form.get("rate").value;
    const newReferenceSymbols: { [key: string]: number } = { [prefCurrency]: rate };

    const newResponse = {
      date: {
        date: newReferenceDate,
        time: this.form.get("time").value,
        tz: this.form.get("timeZone").value,
      },
      base: newReferenceBase,
      symbols: newReferenceSymbols,
    };
    const newReference = new ReferenceData(new ReferenceDataResponse(newResponse));
    try {
      const createdReference = await this.referenceService.import([newReference]);
      if (createdReference) {
        await this.data.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateReference() {
    // todo update
    const clonedReference = _.cloneDeep(this.data.reference);
    clonedReference.date = this.form.value.date;
  }

  closeDialogue() {
    this.dialogRef.close();
  }
}
