import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ISyncStore } from "@bitwarden/web-vault/app/models/interfaces/sync.interface";
import { AccountState, SyncState } from "@bitwarden/web-vault/app/models/types/general-types";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-connector.service";
import { BasiqInstitutionConnection } from "@bitwarden/web-vault/app/services/syncing/basiq-institution-connection";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";

@Injectable({
  providedIn: "root",
})
export class SyncStore extends ComponentStore<ISyncStore> {
  constructor(private glossSyncService: GlossSyncService) {
    super({
      isInitialState: true,
      isStarted: false,
      isCompleted: false,
      isSyncAccounts: true,
      accountsState: [],
      basiqConnector: null,
      basiqInstitutionConnections: [],
    });

    this.glossSyncService.getLastStatus().then((syncStatus) => {
      this.setAccountStatus(syncStatus.accountStatus);
    });
  }

  readonly isInitialState$: Observable<boolean> = this.select((state) => state.isInitialState);
  readonly isStarted$: Observable<boolean> = this.select((state) => state.isStarted);
  readonly isCompleted$: Observable<boolean> = this.select((state) => state.isCompleted);
  readonly isSyncAccounts$: Observable<boolean> = this.select((state) => state.isSyncAccounts);
  readonly accountsState$: Observable<AccountState[]> = this.select((state) => state.accountsState);
  readonly basiqConnector$: Observable<BasiqConnector> = this.select(
    (state) => state.basiqConnector
  );
  readonly basiqInstitutionConnections$: Observable<BasiqInstitutionConnection[]> = this.select(
    (state) => state.basiqInstitutionConnections
  );

  readonly vm$ = this.select(
    this.isInitialState$,
    this.isStarted$,
    this.isCompleted$,
    this.isSyncAccounts$,
    this.accountsState$,
    this.basiqConnector$,
    this.basiqInstitutionConnections$,
    (
      isInitialState,
      isStarted,
      isCompleted,
      isSyncableAccounts,
      accountStatus,
      basiqConnector,
      basiqInstitutionConnections
    ): SyncState => ({
      isInitialState,
      isStarted,
      isCompleted,
      isSyncableAccounts,
      accountStatus,
      basiqConnector,
      basiqInstitutionConnections,
    })
  );

  readonly getCurrentState = this.select((state) => state);

  readonly setStarted = this.updater((state) => {
    const accountStatus = state.accountsState.map((status) => {
      status.isStarted = true;
      status.isCompleted = false;
      status.point = SyncStatusPoints["started"];
      status.lastSyncedAt = GlossDate.getCurrentGlossDate();
      return status;
    });
    return {
      ...state,
      accountsState: accountStatus,
      isStarted: true,
      isCompleted: false,
      isInitialState: false,
    };
  });

  readonly setIsSyncableAccounts = this.updater((state, isSyncableAccounts: boolean) => ({
    ...state,
    isSyncAccounts: isSyncableAccounts,
    isCompleted: !isSyncableAccounts,
    isInitialState: false,
  }));

  readonly setAccountStatus = this.updater((state, accountStatus: AccountState[]) => {
    return {
      ...state,
      accountsState: accountStatus,
      isCompleted: accountStatus.every((status) => status.isCompleted),
    };
  });

  readonly setLastUpdatedStatus = this.updater((state, accountStatus: AccountState[]) => {
    return {
      ...state,
      accountsState: accountStatus,
      isCompleted: false,
      isInitialState: false,
    };
  });

  readonly updateAccountStatus = this.updater((state, accountStatus: AccountState[]) => {
    const newAccountStatus = state.accountsState.map((status) => {
      const _status = accountStatus.find((newStatus) => newStatus.accountId === status.accountId);
      if (_status) {
        status = _status;
      }

      return status;
    });

    return {
      ...state,
      accountsState: newAccountStatus,
      isCompleted: newAccountStatus.every((status) => status.isCompleted),
    };
  });

  async startSync() {
    const isSyncableAccounts = await this.glossSyncService.isSyncableAccounts();
    if (!isSyncableAccounts) {
      this.setIsSyncableAccounts(isSyncableAccounts);
      /** @Sinan this.updateStateSoUserKnowWhatHappens(); Currently if no auto accounts there will be nothing to sync*/
      return;
    }

    this.setStarted();
    this.glossSyncService.sync();
  }

  async setLastStatus() {
    const lastStatus = await this.glossSyncService.getLastStatus();
    this.setLastUpdatedStatus(lastStatus.accountStatus);
  }
}
