import { Step } from "@bitwarden/web-vault/app/models/types/stepper.types";

export abstract class BaseStepper {
  steps: Step[] = []; /** Steps of the components to be displayed */

  /** Move to next step */
  next() {
    const currentIndex = this.currentIndex();
    if (currentIndex < this.steps.length - 1) {
      this.steps[currentIndex].isVisible = false;
      this.steps[currentIndex + 1].isVisible = true;
    }
  }

  /** Current step's index (starts from 0)*/
  currentIndex(): number {
    const visibleStepIndex = this.steps.findIndex((step) => step.isVisible);
    return visibleStepIndex !== -1 ? visibleStepIndex : 0;
  }

  /** Current step */
  currentStep(): Step {
    return this.steps[this.currentIndex()];
  }

  isLastStep(): boolean {
    return this.currentIndex() === this.steps.length - 1;
  }
}
