import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";

import { InstitutionInterest } from "../../models/types/institution.type";

export function _getAppliedInterest(
  rates: InstitutionInterest[],
  amount: number
): InstitutionInterest {
  const sortedRates = rates.sort((a, b) => a.range - b.range);

  for (const rate of sortedRates) {
    if (amount <= rate.range) {
      return rate;
    }
  }
  return sortedRates.find((rate) => rate.range === -1);
}

export function _getLastDayBalanceKey(groupedBalance: BalanceGrouping): string {
  const groupedBalanceByDay = groupedBalance.granularity["day"];
  const keys = Object.keys(groupedBalanceByDay).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  return keys[keys.length - 1];
}
