<div class="tw-rounded-lg" [ngClass]="customClass">
  <button
    (click)="onAccordionClicked()"
    class="tw-min-h-[54px] tw-w-full tw-cursor-pointer tw-border-none tw-pl-3 tw-transition-all"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
      <ng-content select="[title]"></ng-content>

      <svg
        class="tw-right-2 tw-h-7 tw-w-7 tw-text-black xs:tw-h-5 xs:tw-w-5"
        (click)="toggleContent($event)"
      >
        <use *ngIf="isCollapsed" [attr.xlink:href]="'images/sprite.svg#' + expandIcon"></use>
        <use *ngIf="!isCollapsed" [attr.xlink:href]="'images/sprite.svg#' + collapseIcon"></use>
      </svg>
    </div>
  </button>

  <div *ngIf="!isCollapsed" class="tw-p-3">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
