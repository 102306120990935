import { Injector } from "@angular/core";
import { addDays } from "date-fns";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import {
  FlattenedTransactions,
  FlattenedTransactionsByAccount,
  FlattenedTransactionsByAccountSymbol,
} from "@bitwarden/web-vault/app/models/types/balance.types";
import { BalanceByAccounts } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { DashboardFilter } from "@bitwarden/web-vault/app/models/types/dashboard.types";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { TransactionBalancesWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.message";
import { TransactionBalancesWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.result";
import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

/**
 * TransactionBalances - This class will house all the helper functions that
 * are used to manipulate the current transaction objects into the required
 * objects for the web workers that calculate the balances needed
 */
export class TransactionBalanceHelpers {
  /**
   * createTransactionArrays - creates the flattened transaction arrays for the web worker
   * @param transactions
   * @param filters
   * @param ignoreTimes
   * @param useNormalized
   */
  createTransactionArrays(
    transactions: Array<Transaction>,
    filters: DashboardFilter,
    ignoreTimes = true,
    useNormalized: true
  ): FlattenedTransactions {
    const filteredTransactions = this.filterTransactions(transactions, filters, ignoreTimes);
    return this.buildTransactionArrays(filteredTransactions, useNormalized);
  }

  filterTransactions(
    transactions: Array<Transaction>,
    filters: DashboardFilter,
    ignoreTimes = true
  ): Array<Transaction> {
    const filteredTransactions: Array<Transaction> = [];

    let includeEmptyCategories = false;
    if (filters?.categories) {
      includeEmptyCategories = filters.categories.some((cat) => cat.name === "No Category");
    }

    let includeEmptyClassifications = false;
    if (filters?.classifications) {
      includeEmptyClassifications = filters.classifications.some(
        (cat) => cat.name === "No Classification"
      );
    }

    let filterStartDate;
    if (filters?.startDate) {
      // zero out the start Date time
      filterStartDate = new Date(filters.startDate.getTime());
      if (ignoreTimes) {
        filterStartDate.setHours(0, 0, 0, 0);
      }
    }

    let filterEndDate;
    if (filters?.endDate) {
      // zero out the end Date time
      filterEndDate = new Date(filters.endDate.getTime());
      filterEndDate = addDays(filters.endDate, 1);
      if (ignoreTimes) {
        filterEndDate.setHours(0, 0, 0, 0);
      }
    }

    for (const transaction of transactions) {
      // run transaction through filters
      if (filters?.startDate && transaction.transactionDate.date < filterStartDate) {
        continue;
      }
      if (filters?.endDate && transaction.transactionDate.date >= filterEndDate) {
        // Because the transaction array is already sorted in ascending order
        // we can assume any transactions after this one can also be abandoned
        break;
      }
      if (
        filters?.categories &&
        !this.filterCategories(transaction, filters.categories, includeEmptyCategories)
      ) {
        continue;
      }
      if (
        filters?.classifications &&
        !this.filterClassifications(
          transaction,
          filters.classifications,
          includeEmptyClassifications
        )
      ) {
        continue;
      }
      if (filters?.directions && !this.filterDirection(transaction, filters.directions)) {
        continue;
      }
      if (filters?.symbols && !this.filterSymbol(transaction, filters.symbols)) {
        continue;
      }
      if (filters?.accounts && !this.filterAccounts(transaction, filters.accounts)) {
        continue;
      }

      filteredTransactions.push(transaction);
    }
    return filteredTransactions;
  }

  /**
   * filterAccounts - filters the transaction based on the account
   * @param transaction
   * @param accounts
   */

  filterAccounts(transaction: Transaction, accounts: Array<Book>): boolean {
    return accounts.some((account) => account.id === transaction.accountId);
  }

  /**
   * filterCategories - filters the transaction based on the category
   * @param transaction
   * @param categories
   * @param includeEmptyCategories
   */
  filterCategories(
    transaction: Transaction,
    categories: Array<Category>,
    includeEmptyCategories: boolean
  ): boolean {
    const tCats = transaction.categories;
    return categories.some((category) => {
      if (includeEmptyCategories && tCats.length === 0) {
        return true;
      } else {
        return tCats.some((tCat) => tCat.categoryId === category.id);
      }
    });
  }

  /**
   * filterClassifications - filters the transaction based on the classification
   * @param transaction
   * @param classifications
   * @param includeEmptyClassifications
   */
  filterClassifications(
    transaction: Transaction,
    classifications: Array<Classification>,
    includeEmptyClassifications: boolean
  ): boolean {
    const tClasses = transaction.classifications;
    return classifications.some((classification) => {
      if (includeEmptyClassifications && tClasses.length === 0) {
        return true;
      } else {
        return tClasses.some((tClss) => tClss.classificationId === classification.id);
      }
    });
  }

  /**
   * filterDirection - filters the transaction based on the direction
   * @param transaction
   * @param directions
   */
  filterDirection(transaction: Transaction, directions: Array<TransactionDirection>): boolean {
    return directions.some((direction) => transaction.direction === direction);
  }

  /**
   * filterSymbol - filters the transaction based on the symbol
   * @param transaction
   * @param symbols
   */
  filterSymbol(transaction: Transaction, symbols: Array<string>): boolean {
    if (symbols.length === 0) {
      return true;
    }
    return symbols.includes(transaction.quantity.actualQuantity.symbol);
  }

  /**
   * buildTransactionArrays - builds the transaction arrays for the web worker
   * @param transactions
   * @param useNormalized
   * @param ignoreTime
   */
  buildTransactionArrays(
    transactions: Array<Transaction>,
    useNormalized = true,
    ignoreTime = true
  ): FlattenedTransactions {
    const transactionResult: FlattenedTransactions = {
      transactionDates: [],
      transactionAmounts: [],
    };

    if (!Array.isArray(transactions)) {
      return transactionResult;
    }

    for (const transaction of transactions) {
      const transactionDate = new Date(transaction.transactionDate.date);
      if (ignoreTime) {
        transactionDate.setHours(0, 0, 0, 0);
      }
      const unixTime = Date.parse(transactionDate.toUTCString()) / 1000;
      // const unixTime = (Date.parse(transaction.transactionDate.dateString.concat("Z")) / 1000);
      transactionResult.transactionDates.push(unixTime);
      if (useNormalized) {
        transactionResult.transactionAmounts.push(transaction.valuation._normalizedValue.amount);
      } else {
        transactionResult.transactionAmounts.push(transaction.quantity.actualQuantity.amount);
      }
    }
    return transactionResult;
  }

  /**
   * sortTransactionsByAccounts - sorts the transactions by account and flattens the transactions
   * into the required format for the web worker
   * @param transactions
   * @param filters
   * @param ignoreTimes
   * @param useNormalized
   */
  sortTransactionsByAccounts(
    transactions: Array<Transaction>,
    filters?: DashboardFilter,
    ignoreTimes = true,
    useNormalized = true
  ): FlattenedTransactionsByAccount {
    const transactionByAccountResults: FlattenedTransactionsByAccount = {};
    const filteredTransactions = filters
      ? this.filterTransactions(transactions, filters, ignoreTimes)
      : transactions;

    for (const transaction of filteredTransactions) {
      if (!transactionByAccountResults?.[transaction.accountId]) {
        transactionByAccountResults[transaction.accountId] = {
          transactionDates: [],
          transactionAmounts: [],
        };
      }
      transactionByAccountResults[transaction.accountId].transactionDates.push(
        Date.parse(transaction.transactionDate.date.toUTCString()) / 1000
      );
      if (useNormalized) {
        transactionByAccountResults[transaction.accountId].transactionAmounts.push(
          transaction.valuation._normalizedValue.amount
        );
      } else {
        let transactionAmount = transaction.quantity.actualQuantity.amount;
        if (transaction.direction === TransactionDirection.Out && transactionAmount > 0) {
          transactionAmount = -1 * transactionAmount;
        }
      }
    }
    return transactionByAccountResults;
  }

  /**
   * sortTransactionsByAccounts - sorts the transactions by account and flattens the transactions
   * into the required format for the web worker
   * @param transactions // assumes the transactions are already sorted by date in ascending order
   * @param filters
   * @param ignoreTimes
   * @param useNormalized
   */
  sortTransactionsByAccountsSymbols(
    transactions: Array<Transaction>,
    filters?: DashboardFilter,
    ignoreTimes = true,
    useNormalized = true
  ): FlattenedTransactionsByAccountSymbol {
    const transactionByAccountResults: FlattenedTransactionsByAccountSymbol = {};
    const filteredTransactions = filters
      ? this.filterTransactions(transactions, filters, ignoreTimes)
      : transactions;

    for (const transaction of filteredTransactions) {
      if (!transactionByAccountResults?.[transaction.accountId]) {
        transactionByAccountResults[transaction.accountId] = {};
      }
      if (
        !transactionByAccountResults[transaction.accountId]?.[
          transaction.quantity.actualQuantity.symbol
        ]
      ) {
        transactionByAccountResults[transaction.accountId][
          transaction.quantity.actualQuantity.symbol
        ] = {
          transactionDates: [],
          transactionAmounts: [],
        };
      }
      transactionByAccountResults[transaction.accountId][
        transaction.quantity.actualQuantity.symbol
      ].transactionDates.push(Date.parse(transaction.transactionDate.date.toUTCString()) / 1000);
      if (useNormalized) {
        transactionByAccountResults[transaction.accountId][
          transaction.quantity.actualQuantity.symbol
        ].transactionAmounts.push(transaction.valuation.normalizedValue.amount);
      } else {
        let transactionAmount = transaction.quantity.actualQuantity.amount;
        if (transaction.direction === TransactionDirection.Out && transactionAmount > 0) {
          transactionAmount = -1 * transactionAmount;
        }
        transactionByAccountResults[transaction.accountId][
          transaction.quantity.actualQuantity.symbol
        ].transactionAmounts.push(transactionAmount);
      }
    }
    return transactionByAccountResults;
  }

  /**
   * createStartingTransactionsFromBalance - Given a list of starting balances for accounts and symbols, create the
   * corresponding starting transactions
   */
  async createStartingTransactionsFromBalance(
    startDate: Date,
    startingBalances: BalanceByAccounts,
    description: string,
    injector: Injector
  ): Promise<Array<Transaction>> {
    const transactions: Array<Transaction> = [];
    const transactionNormalizeService = injector.get(TransactionNormalizeService);
    const classificationService = injector.get(ClassificationService);
    const categoryService = injector.get(CategoryService);
    const defaultSplitClassification =
      await classificationService.createDefaultSplitClassification();

    for (const accountID in startingBalances) {
      const symbolBalances = startingBalances[accountID];
      for (const symbol in symbolBalances) {
        const symbolAmount = symbolBalances[symbol];
        const mockTransaction = await this.createFakeTransaction(
          accountID,
          symbolAmount,
          symbol,
          startDate,
          description,
          transactionNormalizeService,
          defaultSplitClassification,
          categoryService
        );
        transactions.push(mockTransaction);
      }
    }
    return transactions;
  }

  // todo this should be moved in the TransactionResponse class so it can get updated if the model changes
  async createFakeTransaction(
    accountID: string,
    symbolAmount: number,
    symbol: string,
    transactionDate: Date,
    description: string,
    transactionNormalizeService: TransactionNormalizeService,
    defaultSplitClassification: SplitClassificationType[],
    categoryService: CategoryService,
    linkedTo?: Array<string>
  ): Promise<Transaction> {
    const fakeTransactionResponse = new TransactionResponse({
      __v: 1,
      accountId: accountID,
      description: description,
      quantity: symbolAmount,
      symbol: symbol,
      date: transactionDate.toDateString(),
      definition: TransactionStatusEnum.fake,
      _linkedTo: linkedTo,
    });
    const fakeTransaction = new Transaction(fakeTransactionResponse);
    fakeTransaction.classifications = defaultSplitClassification;
    fakeTransaction.categories = await categoryService.createDefaultSplitCategory();

    await transactionNormalizeService.normalizeImportedTransaction(fakeTransaction);

    // set the currency of the quantity
    if (!fakeTransaction.quantity.currency && fakeTransactionResponse.valuation.value.symbol) {
      fakeTransaction.quantity.currency = fakeTransactionResponse.valuation.value.symbol;
    }
    // set the valuation price
    if (!fakeTransaction.valuationPrice && fakeTransactionResponse.valuation.symbolValue) {
      fakeTransaction.valuationPrice = fakeTransactionResponse.valuation.symbolValue;
    }
    return fakeTransaction;
  }

  addTransactionToBalanceByAccounts(
    balanceByAccounts: BalanceByAccounts,
    transaction: Transaction
  ) {
    const account = transaction.accountId;
    const symbol = transaction.quantity.actualQuantity.symbol;

    if (!balanceByAccounts?.[account]) {
      balanceByAccounts[account] = {};
    }
    let transactionAmount = transaction.quantity.actualQuantity.amount;
    if (transaction.direction === TransactionDirection.Out && transactionAmount > 0) {
      transactionAmount = -1 * transactionAmount;
    }
    if (balanceByAccounts?.[account]?.[symbol]) {
      balanceByAccounts[account][symbol] += transactionAmount;
    } else {
      balanceByAccounts[account][symbol] = transactionAmount;
    }
  }

  async buildTransactionTableBalances(
    webWorkerQueue: WebWorkerQueue,
    id: string,
    transactionDates: ArrayBuffer,
    transactionAmounts: ArrayBuffer
  ): Promise<WorkerMessage> {
    const balanceRequest = new TransactionBalancesWorkerMessage(
      id,
      "getTransactionalBalances",
      transactionDates,
      transactionAmounts
    );
    return await webWorkerQueue.postMessagePromise(balanceRequest);
  }

  async getNormalisedBalancesByAccounts(
    webWorkerQueue: WebWorkerQueue,
    id: string,
    graphData: Array<GraphDataSet>,
    transactions: Array<Transaction>
  ): Promise<Record<number, Record<string, number>>> {
    const flattenedTransactionsByAccount = this.sortTransactionsByAccounts(
      transactions,
      null, // note graphTransactions are already pre-filtered
      true,
      true
    );

    const balanceByAccounts: Record<number, Record<string, number>> = {};
    const requiredBalanceDates = this.getDatesFromGraphData(graphData);

    const promises: Promise<WorkerMessage>[] = [];

    for (const accountId in flattenedTransactionsByAccount) {
      const transactionDates = flattenedTransactionsByAccount[accountId].transactionDates;
      const transactionsAmounts = flattenedTransactionsByAccount[accountId].transactionAmounts;

      const transactionDatesBuffer = new Uint32Array(transactionDates).buffer;
      const transactionAmountsBuffer = new Float64Array(transactionsAmounts).buffer;

      const balanceRequest = new TransactionBalancesWorkerMessage(
        id,
        "getAccountBalances",
        transactionDatesBuffer,
        transactionAmountsBuffer,
        0,
        new Uint32Array(requiredBalanceDates).buffer
      );

      promises.push(
        webWorkerQueue.postMessagePromise(balanceRequest).then(
          (workerMessage: TransactionBalancesWorkerResult) => {
            if (workerMessage?.balanceAmounts) {
              const balanceArray = new Float64Array(workerMessage.balanceAmounts);
              const balanceDates = new Uint32Array(workerMessage.balanceDates);

              if (balanceArray.length > 0) {
                this.addAccountBalancesToBalanceByAccounts(
                  balanceByAccounts,
                  accountId,
                  balanceArray,
                  balanceDates
                );
              }
            }
            return Promise.resolve(workerMessage);
          },
          (error: Error) => {
            return Promise.reject(error);
          }
        )
      );
    }
    await Promise.all(promises);
    return balanceByAccounts;
  }

  /**
   * sumAccountBalances - sums the account balances for the given currency
   * @param balanceByAccounts
   * @param currency
   */
  sumAccountBalances(balanceByAccounts: BalanceByAccounts, currency: string) {
    let total = 0;
    for (const account in balanceByAccounts) {
      if (balanceByAccounts[account]?.[currency]) {
        total += balanceByAccounts[account][currency];
      }
    }
    return total;
  }

  mapTransactions(balanceAmountBuffer: ArrayBuffer, transactions: Array<Transaction>) {
    const balanceArray = new Float64Array(balanceAmountBuffer);
    const transactionBalances: Record<string, number> = {};
    for (const i in balanceArray) {
      const transaction = transactions[i];
      transactionBalances[transaction.id] = balanceArray[i];
    }
    return transactionBalances;
  }

  getDatesFromGraphData(graphData: Array<GraphDataSet>): Array<number> {
    const dates = [];
    for (const dataSet of graphData) {
      dates.push(dataSet.timeStamp);
    }
    return dates;
  }

  addAccountBalancesToBalanceByAccounts(
    balanceByAccounts: Record<number, Record<string, number>>,
    accountID: string,
    balanceArray: Float64Array,
    balanceDates: Uint32Array
  ) {
    // for each balance date, if there is a matching balance date in required balance dates then add it to the list
    for (let i = 0; i < balanceDates.length; i++) {
      const balanceDate = balanceDates[i];
      const balanceAmount = balanceArray[i];

      if (!balanceByAccounts?.[balanceDate]) {
        balanceByAccounts[balanceDate] = {};
      }
      balanceByAccounts[balanceDate][accountID] = balanceAmount;
    }
  }
}
