import { Component } from "@angular/core";

import { SETTINGS_SECURITY_PATH } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { SecuritySettingsRoute } from "@bitwarden/web-vault/app/models/enum/routes.enum";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";

@Component({
  selector: "app-manage-security",
  templateUrl: "manage-security.component.html",
})
export class ManageSecurityComponent {
  protected readonly SecuritySettingsRoute = SecuritySettingsRoute;
  isDevEnv: boolean;

  constructor() {
    this.isDevEnv = HelperCommon.isDevEnv();
  }

  getRouterLink(route: string) {
    return `${SETTINGS_SECURITY_PATH}/${route}`;
  }
}
